import propsToDom from 'core/helpers/propsToDom';
import React from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import { useGlobalContext } from 'store/GlobalProvider';
import { usePageContext } from 'store/PageProvider';
import styled from 'styled-components';
import media from 'styles/media';
import { bgPaper } from 'styles/others';
import { rem } from 'styles/utils';
import { Container } from './Container';
import { NavContactSubscribeSocials } from './NavContactSubscribeSocials';
import { NavLang } from './NavLang';
import { NavRoman } from './NavRoman';
import { NavSecondary } from './NavSecondary';

const MenuComponent = ({ ...others }) => {
  const { siteData } = usePageContext();
  const { headerHeight, isMenuOpen, setSavedScrollTop, setScrollEnabled } = useGlobalContext();

  // Merge with submenu links if available
  const nav = siteData.header_menu
    .filter(({ link }) => !!link?.url)
    .reduce((acc, curr) => {
      if (curr.submenu_id) {
        const submenu = siteData.body.find(({ primary }) => primary.id === curr.submenu_id);

        if (submenu) {
          const subnav = submenu.items
            .filter(({ link }) => !!link?.url)
            .map(({ link, text_mobile }) => ({ link, link_text: text_mobile }));
          acc.push(...subnav);
        }

        return acc;
      }

      acc.push(curr);
      return acc;
    }, []);

  useIsomorphicLayoutEffect(() => {
    if (isMenuOpen) {
      const { scrollTop } = document.scrollingElement || document.documentElement;
      setSavedScrollTop(scrollTop);
    }

    setScrollEnabled(!isMenuOpen);
  }, [isMenuOpen]);

  return (
    <div {...propsToDom(others)} style={{ transform: `translateX(${isMenuOpen ? '0%' : '100%'})` }}>
      <Container style={{ paddingTop: `calc(${headerHeight}px + ${rem(30)})` }}>
        <NavRoman menu={nav} />
        <NavContactSubscribeSocials />
      </Container>
      <Grid>
        <NavSecondary />
        <Copyright>Eminente {new Date().getFullYear()}©</Copyright>
        <NavLang />
      </Grid>
    </div>
  );
};

export const Menu = styled(MenuComponent)`
  ${bgPaper}
  display: none;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.menu};
  transition: transform 0.9s ${({ theme }) => theme.easings.circOut};
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  ${Container} {
    margin-bottom: ${({ theme }) => theme.spacing.s3};
  }

  ${NavRoman} {
    margin-bottom: ${({ theme }) => theme.spacing.s5};
  }

  ${media.tabletPortrait`
    display: flex;
  `}
`;

const Copyright = styled.div`
  line-height: ${20 / 14};
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const Grid = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: auto;

  ${NavSecondary} {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: ${rem(20)} ${rem(16)};
    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.black};
  }

  ${Copyright},
  ${NavLang} {
    width: 50%;
    padding: ${rem(20)} ${rem(16)};
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  }

  ${NavLang} {
    border-left: 1px solid ${({ theme }) => theme.colors.black};
    padding-left: ${rem(30)};
  }
`;
