import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, Marker, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api';
import { useMapContext } from 'store/MapProvider';
import { useEffectOnce, useIsomorphicLayoutEffect, useUpdateEffect } from 'react-use';
import mapStyles from 'data/mapStyles';
import { usePageContext } from 'store/PageProvider';

const libraries = ['places'];

const mapOptions = {
  maxZoom: 16,
  disableDefaultUI: false,
  fullscreenControl: false,
  mapTypeControl: false,
  panControl: false,
  rotateControl: false,
  streetViewControl: false,
  zoomControl: true,
  styles: mapStyles,
};

const clustererOptions = {
  imageExtension: 'png',
  imagePath: '/images/markers/',
  imageSizes: [98, 98, 98, 98, 98],
  enableRetinaIcons: true,
};

const containerStyle = {
  width: '100%',
  height: '100%',
};

const defaultCenter = { lat: 41, lng: -71 };
const defaultZoom = 12;

const StoreLocatorMap = ({ ...others }) => {
  // ------------------------------------------------------------
  //    REFS
  // ------------------------------------------------------------
  const mapRef = useRef(null);
  const clustererRef = useRef(null);

  // ------------------------------------------------------------
  //    HOOKS & STATE
  // ------------------------------------------------------------
  const { siteData } = usePageContext();
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.GATSBY_GOOGLE_API_KEY,
    libraries,
  });

  const [center, setCenter] = useState(defaultCenter);
  const [zoom, setZoom] = useState(defaultZoom);
  const { retailers, allRetailers, location, activeRetailer, activeCategory, setActiveRetailer } =
    useMapContext();

  // ------------------------------------------------------------
  //    FUNCTIONS
  // ------------------------------------------------------------
  const panTo = ({ lat, lng }) => {
    setCenter({ lat, lng });
  };

  const onDragEnd = () => {
    if (mapRef.current) {
      setCenter(mapRef.current.getCenter().toJSON());
    }
  };

  const onZoomChanged = () => {
    if (mapRef.current) {
      setZoom(mapRef.current.getZoom());
    }
  };

  const centerToLocation = () => {
    if (location.lat && location.lng && center !== defaultCenter) {
      panTo(location);
    }
  };

  // ------------------------------------------------------------
  //    EFFECTS
  // ------------------------------------------------------------
  useUpdateEffect(centerToLocation, [location]);

  useIsomorphicLayoutEffect(() => {
    if (!activeRetailer?.lat || !activeRetailer?.lng) return;

    panTo(activeRetailer);
    setZoom(Math.max(zoom, 15));
  }, [activeRetailer]);

  useEffect(() => {
    if (clustererRef.current) {
      clustererRef.current.redraw();
    }
  }, [activeCategory, retailers]);

  useEffect(() => {
    const hasLocation = location?.lat && location?.lng;
    const hasActive = activeRetailer !== null;
    const isDefault = center.lat === defaultCenter.lat && center.lng === defaultCenter.lng;
    const hasClusters = clustererRef.current?.clusters?.length > 0;

    if (!hasLocation && !hasActive && isDefault && hasClusters) {
      clustererRef.current.fitMapToMarkers();
    }
  }, [retailers]);

  useEffectOnce(() => {
    if (clustererRef.current) {
      clustererRef.current.fitMapToMarkers();
    }
  }, [allRetailers]);

  const renderMap = () => {
    const onLoad = (map) => {
      mapRef.current = map;
      centerToLocation();
    };

    const onLoadClusterer = (clusterer) => {
      clustererRef.current = clusterer;
      clustererRef.current.fitMapToMarkers();
    };

    const onClickMarker = (marker) => {
      const active = retailers.find(
        ({ lat, lng }) => lat === marker.latLng.lat() && lng === marker.latLng.lng()
      );

      if (active) {
        setActiveRetailer(active);
      }
    };

    return (
      <GoogleMap
        id="eminente-map"
        onLoad={onLoad}
        mapContainerStyle={containerStyle}
        clickableIcons={false}
        onDragEnd={onDragEnd}
        onDragStart={() => setActiveRetailer(null)}
        onClick={() => setActiveRetailer(null)}
        onZoomChanged={onZoomChanged}
        heading={0}
        center={center}
        options={mapOptions}
        zoom={zoom}
      >
        <MarkerClusterer
          onLoad={onLoadClusterer}
          enableRetinaIcons={true}
          maxZoom={14}
          options={clustererOptions}
        >
          {(clusterer) => {
            return retailers?.map(({ uid, lat, lng, products }, index) => (
              <Marker
                key={uid}
                onClick={onClickMarker}
                opacity={activeRetailer === null ? 1 : activeRetailer?.uid === uid ? 1 : 0.5}
                position={{ lat, lng }}
                clusterer={clusterer}
                noClustererRedraw={true}
                icon={{
                  url: `/images/markers/${
                    products === 0 ? '1_R' : products === 1 ? '1_GR' : '1_RGR'
                  }.png`,
                  size: { width: 98, height: 98 },
                  scaledSize: { width: 98, height: 98 },
                  anchor: { x: 49, y: 49 },
                }}
              />
            ));
          }}
        </MarkerClusterer>
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded && allRetailers?.length > 0 ? renderMap() : <div>{siteData.i18n_loading}</div>;
};

export default StoreLocatorMap;
