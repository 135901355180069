import React from 'react';
import styled, { useTheme } from 'styled-components';
import { graphql } from 'gatsby';

import propsToDom from 'core/helpers/propsToDom';
import propsToLink from 'core/helpers/propsToLink';

import { Title1, Title4 } from 'styles/typography';
import { rem } from 'styles/utils';

import { Button } from 'components/Button';
import { ContainerGrid } from 'components/Container';
import Image from 'components/Image';
import { Panel } from 'components/Panel';
import RichText from 'components/RichText';
import media from 'styles/media';
import useParallax from 'hooks/useParallax';
import clamp from 'core/helpers/clamp';
import { motion } from 'framer-motion';

const PushExploreComponent = ({ slice_type, primary, ...others }) => {
  const { colors } = useTheme();
  const { main_title, illustration, subtitle, text, image, link, link_text, color } = primary;
  const hasTitle = !!main_title?.text;
  const hasSubtitle = !!subtitle?.text;
  const hasImage = !!image?.url;
  const hasText = !!text?.richText;
  const hasLink = !!link?.url && !!link_text;

  const [$parallax, y] = useParallax((y, b) => {
    const max = b.h / 10;
    return clamp(y * -max, -max, max);
  });

  const illusInside = {
    crocodile: '/images/illustrations/croc-dust.png',
    crocodilefull: '/images/illustrations/crocfull-dust.png',
    foulard: '/images/illustrations/foulard-dust.png',
  };

  return (
    <Panel
      {...propsToDom(others)}
      id={slice_type}
      headerTheme="light-dust"
      style={{ '--accent': colors[color] }}
    >
      <ContainerGrid>
        {hasTitle && <Title1 as="h2">{main_title.text}</Title1>}
        {hasImage && (
          <ImageContainer>
            <Image ref={$parallax} style={{ y }} {...image} />
            {typeof illusInside[illustration] === 'string' && (
              <Croc src={illusInside[illustration]} role="presentation" loading="lazy" />
            )}
          </ImageContainer>
        )}
        <Content>
          {hasSubtitle && <Title4 as="h3">{subtitle.text}</Title4>}
          {hasText && <RichText render={text.richText} />}
          {hasLink && (
            <Button background="medium" color="dust" {...propsToLink(link)}>
              {link_text}
            </Button>
          )}
        </Content>
      </ContainerGrid>
      {illustration === 'door' && (
        <Door src="/images/illustrations/door-dust.png" role="presentation" loading="lazy" />
      )}
    </Panel>
  );
};

export const PushExplore = styled(PushExploreComponent)`
  position: relative;
  background: var(--accent, ${({ theme }) => theme.colors.green});
  color: ${({ theme }) => theme.colors.dust};
  padding-bottom: ${rem(140)};

  ${Title1} {
    position: relative;
    grid-column: 1 / -1;
    line-height: 1;
    margin-bottom: -0.5em;
    text-transform: uppercase;
    z-index: 2;
  }

  ${media.mobile`
    padding-bottom: ${rem(60)};

    ${Title1} {
      margin-bottom: 0;
    }
  `}
`;

const ImageContainer = styled(motion.div)`
  position: relative;
  grid-column: 1 / span 8;
  padding: 0 ${rem(58)};

  ${media.mobile`
    grid-row: 3 / span 1;
    padding: 0;
  `}
`;

const Content = styled.div`
  grid-column: auto / span 4;
  padding-top: ${({ theme }) => theme.spacing.s8};

  ${Title4} {
    margin-bottom: ${({ theme }) => theme.spacing.s3};
  }

  ${RichText} {
    margin-bottom: ${({ theme }) => theme.spacing.s6};
  }

  ${media.mobile`
    margin-bottom: ${({ theme }) => theme.spacing.s6};
    padding: ${({ theme }) => `${theme.spacing.s3} ${rem(27)} 0 ${rem(27)}`};
    text-align: center;

    ${Title4},
    ${RichText} {
      text-align: left;
    }
  `}
`;

const Croc = styled.img`
  position: absolute;
  right: ${({ theme }) => rem(50 + 58)};
  bottom: 0;
  transform: translateY(50%) rotate(0deg);
  width: ${rem(166)};

  ${media.mobile`
    width: ${rem(72)};
    right: ${rem(65)};
  `}
`;

const Door = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 3;
  width: ${rem(182)};

  ${media.mobile`
    width: ${rem(87)};
  `}
`;

export const query = graphql`
  fragment SlicePushExplore on PrismicPageHomeDataBodyPushExplore {
    id
    slice_type
    slice_label
    primary {
      color
      main_title {
        text
      }
      subtitle {
        text
      }
      text {
        richText
      }
      illustration
      image {
        alt
        url
        dimensions {
          width
          height
        }
      }
      link {
        target
        url
      }
      link_text
    }
  }

  fragment SlicePushExploreRich on PrismicPageRichDataBodyPushExplore {
    id
    slice_type
    slice_label
    primary {
      color
      main_title {
        text
      }
      subtitle {
        text
      }
      text {
        richText
      }
      illustration
      image {
        alt
        url
        dimensions {
          width
          height
        }
      }
      link {
        target
        url
      }
      link_text
    }
  }
`;
