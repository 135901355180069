import React from 'react';
import { ContainerGrid } from 'components/Container';
import { Panel } from 'components/Panel';
import { graphql } from 'gatsby';
import styled, { useTheme } from 'styled-components';
import { Title1, h3, highlightCSS } from 'styles/typography';
import propsToDom from 'core/helpers/propsToDom';
import Image from 'components/Image';
import RichText from 'components/RichText';
import { Button } from 'components/Button';
import { rem } from 'styles/utils';
import propsToLink from 'core/helpers/propsToLink';
import media from 'styles/media';
import useParallax from 'hooks/useParallax';
import clamp from 'core/helpers/clamp';
import { motion } from 'framer-motion';

const PushCenterComponent = ({ slice_type, primary, ...others }) => {
  const { colors } = useTheme();
  const { main_title, image, text, button_link, button_label, color } = primary;
  const hasTitle = !!main_title.text;
  const hasImage = !!image?.url;
  const hasText = !!text?.richText.length > 0;
  const hasButton = !!button_link?.url && !!button_label;

  const [$pTitle, yTitle] = useParallax((y, b) => {
    const max = b.h / 2;
    return clamp(y * max, -max, max);
  });

  return (
    <Panel {...propsToDom(others)} id={slice_type} style={{ '--accent': colors[color] }}>
      <ContainerGrid>
        {hasTitle && (
          <Title1 ref={$pTitle} as={motion.h2} style={{ y: yTitle }}>
            {main_title.text}
          </Title1>
        )}
        {hasImage && <Image {...image} />}
        <Content>
          {hasText && <RichText render={text.richText} />}
          {hasButton && (
            <Button background="small" color="green" {...propsToLink(button_link)}>
              {button_label}
            </Button>
          )}
        </Content>
      </ContainerGrid>
    </Panel>
  );
};

export const PushCenter = styled(PushCenterComponent)`
  ${Title1} {
    position: relative;
    line-height: 1;
    margin-bottom: -0.5em;
    z-index: 2;
    grid-column: 1 / -1;
    text-transform: uppercase;
    color: var(--accent);
  }

  ${Image} {
    grid-column: 2 / -2;
  }

  ${media.mobile`
    ${Title1} {
      text-align: center;
    }
  `}
`;

const Content = styled.div`
  grid-column: 2 / -2;
  text-align: center;

  ${RichText} {
    ${highlightCSS}
    margin-top: ${rem(30)};
  }

  ${Button} {
    margin-top: ${rem(34)};
  }

  ${media.mobile`
    ${RichText} {
      margin-top: ${rem(20)};
    }
  `}
`;

export const query = graphql`
  fragment SlicePushCenter on PrismicPageHomeDataBodyPushCenter {
    id
    slice_type
    slice_label
    primary {
      color
      main_title {
        text
      }
      image {
        alt
        url
        dimensions {
          width
          height
        }
      }
      text {
        richText
      }
      button_link {
        target
        url
      }
      button_label
    }
  }

  fragment SlicePushCenterRich on PrismicPageRichDataBodyPushCenter {
    id
    slice_type
    slice_label
    primary {
      color
      main_title {
        text
      }
      image {
        alt
        url
        dimensions {
          width
          height
        }
      }
      text {
        richText
      }
      button_link {
        target
        url
      }
      button_label
    }
  }
`;
