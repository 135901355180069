import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import { rem } from 'styles/utils';
import { labelCSS } from './Form';

const FormCheckboxComponent = ({ children, className, ...others }) => {
  return (
    <div className={className}>
      <input type="checkbox" {...others} />

      <label htmlFor={others.id}>
        <Icon id="checkbox" />
        {children}
      </label>
    </div>
  );
};

export const FormCheckbox = styled(FormCheckboxComponent)`
  position: relative;
  text-align: center;
  line-height: ${20 / 14};

  ${Icon} {
    font-size: ${rem(16)};
    line-height: ${rem(20)};
    vertical-align: baseline;
    margin-right: ${rem(12)};
    color: ${({ theme }) => theme.colors.red};
    pointer-events: none;

    .background,
    .checked {
      display: none;
    }
  }

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;

    &:checked ~ label ${Icon} {
      .background,
      .checked {
        display: block;
      }
    }
  }

  label {
    ${labelCSS}
    display: inline-block;
    cursor: pointer;

    &:before {
      content: ' ';
      display: inline-block;
    }
  }
`;
