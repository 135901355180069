import React from 'react';
import { Container } from 'components/Container';
import { Panel } from 'components/Panel';
import { graphql } from 'gatsby';
import styled, { useTheme } from 'styled-components';
import propsToDom from 'core/helpers/propsToDom';
import { ArticleThumbnail } from 'components/ArticleThumbnail';
import { Title1, Title4 } from 'styles/typography';
import { CarouselMotion, Wrapper as CarouselWrapper } from 'components/CarouselMotion';
import { useGlobalContext } from 'store/GlobalProvider';
import { rem } from 'styles/utils';
import media from 'styles/media';
import useParallax from 'hooks/useParallax';
import clamp from 'core/helpers/clamp';
import { motion } from 'framer-motion';
import { asText, isFilled } from '@prismicio/client';
import Image from 'components/Image';
import ConditionalWrap from 'components/ConditionalWrap';
import Link from 'components/Link';
import linkResolver from 'core/linkResolver';

const CarouselThreeColsComponent = ({ slice_type, primary, items: originalItems, ...others }) => {
  const { colors } = useTheme();
  const { isMobile } = useGlobalContext();
  const { main_title, color } = primary;
  const hasTitle = !!main_title?.text;

  const items = originalItems.filter(({ image, link }) => isFilled.image(image));

  const isCarousel = (isMobile && items.length > 1) || (!isMobile && items.length > 3);
  const ItemsComponent = isCarousel ? CarouselMotion : Grid;
  const carouselProps = isCarousel ? { magnet: true } : {};

  const [$pTitle, yTitle] = useParallax((y, b) => {
    const max = b.h / 2;
    return clamp(y * max, -max, max);
  });

  return (
    <Panel {...propsToDom(others)} id={slice_type} style={{ '--accent': colors[color] }}>
      <Container>
        {hasTitle && (
          <Header>
            <Title1 ref={$pTitle} as={motion.h2} style={{ y: yTitle }}>
              {main_title.text}
            </Title1>
          </Header>
        )}

        <ItemsComponent {...carouselProps}>
          {items.map(({ image, link, item_title }, index) => {
            const hasLink = !!link?.url || isFilled.link(link);
            const hasTitle = isFilled.richText(item_title?.richText);
            const url = link?.url || linkResolver(link);

            return (
              <ConditionalWrap
                key={index}
                condition={hasLink}
                wrap={(children) => <Link to={url}>{children}</Link>}
              >
                <Item key={index}>
                  <Image {...image} />
                  {hasTitle && <Title4>{asText(item_title.richText)}</Title4>}
                </Item>
              </ConditionalWrap>
            );
          })}
        </ItemsComponent>
      </Container>
    </Panel>
  );
};

const Header = styled.header`
  color: var(--accent);
  z-index: 3;
  pointer-events: none;
  padding-bottom: ${rem(20)};

  ${media.mobile`
    ${Title1} {
      font-size: ${rem(36)};
    }
  `}
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(10px, 1fr));
  grid-gap: var(--margin);
`;

const Item = styled.div`
  display: block;
  flex-shrink: 0;
  width: calc((var(--container-width, 100vw) - var(--margin) * 2) / 3);
  max-width: 100%;

  ${Image} {
    margin-bottom: ${rem(60)};
  }

  ${Title4} {
    text-transform: uppercase;
  }

  ${media.mobile`
    width: ${rem(240)};
  `}
`;

export const CarouselThreeCols = styled(CarouselThreeColsComponent)`
  --margin: ${rem(30)};
  position: relative;
  overflow: hidden;

  ${Container} {
    position: relative;
  }

  ${Title1} {
    text-transform: uppercase;
  }

  ${CarouselWrapper} {
    gap: var(--margin);
  }

  ${media.mobile`
    ${ArticleThumbnail} {
      width: ${rem(240)};
    }
  `}
`;

export const query = graphql`
  fragment SliceCarouselThreeCols on PrismicPageRichDataBodyCarouselThreeCols {
    id
    slice_type
    slice_label
    primary {
      color
      main_title {
        text
      }
    }
    items {
      item_title {
        richText
      }
      link {
        url
        target
      }
      image {
        alt
        url
        dimensions {
          width
          height
        }
      }
    }
  }
`;
