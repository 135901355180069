import { rem as polishedRem, em as polishedEm } from 'polished';
import theme from './theme';

export function rem(value, base = theme.fontSize.base) {
  return polishedRem(value, base);
}

export function em(value, base = theme.fontSize.base) {
  return polishedEm(value, base);
}

export function fluidPx(px, variable = '--fluidPx') {
  return `calc(var(${variable}) * ${parseFloat(px, 10)})`;
}

export function sizeAsColVw(numberOfCols, gridSize = theme.defaultGridSize) {
  return `${(100 / gridSize) * numberOfCols}vw`;
}

export function sizeAsColPercent(numberOfCols, gridSize = theme.defaultGridSize) {
  return `${(100 / gridSize) * numberOfCols}%`;
}
