import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Panel } from 'components/Panel';
import RichText from 'components/RichText';
import propsToDom from 'core/helpers/propsToDom';
import { ContainerGrid } from 'components/Container';
import { h2 } from 'styles/typography';
import { em, rem } from 'styles/utils';
import media from 'styles/media';
import useParallax from 'hooks/useParallax';
import clamp from 'core/helpers/clamp';

const QuoteBottleComponent = ({ slice_type, primary, ...others }) => {
  const { quote, author } = primary;

  const [$parallax, y] = useParallax((y, b) => {
    const max = b.h / 8;
    return clamp(y * -max, -max, max);
  });

  return (
    <Panel {...propsToDom(others)} id={slice_type}>
      <ContainerGrid>
        <Blockquote>
          <RichText ref={$parallax} style={{ y }} animated={true} render={quote.richText}></RichText>
          <cite>{author}</cite>
        </Blockquote>
      </ContainerGrid>
    </Panel>
  );
};

export const QuoteBottle = styled(QuoteBottleComponent)`
  text-align: center;
`;

const Blockquote = styled.blockquote`
  position: relative;
  box-sizing: border-box;
  grid-column: 1 / -1;
  padding: ${({ theme }) => `${theme.spacing.s9} ${theme.spacing.s5}`};
  background: url('/images/illustrations/bottle-quote.svg') no-repeat;
  background-size: contain;
  background-position: center center;
  min-height: ${rem(482)};
  display: flex;
  align-items: center;
  justify-content: stretch;

  cite {
    position: absolute;
    top: 100%;
    margin-top: ${({ theme }) => theme.spacing.s3};
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    border-top: 1px solid currentColor;
    padding-top: ${({ theme }) => theme.spacing.s1};
  }

  ${RichText} {
    ${h2}
    line-height: ${80 / 56};

    strong {
      text-decoration: underline;
      font-weight: normal;
    }

    p:first-child:before,
    p:last-child:after {
      display: inline-block;
      vertical-align: bottom;
      color: ${({ theme }) => theme.colors.white};
      font-size: ${em(112, 56)};
      line-height: ${em(30, 112)};
    }

    p:first-child:before {
      content: '“';
      margin-right: ${({ theme }) => theme.spacing.s1};
      margin-left: ${em(-10)};
    }

    p:last-child:after {
      content: '”';
      margin-left: ${({ theme }) => theme.spacing.s1};
      margin-right: ${em(-10)};
    }
  }

  ${media.mobile`
    padding-top: 0;
    padding-bottom: 0;

    ${RichText} {
      p:first-child:before,
      p:last-child:after {
        font-size: ${em(56, 42)};
        line-height: ${em(35, 56)};
      }
    }
  `}
`;

export const query = graphql`
  fragment SliceQuoteBottle on PrismicPageRichDataBodyQuoteBottle {
    id
    slice_type
    slice_label
    primary {
      quote {
        richText
      }
      author
    }
  }
`;
