import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from 'components/SEO';
import SliceZone from 'components/SliceZone';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { useGlobalContext } from 'store/GlobalProvider';

const Page = ({ data }) => {
  const { setSubnav } = useGlobalContext();

  useEffect(() => {
    const hasData = data?.prismicPageRich?.data;
    const hasSubnavDoc = hasData && data.prismicPageRich.data?.subnav?.document;

    if (!hasSubnavDoc) {
      setSubnav(null);
      return () => setSubnav(null);
    }

    const subnavDoc = data.prismicPageRich.data.subnav.document;
    const state = { id: subnavDoc.uid, links: subnavDoc.data.links };
    setSubnav(state);
    return () => setSubnav(null);
  }, [data, setSubnav]);

  if (!data?.prismicPageRich?.data) return <h1>No data on rich template</h1>;

  const { body, subnav, ...others } = data.prismicPageRich.data;

  return (
    <Module>
      <SEO {...others} />
      <SliceZone allSlices={body} />
    </Module>
  );
};

const Module = styled.div``;

export default withPrismicPreview(Page);

export const pageQuery = graphql`
  query PageRichQuery($uid: String!, $lang: String!) {
    prismicPageRich(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      id
      uid
      data {
        seo_title
        seo_og_title
        seo_description
        seo_og_description
        schemaorg {
          text
        }
        title {
          text
        }
        subnav {
          document {
            ... on PrismicSubnav {
              uid
              data {
                links {
                  link {
                    url
                    target
                  }
                  label
                }
              }
            }
          }
        }
        body {
          ...SliceHeroRich
          ...SliceDuoImagesText
          ...SliceQuoteBottle
          ...SliceImageText
          ...SliceSliderIslaCocodrilo
          ...SlicePushExploreRich
          ...SliceProductHighlight
          ...SliceTastingRich
          ...SliceHowToDrinkRich
          ...SlicePushCenterRich
          ...SliceSliderTextRich
          ...SliceIntroThumbsRich
          ...SliceCarouselThreeCols
          ...SliceHeroTourRich
          ...SliceTourListRich
        }
      }
    }
  }
`;
