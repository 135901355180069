import propsToLink from 'core/helpers/propsToLink';
import React from 'react';
import styled from 'styled-components';
import { useGlobalContext } from 'store/GlobalProvider';
import Icon from './Icon';
import Link from './Link';
import { rem } from 'styles/utils';
import media from 'styles/media';
import { linkTransition } from 'styles/transitions';

const ButtonComponent = ({
  component: Component = false,
  id = null,
  color = null,
  background = null,
  children,
  ...others
}) => {
  const { isMobile, isClient } = useGlobalContext();
  let svg = null;

  switch (background) {
    case 'medium':
      svg = <Icon id="button-line-medium" className="background" preserveAspectRatio="none" />;
      break;
    case 'small':
      svg = <Icon id="button-line-small" className="background" preserveAspectRatio="none" />;
      break;
  }

  if (id === 'buy') {
    svg = (
      <Icon
        key={isClient ? 'client' : 'server'}
        id={`button-line-buy${isMobile ? '-mobile' : ''}`}
        className="background"
        preserveAspectRatio="none"
      />
    );
  }

  if (id === 'map') {
    svg = <Icon id="button-line-buy" className="background" preserveAspectRatio="none" />;
  }

  return Component ? (
    <Component data-id={id} data-bg={background} data-color={color} {...others}>
      {svg}
      <span>{children}</span>
    </Component>
  ) : (
    <Link data-id={id} data-bg={background} data-color={color} {...propsToLink(others)}>
      {svg}
      <span>{children}</span>
    </Link>
  );
};

export const Button = styled(ButtonComponent)`
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  padding: ${({ theme }) => `${theme.spacing.s1_5} ${theme.spacing.s3}`};
  letter-spacing: 0.1em;
  ${linkTransition}
  transition-property: opacity;

  span {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100%;
    color: currentColor;
  }

  &[data-bg='medium'] {
    padding-left: ${rem(40)};
    min-width: ${rem(206)};
  }

  &[data-bg='small'] {
    padding-left: ${rem(38)};
    padding-right: ${rem(38)};
  }

  &[data-color='green'] .background {
    color: ${({ theme }) => theme.colors.green};
  }

  &[data-color='greenLight'] .background {
    color: ${({ theme }) => theme.colors.greenLight};
  }

  &[data-color='red'] .background {
    color: ${({ theme }) => theme.colors.red};
  }

  &[data-color='dust'] .background {
    color: ${({ theme }) => theme.colors.dust};
  }

  &[data-id='buy'],
  &[data-id='map'] {
    color: ${({ theme }) => theme.colors.black};
    .background .bg {
      fill: ${({ theme }) => theme.colors.dust};
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      opacity: 0.5;
    }
  }

  ${media.mobile`
    &[data-id='buy'] {
      padding-left: ${rem(20)};
      padding-right: ${rem(20)};
    }
  `}
`;
