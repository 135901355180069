import React from 'react';
import styled from 'styled-components';
import { useGlobalContext } from 'store/GlobalProvider';
import { usePageContext } from 'store/PageProvider';
import { Title4, fontTitle } from 'styles/typography';
import { em, rem } from 'styles/utils';
import { ContainerGrid } from './Container';
import Image from './Image';
import { BuyRetailer } from './BuyRetailer';
import media from 'styles/media';

const BuyRetailersComponent = ({ ...others }) => {
  const { modalBuy } = usePageContext();
  const { userCountry } = useGlobalContext();

  const {
    title_reserva,
    subtitle_reserva,
    title_gran_reserva,
    subtitle_gran_reserva,
    bottle_image_reserva,
    bottle_image_gran_reserva,
    find_also_on_title,
    no_retailers_country_error,
    retailers,
  } = modalBuy;

  const localRetailers = retailers.find(
    ({ primary }) => primary?.country_code === userCountry
  )?.items;

  let $retailers = null,
    $highlightRetailers = null;

  if (localRetailers) {
    const highlighteds = localRetailers.filter(({ is_highlighted }) => is_highlighted === true);
    const notHighlighteds = localRetailers.filter(({ is_highlighted }) => is_highlighted === false);

    $highlightRetailers =
      highlighteds.length > 0 ? (
        <Retailers isHighlighted={true}>
          <Croc
            src="/images/illustrations/croc-black.png"
            width="108"
            height="88"
            loading="lazy"
            role="presentation"
            className="filter-black"
          />
          {highlighteds.map((props, index) => (
            <BuyRetailer key={`retailer-${index}`} color="black" {...props} />
          ))}
        </Retailers>
      ) : null;

    $retailers = (
      <Retailers>
        {notHighlighteds.map((props, index) => (
          <BuyRetailer key={`retailer-${index}`} {...props} />
        ))}
      </Retailers>
    );
  }

  return (
    <ContainerGrid {...others}>
      <Bottles>
        <BottleReserva>
          <Title>
            <span>{title_reserva.text}</span>
            {subtitle_reserva.text && <small>{subtitle_reserva.text}</small>}
          </Title>
          <Image {...bottle_image_reserva} />
        </BottleReserva>

        <BottleGranReserva>
          <Title>
            <span>{title_gran_reserva.text}</span>
            {subtitle_gran_reserva.text && <small>{subtitle_gran_reserva.text}</small>}
          </Title>
          <Image {...bottle_image_gran_reserva} />
        </BottleGranReserva>
      </Bottles>
      <Specs>
        {$highlightRetailers}
        <FindAlso>
          <Title4
            dangerouslySetInnerHTML={{
              __html: localRetailers ? find_also_on_title : no_retailers_country_error.html,
            }}
          />
          {$retailers}
        </FindAlso>
        {/* <SpecsTable>
          <tbody>
            {bottle_specs.map(({ key, value }, index) => (
              <tr key={key + value + index}>
                <td>{key}</td>
                <td>{value}</td>
              </tr>
            ))}
          </tbody>
        </SpecsTable> */}
      </Specs>
    </ContainerGrid>
  );
};

export const BuyRetailers = styled(BuyRetailersComponent)`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  align-items: flex-end;
  align-content: flex-end;
  padding-bottom: ${({ theme }) => theme.spacing.s5};
  overflow: hidden;

  ${media.mobile`
    padding-bottom: ${({ theme }) => theme.spacing.s3};
  `}
`;

const Title = styled.h2`
  ${fontTitle};
  font-size: ${rem(48)};
  line-height: ${58 / 48};
  text-transform: uppercase;
  white-space: pre-line;

  span {
    display: block;
    line-height: 1;
  }

  small {
    display: block;
    font-size: ${em(32, 48)};
    white-space: nowrap;
  }

  ${media.mobile`
    font-size: ${rem(32)};

    small {
      font-size: ${em(22, 32)};
    }
  `}
`;

const Bottles = styled.div`
  grid-column: 1 / span 7;
  display: flex;
  gap: 2px;

  ${media.mobile`
    gap: ${rem(30)};
    margin-bottom: ${rem(75)};
  `}
`;

const Bottle = styled.div`
  position: relative;
  width: 50%;
  height: 100%;

  ${Title} {
    z-index: 2;
  }
`;

const BottleReserva = styled(Bottle)`
  padding-top: ${rem(55)};

  ${Image} {
    width: ${rem(110)};
    margin-left: auto;
  }

  ${Title} {
    position: absolute;
    top: 0;
    left: 0;
    color: ${({ theme }) => theme.colors.red};
  }

  ${media.fromMobile`
    width: 42%;
  `}

  ${media.mobile`
    padding-top: ${rem(65)};

    ${Image} {
      width: ${rem(87)};
    }
  `}
`;

const BottleGranReserva = styled(Bottle)`
  padding-top: ${rem(75)};
  padding-bottom: ${rem(60)};

  ${Image} {
    width: ${rem(130)};
    margin-right: auto;
  }

  ${Title} {
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: right;
    color: ${({ theme }) => theme.colors.green};
  }

  ${media.fromMobile`
    width: 58%;
  `}

  ${media.mobile`
  padding-top: ${rem(85)};
  padding-bottom: ${rem(75)};

    ${Image} {
      width: ${rem(95)};
    }
  `}
`;

const FindAlso = styled.div`
  margin-top: ${rem(35)};

  ${media.mobile`
    margin-top: ${({ theme }) => theme.spacing.s3};
  `}
`;

const Specs = styled.div`
  grid-column: 8 / -1;

  ${media.mobile`
    grid-row-start: 2;
  `}
`;

const Retailers = styled.div`
  position: relative;
  margin-top: ${({ theme, isHighlighted = false }) => (isHighlighted ? null : theme.spacing.s3)};
`;

const Croc = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-65%);
`;

// const SpecsTable = styled.table`
//   width: 100%;
//   text-transform: uppercase;
//   line-height: ${20 / 14};

//   td {
//     padding: ${({ theme }) => theme.spacing.s1};
//     border-bottom: 1px solid currentColor;

//     &:first-child {
//       padding-left: 0;
//     }

//     &:last-child {
//       padding-right: 0;
//       text-align: right;
//       letter-spacing: 0.1em;
//       font-weight: ${({ theme }) => theme.fontWeight.medium};
//     }
//   }
// `;
