import { clamp } from 'framer-motion';
import { useRef } from 'react';
import { useGlobalContext } from 'store/GlobalProvider';

const TOUCH_MULTIPLIER = 2;

const useCarouselMotionEvents = ({
  tx,
  enableMouse = false,
  min = 0,
  max = 0,
  start = () => {},
  move = () => {},
  end = () => {},
}) => {
  const { hasMouse } = useGlobalContext();
  // const router = useRouter();
  const touch = useRef({ firstX: 0, firstTx: 0, prevTouchMove: 0, startTime: null, url: null });

  const onTouchEvent = (event) => {
    event.preventDefault();
    const outBoundsMultiplier = 3;
    const hasTouches = event.touches && event.touches.length > 0;
    const x = hasTouches ? event.changedTouches[0].screenX : event.clientX;
    let url = null;

    /* Set next value */
    let deltaX = x - touch.current.firstX;
    if (event.type === 'touchend') {
      deltaX = touch.current.prevTouchMove - touch.current.firstX;
    }

    let next = touch.current.firstTx + deltaX * (!hasMouse ? TOUCH_MULTIPLIER : 1);

    /* Handle event */
    switch (event.type) {
      case 'touchstart':
      case 'mousedown':
        touch.current.firstX = x;
        touch.current.firstTx = tx.get();

        url = event.target.dataset.url || event.target.closest('[data-url]')?.dataset?.url || null;
        touch.current.url = url;
        touch.current.startTime = Date.now();

        /* Start */
        start({ next, ...touch.current });

        /* Listen to window events */
        window.addEventListener('touchmove', onTouchEvent, { passive: true });
        window.addEventListener('touchend', onTouchEvent, { passive: true });

        if (enableMouse) {
          window.addEventListener('mousemove', onTouchEvent, { passive: true });
          window.addEventListener('mouseup', onTouchEvent, { passive: true });
          window.addEventListener('mouseleave', onTouchEvent, { passive: true });
        }
        break;
      case 'mousemove':
      case 'touchmove':
        /* Create resistance effect */
        if (next < min) {
          next = Math.sqrt(Math.abs(next - min)) * -outBoundsMultiplier + min;
        } else if (next > max) {
          next = Math.sqrt(next) * outBoundsMultiplier;
        }

        tx.set(next);

        /* Move */
        move({ next, ...touch.current });

        touch.current.prevTouchMove = x;
        break;
      case 'touchend':
      case 'mouseup':
      case 'mouseleave':
        next = clamp(min, max, next);

        /* End */
        end({ next, ...touch.current });

        /* Remove events */
        window.removeEventListener('touchmove', onTouchEvent);
        window.removeEventListener('touchend', onTouchEvent);

        /* Link */
        url = event.target.dataset.url || event.target.closest('[data-url]')?.dataset?.url || null;

        // if (
        //   url &&
        //   deltaX < 30 &&
        //   Date.now() - touch.current.startTime < 300 &&
        //   url === touch.current.url
        // ) {
        //   router.push(url, undefined, { scroll: false });
        // }

        if (enableMouse) {
          window.removeEventListener('mousemove', onTouchEvent);
          window.removeEventListener('mouseup', onTouchEvent);
          window.removeEventListener('mouseleave', onTouchEvent);
        }
        break;
    }
  };

  return { onTouchEvent, touch: touch.current };
};

export { useCarouselMotionEvents };
