import React from 'react';
import styled from 'styled-components';
import { Title1, Title4 } from 'styles/typography';
import SliceZone from 'components/SliceZone';
import { ContainerGrid } from './Container';
import { Recipe } from './Recipe';
import media from 'styles/media';
import propsToDom from 'core/helpers/propsToDom';
import { motion } from 'framer-motion';
import useParallax from 'hooks/useParallax';
import clamp from 'core/helpers/clamp';

function getText(object) {
  return object?.text || object[0]?.text || 'Not found';
}

const ArticleComponent = ({
  isGatsbySource,
  is_recipe,
  recipe_ingredients,
  recipe_ingredients_title,
  recipe_accessories,
  recipe_accessories_title,
  recipe_steps,
  title,
  category,
  body,
  ...others
}) => {
  const dataCategory = isGatsbySource ? category?.document?.data : category?.data;
  const hasCategory = !!dataCategory?.title;

  const [$parallax, y] = useParallax((y, b) => {
    const max = b.h / 2;
    return clamp(y * max, 0, max);
  });

  return (
    <div {...propsToDom(others)}>
      <ContainerGrid>
        <Title ref={$parallax} style={{ y }}>
          {hasCategory && <Title4 as="span">{getText(dataCategory.title)}</Title4>}
          <Title1 as="span">{getText(title)}</Title1>
        </Title>
      </ContainerGrid>
      {is_recipe && (
        <Recipe
          ingredients={recipe_ingredients}
          ingredientsTitle={recipe_ingredients_title}
          accessories={recipe_accessories}
          accessoriesTitle={recipe_accessories_title}
          steps={recipe_steps}
        />
      )}
      <SliceZone allSlices={body} />
    </div>
  );
};

export const Article = styled(ArticleComponent)``;

const Title = styled(motion.h1)`
  grid-column: 2 / -2;

  span {
    display: block;

    &:empty {
      display: block;
    }
  }

  ${Title1} {
    color: ${({ theme }) => `var(--accent-color, ${theme.colors.green}) `};
  }

  ${Title4} {
    line-height: 1;
  }

  ${media.tabletPortrait`
    grid-column: 1 / -1;
  `}
`;
