import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Caps, SmallCaps, Title4, Title5 } from 'styles/typography';
import { rem } from 'styles/utils';
import Link from './Link';
import { linkTransition } from 'styles/transitions';
import Icon from './Icon';
import { rgba } from 'polished';
import { LinkUnderline } from './LinkUnderline';
import { usePageContext } from 'store/PageProvider';

export const BuyRetailerBorder = ({ color }) => (
  <svg width="492" height="134" viewBox="0 0 492 134" preserveAspectRatio="none">
    <path
      d="m2.83,131.37C-.17,97.64,1.35,80.8,1.65,2.29m-.31.66c228.76,2.17,325.99-1.68,483.41,1.08L1.35,2.95Zm2.69.11c209.82.43,294.53-1.03,482.96-2.05L4.03,3.05Zm484.46-.66c1.6,54.86-1.59,93.65,2.51,127.66l-2.51-127.66Zm.48,1.18c-1.93,29.14-2.55,43.57-.11,127.56l.11-127.56Zm-1.74,125.94c-189.88-1.32-250.14,1.11-484.64-.55l484.64.55Zm2.43,1.38c-169.5-2.04-207.44-2.21-488.62-1.09l488.62,1.09Zm-485.32,2.1c-.09-44.55-2.52-69.24-1.53-130.15l1.53,130.15Z"
      vectorEffect="non-scaling-stroke"
      fill="none"
      stroke={color}
      strokeLinejoin="round"
    />
  </svg>
);

const BuyRetailerComponent = ({
  bottle_price,
  logo,
  product_url,
  shipping_delivery,
  shipping_price,
  is_highlighted,
  product_link_text,
  color = 'red',
  ...others
}) => {
  const { colors } = useTheme();
  const { siteData } = usePageContext();
  const hasLogo = !!logo?.url;
  const hasBottlePrice = !!bottle_price;
  const hasShippingPrice = !!shipping_price;
  const isHighlighted = !!is_highlighted;

  const PriceTitle = isHighlighted ? Title4 : Title5;
  const $logo = hasLogo && (
    <RetailerLogo
      src={logo.url}
      alt={logo.alt}
      loading="lazy"
      className={isHighlighted ? `filter-${color}` : null}
    />
  );
  const $bottlePrice = hasBottlePrice && <PriceTitle as="p">{bottle_price}</PriceTitle>;
  const $shippingPrice = hasShippingPrice && <SmallCaps>{shipping_price}</SmallCaps>;

  const onClick = () => {
    try {
      window.gtag('event', 'conversion', {
        send_to: 'AW-592779712/x-Q-CMbC2d0BEMCz1JoC',
      });
    } catch (e) {
      // silent error
    }
  };

  return isHighlighted ? (
    <Link
      to={product_url?.url}
      target="_blank"
      data-color={color}
      style={{ '--accent': colors[color] }}
      {...others}
      onClick={onClick}
      data-highlighted={isHighlighted}
    >
      <BuyRetailerBorder color={colors[color]} />
      <div>
        {$logo}
        <Caps>{product_link_text}</Caps>
      </div>
      <div>
        {$bottlePrice}
        {$shippingPrice}
        <SmallCaps>{shipping_delivery}</SmallCaps>
      </div>
    </Link>
  ) : (
    <Link
      to={product_url?.url}
      target="_blank"
      data-color={color}
      style={{ '--accent': colors[color] }}
      onClick={onClick}
      {...others}
    >
      {$logo}
      <div>
        {$bottlePrice}
        {$shippingPrice}
        {!hasLogo && <SmallCaps>{product_link_text}</SmallCaps>}
      </div>
      <LinkUnderline tag="div">
        <span>{siteData.i18n_short_external}</span>
        <Icon id="arrow-right" />
      </LinkUnderline>
    </Link>
  );
};

export const BuyRetailer = styled(BuyRetailerComponent)`
  transition-property: opacity, background-color, color;
  ${linkTransition}
  display: flex;
  align-items: center;
  align-content: center;
  padding: ${({ theme }) => theme.spacing.s1} 0;
  box-sizing: content-box;
  min-height: ${rem(58)};

  & > svg {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &:first-child {
    padding-top: 0;
  }

  & + & {
    border-top: 1.5px solid ${({ theme }) => rgba(theme.colors.black, 0.3)};
  }

  ${Title4} {
    margin-bottom: ${rem(5)};
  }

  ${LinkUnderline} {
    margin-left: auto;

    & > span {
      display: inline-flex;
      align-items: center;
    }

    ${Icon}[data-id="arrow-right"] {
      font-size: 8px;
      flex-shrink: 0;
      margin-left: ${({ theme }) => theme.spacing.s1};
    }
  }

  &[data-highlighted='true'] {
    color: var(--accent, ${({ theme }) => theme.colors.red});
    padding: ${({ theme }) => theme.spacing.s3};
    align-items: flex-end;
    align-content: flex-end;

    & + & {
      border-top: 0;
    }

    img {
      margin-bottom: ${rem(15)};
    }

    & > div:first-child {
      ${Caps} {
        display: block;
      }
    }

    & > div:last-child {
      text-align: right;
      flex-grow: 5;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      opacity: 0.5;
    }

    &[data-highlighted='true']:hover {
      opacity: 1;
      color: ${({ theme }) => theme.colors.dust};
      background-color: ${({ theme }) => rgba(theme.colors.red, 0.5)};
    }

    &[data-highlighted='true'][data-color='green']:hover {
      background-color: ${({ theme }) => rgba(theme.colors.green, 0.5)};
    }

    &[data-highlighted='true'][data-color='greenLight']:hover {
      background-color: ${({ theme }) => rgba(theme.colors.greenLight, 0.5)};
    }

    &[data-highlighted='true'][data-color='black']:hover {
      background-color: ${({ theme }) => rgba(theme.colors.black, 0.5)};
    }
  }
`;

const RetailerLogo = styled.img`
  display: block;
  width: ${rem(58)};
  margin-right: ${({ theme }) => theme.spacing.s2};
  height: auto;
  /*
  [data-highlighted='true']:not([data-color='black']) & {
    filter: invert(38%) sepia(10%) saturate(4596%) hue-rotate(327deg) brightness(93%) contrast(87%);
  }

  [data-highlighted='true'][data-color='green'] & {
    filter: invert(34%) sepia(34%) saturate(317%) hue-rotate(69deg) brightness(90%) contrast(88%);
  } */

  [data-highlighted='true']:hover & {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(122deg) brightness(103%) contrast(101%);
  }
`;
