import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Image from 'components/Image';
import Icon from 'components/Icon';
import { useGlobalContext } from 'store/GlobalProvider';
import propsToDom from 'core/helpers/propsToDom';
import { Panel } from 'components/Panel';
import { SmallCaps } from 'styles/typography';
import { ImageCover } from 'components/ImageCover';
import useParallax from 'hooks/useParallax';
import clamp from 'core/helpers/clamp';

const HeroComponent = ({ slice_type, primary, ...others }) => {
  const { image, text_invite } = primary;
  const hasImage = !!image?.url;
  const hasTextInvite = !!text_invite;
  const { isMobile } = useGlobalContext();
  const [$parallax, y] = useParallax((y, b, wh) => clamp(y * 0.35, 0, wh * 0.5), {
    smooth: false,
    animation: null,
  });

  return (
    <Panel {...propsToDom(others)} id={slice_type} headerTheme="light-red" hasInViewAttr={true}>
      {hasImage && (
        <ImageCover
          ref={$parallax}
          style={!isMobile && { y }}
          quality={90}
          {...(isMobile ? image.thumbnails.mobile : image)}
        />
      )}
      {hasTextInvite && (
        <Invite as="div">
          <p>{text_invite}</p>
          <Icon id="line-vertical" />
        </Invite>
      )}
    </Panel>
  );
};

export const Hero = styled(HeroComponent)`
  position: relative;
  overflow: hidden;
  height: 100vh;

  ${Image} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
`;

const Invite = styled(SmallCaps)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  white-space: nowrap;

  @keyframes invite {
    0% {
      transform-origin: 50% 0;
      transform: scaleY(0);
    }
    33% {
      transform-origin: 50% 0;
      transform: scaleY(1);
    }
    66% {
      transform-origin: 50% 0;
      transform: scaleY(1);
    }
    67% {
      transform-origin: 50% 100%;
    }
    100% {
      transform-origin: 50% 100%;
      transform: scaleY(0);
    }
  }

  ${Icon} {
    font-size: 80px;
    margin: ${({ theme }) => `${theme.spacing.s1} 0 ${theme.spacing.s3}`};
    animation: invite 2.5s infinite forwards ${({ theme }) => theme.easings.easeInOutCirc};

    [data-in-view='false'] & {
      animation-play-state: paused;
    }
  }
`;

export const query = graphql`
  fragment SliceHero on PrismicPageHomeDataBodyHero {
    id
    slice_type
    slice_label
    primary {
      image {
        alt
        url
        dimensions {
          width
          height
        }
        thumbnails {
          mobile {
            alt
            url
            dimensions {
              width
              height
            }
          }
        }
      }
      text_invite
    }
  }

  fragment SliceHeroRich on PrismicPageRichDataBodyHero {
    id
    slice_type
    slice_label
    primary {
      image {
        alt
        url
        dimensions {
          width
          height
        }
        thumbnails {
          mobile {
            alt
            url
            dimensions {
              width
              height
            }
          }
        }
      }
      text_invite
    }
  }
`;
