import React from 'react';
import styled, { useTheme } from 'styled-components';
import { graphql } from 'gatsby';
import { Title1, Title4 } from 'styles/typography';
import RichText from 'components/RichText';
import propsToDom from 'core/helpers/propsToDom';
import { rem } from 'styles/utils';
import { Panel } from 'components/Panel';
import { ContainerGrid } from 'components/Container';
import Image from 'components/Image';
import media from 'styles/media';
import { hiddenScrollbarCSS } from 'styles/others';
import useParallax from 'hooks/useParallax';
import clamp from 'core/helpers/clamp';
import { motion } from 'framer-motion';

const TastingComponent = ({ slice_type, primary, items, ...others }) => {
  const { colors } = useTheme();
  const { main_title, subtitle, description, color = 'red' } = primary;
  const hasTitle = !!main_title?.text;
  const hasSubtitle = !!subtitle?.text;
  const hasDescription = !!description?.html;

  const [$barrel, yBarrel] = useParallax((y, b) => {
    const max = b.h / 6;
    return clamp(y * max, -max, max);
  });
  const [$p1, y1] = useParallax((y, b) => {
    const max = b.h / 10;
    return clamp(y * -max, -max, max);
  });
  const [$p2, y2] = useParallax((y, b) => {
    const max = b.h / 10;
    return clamp(y * max, -max, max);
  });
  const [$p3, y3] = useParallax((y, b) => {
    const max = b.h / 10;
    return clamp(y * -max, -max, max);
  });
  const refs = [$p1, $p2, $p3];
  const ys = [y1, y2, y3];

  return (
    <Panel {...propsToDom(others)} id={slice_type} style={{ '--accent': colors[color] }}>
      {hasTitle && <Title1 as="h2">{main_title.text}</Title1>}
      <ContainerGrid>
        <Content>
          {hasSubtitle && <Title4 as="h3">{subtitle.text}</Title4>}
          {hasDescription && <RichText dangerouslySetInnerHTML={{ __html: description.html }} />}
        </Content>
        <Items>
          <ItemsScroller $nbCols={3}>
            {items
              .filter(({ image }) => !!image?.url)
              .map(({ image, main_title, text }, index) => {
                const hasTitle = !!main_title;
                const hasText = !!text?.html;

                return (
                  <Item ref={refs[index]} style={{ y: ys[index] }} key={image.url + index}>
                    <Image {...image} />
                    <ItemText>
                      {hasTitle && <h4>{main_title}</h4>}
                      {hasText && <RichText dangerouslySetInnerHTML={{ __html: text.html }} />}
                    </ItemText>
                    {index === 0 && (
                      <Barrel
                        ref={$barrel}
                        style={{ y: yBarrel }}
                        src={`/images/illustrations/barrel-${color}.svg`}
                        role="presentation"
                        loading="lazy"
                        className="no-mobile"
                      />
                    )}
                  </Item>
                );
              })}
          </ItemsScroller>
        </Items>
        <Barrel
          src={`/images/illustrations/barrel-${color}.svg`}
          role="presentation"
          loading="lazy"
          className="mobile-only"
        />
      </ContainerGrid>
    </Panel>
  );
};

export const Tasting = styled(TastingComponent)`
  position: relative;
  overflow: hidden;

  ${Title1} {
    color: var(--accent, ${({ theme }) => theme.colors.red});
    text-transform: uppercase;
    margin-bottom: -0.25em;
    padding-left: ${({ theme }) => theme.spacing.s1_5};
  }

  & > ${ContainerGrid} {
    position: relative;
  }
`;

const Content = styled.div`
  position: relative;
  grid-column: auto / span 3;
  z-index: 2;

  ${Title4} {
    margin-top: 0.25em;
    margin-bottom: ${({ theme }) => theme.spacing.s3};
  }

  ${media.tabletPortrait`
    grid-column: auto / span 7;
  `}

  ${media.mobile`
    padding-left: ${rem(56)};
  `}
`;

const Items = styled.div`
  grid-column: auto / span 9;

  ${media.tabletPortrait`
    grid-column: 1 / -1;
    margin-top: ${({ theme }) => theme.spacing.s3};
  `}

  ${media.mobile`
    ${hiddenScrollbarCSS}
    margin: 0 ${rem(-16)};
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  `}
`;

const ItemsScroller = styled(ContainerGrid)`
  position: relative;

  ${media.mobile`
    display: flex;
    gap: ${rem(16)};
    padding-left: ${rem(72)};
    padding-right: ${rem(16)};
    width: max-content;
  `}
`;

const Item = styled(motion.div)`
  position: relative;
  padding-top: ${({ theme }) => theme.spacing.s3};

  ${media.mobile`
    flex-shrink: 0;
    width: ${rem(215)};
  `}
`;

const Barrel = styled(motion.img)`
  position: absolute;
  bottom: ${(-60 / 410) * 100}%;
  left: ${(-108 / 274) * 100}%;
  width: ${(166 / 274) * 100}%;

  ${media.mobile`
    width: ${rem(72)};
    left: 0;
    bottom: ${rem(-80)};
  `}
`;

const ItemText = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: ${rem(20)} ${rem(24)};
  color: ${({ theme }) => theme.colors.dust};
  text-align: center;
  z-index: 3;

  h4 {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    letter-spacing: 0.1em;
    text-transform: uppercase;
    line-height: ${20 / 14};
    margin-bottom: ${rem(20)};
  }
`;

export const query = graphql`
  fragment SliceTasting on PrismicPageHomeDataBodyTasting {
    id
    slice_type
    slice_label
    primary {
      color
      main_title {
        text
      }
      subtitle {
        text
      }
      description {
        html
      }
    }
    items {
      image {
        alt
        url
        dimensions {
          width
          height
        }
      }
      main_title
      text {
        html
      }
    }
  }

  fragment SliceTastingRich on PrismicPageRichDataBodyTasting {
    id
    slice_type
    slice_label
    primary {
      color
      main_title {
        text
      }
      subtitle {
        text
      }
      description {
        html
      }
    }
    items {
      image {
        alt
        url
        dimensions {
          width
          height
        }
      }
      main_title
      text {
        html
      }
    }
  }
`;
