import { asText, isFilled } from '@prismicio/client';
import classNames from 'classnames';
import ConditionalWrap from 'components/ConditionalWrap';
import { Container } from 'components/Container';
import Image from 'components/Image';
import Link from 'components/Link';
import { Panel } from 'components/Panel';
import RichText from 'components/RichText';
import propsToDom from 'core/helpers/propsToDom';
import { graphql } from 'gatsby';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import media from 'styles/media';
import { Title1, h3 } from 'styles/typography';
import { rem } from 'styles/utils';

const TourListComponent = ({ slice_type, primary, items, indexOfType, className, ...others }) => {
  const { colors } = useTheme();
  const { main_title, text, color, image, reset_counter, title_inside } = primary;
  const hasTitle = isFilled.richText(main_title?.richText);
  const hasText = isFilled.richText(text?.richText);
  const hasImage = isFilled.image(image);

  const $header = (
    <Header>
      {hasTitle && <Title1>{asText(main_title.richText)}</Title1>}
      {hasText && <RichText render={text.richText} />}
    </Header>
  );

  const cls = classNames(className, { border: title_inside });

  return (
    <Panel
      {...propsToDom(others)}
      className={cls}
      id={slice_type}
      style={{
        '--accent': colors[color],
        'counter-reset': reset_counter ? undefined : indexOfType === 0 ? 'tour' : undefined,
      }}
    >
      <Container>
        {!title_inside && $header}
        <Flex>
          {hasImage && (
            <Sticky>
              <Image {...image} />
            </Sticky>
          )}
          <Content>
            {title_inside && $header}
            <List>
              {items?.map(({ name, link, link_label }, index) => {
                const hasLink = isFilled.link(link) && isFilled.keyText(link_label);
                return (
                  <Item key={index}>
                    <div>
                      <h3>{name}</h3>
                      <ConditionalWrap
                        condition={hasLink}
                        wrap={(children) => (
                          <Link to={link.url} target={link.target}>
                            {children}
                          </Link>
                        )}
                      >
                        <span>{link_label}</span>
                      </ConditionalWrap>
                    </div>
                  </Item>
                );
              })}
            </List>
          </Content>
        </Flex>
      </Container>
    </Panel>
  );
};

const Header = styled.header`
  margin-bottom: ${rem(50)};

  ${Title1} {
    color: var(--accent);
    text-transform: uppercase;
  }

  ${RichText} {
    ${h3};
  }

  ${media.mobile`
    margin-bottom: ${rem(25)};

    ${RichText} {
      font-size: ${rem(24)};
    }
  `}
`;

const Flex = styled.div`
  display: flex;
  gap: ${rem(30)};
  list-style-position: inside;

  ${media.mobile`
    flex-direction: column;
  `}
`;

const Content = styled.div`
  flex: 1 1 50%;

  .border & {
    padding-top: ${rem(30)};

    ${Header} {
      padding-left: 1em;
    }
  }

  ${Title1} {
    font-size: ${rem(40)};
  }

  ${RichText} {
    font-size: ${rem(32)};
  }
`;

const List = styled.ol`
  display: flex;
  flex-direction: column;
  gap: ${rem(20)};
  list-style: none;
  ${h3};

  ${media.mobile`
    font-size: ${rem(24)};
  `}
`;

const Item = styled.li`
  display: flex;
  counter-increment: tour;
  gap: ${rem(40)};

  &:before {
    content: counter(tour) '.';
    width: 1.5em;
    text-align: right;
    color: var(--accent);
    font-weight: bold;
  }

  ${Link} {
    text-decoration: underline;
    text-decoration-thickness: ${rem(2)};
    text-underline-offset: ${rem(5)};
  }

  h3 {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  span {
    font-style: italic;
  }

  ${media.mobile`
    gap: ${rem(20)};
    padding: 0 ${rem(20)};
  `}
`;

const Sticky = styled.div`
  flex: 1 1 50%;

  ${Image} {
    position: sticky;
    top: var(--header-height);
  }
`;

export const TourList = styled(TourListComponent)`
  &.border {
    border-top: 1px solid #000 !important;
  }
`;

export const query = graphql`
  fragment SliceTourListRich on PrismicPageRichDataBodyTourList {
    id
    slice_type
    slice_label
    primary {
      reset_counter
      title_inside
      color
      main_title {
        richText
      }
      text {
        richText
      }
      image {
        alt
        url
        dimensions {
          width
          height
        }
      }
    }
    items {
      name
      link {
        url
        target
      }
      link_label
    }
  }
`;
