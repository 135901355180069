import { Button } from 'components/Button';
import Image from 'components/Image';
import { Panel } from 'components/Panel';
import propsToDom from 'core/helpers/propsToDom';
import { graphql } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';

const MovieComponent = ({ slice_type, primary, ...others }) => {
  const [isOpen, setOpen] = useState(false);
  const { video_poster, button_text, video } = primary;
  const hasVideo = !!video?.video_id && !!video?.width && !!video?.height;
  const hasPoster = !!video_poster?.url;
  const ratio = video.width / video.height;

  if (!hasVideo) {
    return <div {...others}>Missing video</div>;
  }

  return (
    <Panel {...propsToDom(others)} id={slice_type} headerTheme="light-dust">
      <Sizer style={{ paddingBottom: `${(1 / ratio) * 100}%` }} />
      {hasPoster && <Image {...video_poster} />}
      {isOpen ? (
        <iframe
          src={`https://player.vimeo.com/video/${video.video_id}?autoplay=true`}
          width="640"
          height="360"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      ) : (
        <Button background="medium" color="green" onClick={() => setOpen(true)}>
          {button_text || 'Missing text'}
        </Button>
      )}
    </Panel>
  );
};

export const Movie = styled(MovieComponent)`
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
  }

  ${Image} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ${Button} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${({ theme }) => theme.colors.dust};
  }
`;

const Sizer = styled.span`
  display: block;
`;

export const query = graphql`
  fragment SliceMovie on PrismicPageHomeDataBodyMovie {
    id
    slice_type
    slice_label
    primary {
      button_text
      video_poster {
        alt
        url
        dimensions {
          width
          height
        }
      }
    }
  }
`;
