// import { rem, rgba } from 'polished';
import { rem } from 'polished';
import easings from './easings';

const defaultGridSize = 40;
const designedDesktop = 1366;
const designedMobile = 375;
const baseFontSize = 14;

const theme = {
  defaultGridSize,
  designedDesktop,
  designedMobile,
  zIndex: {
    ageGateway: 12,
    modal: 11,
    header: 10,
    menu: 9,
  },
  colors: {
    metaThemeColor: '#496048',
    black: '#000000',
    white: '#ffffff',
    red: '#B54E37',
    green: '#496048',
    greenLight: '#769080',
    dust: '#E9DBCD',
  },
  fontWeight: {
    normal: 'normal',
    medium: 500,
  },
  fontSize: {
    base: baseFontSize,
  },
  lineHeight: {
    base: 1.8,
  },
  spacing: {
    s1: rem(10, baseFontSize),
    s1_5: rem(15, baseFontSize),
    s2: rem(20, baseFontSize),
    s3: rem(30, baseFontSize),
    s4: rem(40, baseFontSize),
    s5: rem(50, baseFontSize),
    s6: rem(60, baseFontSize),
    s7: rem(70, baseFontSize),
    s8: rem(80, baseFontSize),
    s9: rem(90, baseFontSize),
    s10: rem(100, baseFontSize),
    s16: rem(160, baseFontSize),
    gridColumnGap: rem(50, baseFontSize),
  },
  easings,
};

export default theme;
