import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import propsToDom from 'core/helpers/propsToDom';
import { Panel } from 'components/Panel';
import { ContainerGrid } from 'components/Container';
import { Carousel, CarouselWrapper } from 'components/Carousel';
import Image from 'components/Image';

const SliderComponent = ({ slice_type, items, ...others }) => {
  const images = items
    .filter((item) => !!item?.slider_image?.url)
    .map(({ slider_image }) => slider_image);

  return (
    <Panel {...propsToDom(others)} id={slice_type}>
      <ContainerGrid>
        <Carousel>
          {images.map((image, index) => (
            <Image key={image.url + index} {...image} quality={95} />
          ))}
        </Carousel>
      </ContainerGrid>
    </Panel>
  );
};

export const Slider = styled(SliderComponent)`
  position: relative;
  overflow: hidden;

  ${Carousel} {
    grid-column: 2 / span 10;

    ${CarouselWrapper} > *:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.gridColumnGap};
    }
  }
`;

export const query = graphql`
  fragment SliceSlider on PrismicArticleDataBodySlider {
    id
    slice_type
    slice_label
    items {
      slider_image {
        alt
        url
        dimensions {
          width
          height
        }
      }
    }
  }
`;
