import React from 'react';
import linkResolver from 'core/linkResolver';
import { usePageContext } from 'store/PageProvider';
import styled from 'styled-components';
import { CapsCSS, Title4 } from 'styles/typography';
import Icon from './Icon';
import Image from './Image';
import Link from './Link';
import RichText from './RichText';
import { asText } from '@prismicio/richtext';
import { useGlobalContext } from 'store/GlobalProvider';
import useParallax from 'hooks/useParallax';
import clamp from 'core/helpers/clamp';
import { motion } from 'framer-motion';
import media from 'styles/media';
import { linkTransition } from 'styles/transitions';
import classNames from 'classnames';

const ArticleThumbnailComponent = ({
  uid,
  id,
  is_recipe,
  thumbnail,
  extract,
  title,
  category,
  index = 0,
  ...others
}) => {
  const { setModal } = useGlobalContext();
  const { url: currentUrl } = usePageContext();

  const categoryTitleRaw = category?.document?.data
    ? category.document.data.title?.richText
    : category?.data?.title?.richText || category?.data?.title;
  const titleRaw = title?.richText || title;
  const { lang } = usePageContext();
  const hasThumb = !!thumbnail?.url;
  const hasExtract = extract?.length > 0 || extract?.richText?.length > 0;
  const hasCategory = categoryTitleRaw?.length > 0;
  const hasTree = index > 0 && index % 3 === 0;

  const url = linkResolver({
    uid,
    type: 'article',
    lang,
  });

  const [$pTree, yTree] = useParallax((y, b) => {
    const max = b.h / 10;
    return clamp(y * -max, -max, max);
  });

  const handleClick = (event) => {
    event.preventDefault();
    setModal({ id: 'article', options: { previousUrl: currentUrl, url, id, uid, lang } });
  };

  return (
    <Link {...others} onClick={handleClick} to={url}>
      {hasThumb && <ImageThumb className={classNames({ recipe: is_recipe })} {...thumbnail} />}
      <Body>
        {hasCategory && (
          <Category>
            <span>{asText(categoryTitleRaw)}</span>
            <Icon id="line-horizontal" />
          </Category>
        )}
        <Title4>{asText(titleRaw)}</Title4>
        {hasExtract && <RichText render={extract.richText || extract} />}
        {hasTree && (
          <Tree
            ref={$pTree}
            style={{ y: yTree }}
            src="/images/illustrations/tree-dust.svg"
            role="presentation"
            loading="lazy"
          />
        )}
      </Body>
    </Link>
  );
};

export const ArticleThumbnail = styled(ArticleThumbnailComponent)`
  position: relative;

  ${Title4} {
    margin-bottom: ${({ theme }) => theme.spacing.s1};
  }
`;

const Body = styled.div`
  ${linkTransition}
  transition-property: opacity;
  padding-top: ${({ theme }) => theme.spacing.s3};

  @media (hover: hover) and (pointer: fine) {
    ${ArticleThumbnail}:hover & {
      opacity: 0.5;
    }
  }
`;

const ImageThumb = styled(Image)`
  &.recipe {
    position: relative;
    border-radius: 99rem 99rem 0 0;
    clip-path: inset(0 0 0 0 round 99em 99em 0 0);
    overflow: hidden;
  }
`;

const Category = styled.p`
  ${CapsCSS}
  position: relative;
  display: inline-block;
  margin-bottom: ${({ theme }) => theme.spacing.s1_5};

  ${Icon} {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 4px;
  }
`;

const Tree = styled(motion.img)`
  position: absolute;
  top: ${(70 / 574) * 100}%;
  right: ${(-108 / 382) * 100}%;
  width: ${(216 / 382) * 100}%;
  filter: brightness(0) saturate(100%) invert(35%) sepia(14%) saturate(767%) hue-rotate(69deg)
    brightness(92%) contrast(89%);

  ${media.mobile`
    display: none;
  `}
`;

export const FETCH_FIELDS = [
  'article.is_recipe',
  'article.title',
  'article.category',
  'article.thumbnail',
  'article.extract',
];
