import React, { forwardRef, useEffect } from 'react';
import propsToDom from 'core/helpers/propsToDom';
import { math } from 'polished';
import styled from 'styled-components';
import { rem } from 'styles/utils';
import { useGlobalContext } from 'store/GlobalProvider';
import { useInView } from 'framer-motion';
import media from 'styles/media';

const PanelComponent = forwardRef(
  (
    {
      id,
      headerTheme = 'dark',
      hasInViewAttr = false,
      noSetupHeaderTheme = false,
      children,
      ...others
    },
    $outerRef
  ) => {
    const { setHeaderTheme } = useGlobalContext();
    const $innerRef = React.useRef(null);
    const $ref = $outerRef || $innerRef;
    const inView = useInView($ref, {
      margin: '0px 0px -99% 0px',
      amount: 0,
    });
    /* const [$ref, inView] = useInView({ threshold: 0.0, rootMargin: '0px 0px -99% 0px' }); */

    useEffect(() => {
      if (inView && !noSetupHeaderTheme) {
        setHeaderTheme(headerTheme);
      }
    }, [inView, setHeaderTheme, headerTheme, noSetupHeaderTheme]);

    return (
      <div
        ref={$ref}
        data-id={id}
        {...propsToDom(others)}
        data-in-view={hasInViewAttr ? inView : null}
      >
        {children}
      </div>
    );
  }
);

PanelComponent.displayName = 'Panel';

export const Panel = styled(PanelComponent)`
  position: relative;
  padding-top: ${({ theme }) => theme.spacing.s8};
  padding-bottom: ${({ theme }) => theme.spacing.s8};

  &:first-child:not([data-id='hero'], [data-id='hero_tour_carousel']) {
    padding-top: ${({ theme }) => `calc(${theme.spacing.s8} + var(--header-height))`};
  }

  &:first-child[data-id='product_highlight'] {
    padding-top: ${({ theme }) => `calc(${theme.spacing.s4} + var(--header-height))`};
  }

  &[data-id='hero'],
  &[data-id='hero_tour_carousel'],
  &[data-id='movie'] {
    padding-top: 0;
    padding-bottom: 0;
  }

  &[data-id='tasting'],
  &[data-id='quote_bottle'] {
    padding-bottom: ${({ theme }) => math(`${theme.spacing.s8} + ${rem(60)}`)};
  }

  &[data-borders='true'] {
    border-top: 1.5px solid ${({ theme }) => theme.colors.border};
    border-bottom: 1.5px solid ${({ theme }) => theme.colors.border};
  }

  /* stylelint-disable */
  /* prettier-ignore */
  &:not(
    [data-id='hero'],
    [data-id='hero_tour_carousel'],
    [data-id='movie'],
    [data-id='image_text'],
    [data-id='push_explore'],
    [data-id='how_to_drink'],
    [data-borders="true"]
  ) + &:not(
    [data-id='how_to_drink'],
    [data-id='image_text'],
    [data-id='push_explore'],
    [data-id='push_map'],
    [data-borders="true"]
  ) {
    padding-top: 0;
  }

  ${media.mobile`
    &:first-child[data-id='hero_tour'] {
      padding-top: ${({ theme }) => `calc(${theme.spacing.s4} + var(--header-height))`};
    }
  `}
`;
