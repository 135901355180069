import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useGlobalContext } from 'store/GlobalProvider';
import styled from 'styled-components';
import { LinkUnderline } from './LinkUnderline';
import linkResolver from 'core/linkResolver';
import { rem } from 'styles/utils';
import media from 'styles/media';
import { hiddenScrollbarCSS } from 'styles/others';

const SubnavComponent = ({ className }) => {
  const { subnav, isMenuOpen } = useGlobalContext();

  return (
    <AnimatePresence>
      {subnav && !isMenuOpen && (
        <motion.div
          id="subnav"
          className={className}
          initial={{ y: '-100%', opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: '-100%', opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {subnav && (
            <Nav>
              <ul>
                {subnav.links.map(({ link, label }, index) => {
                  const url = link?.url || linkResolver(link);
                  return (
                    <li key={index}>
                      <LinkUnderline to={url}>{label}</LinkUnderline>
                    </li>
                  );
                })}
              </ul>
            </Nav>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const Nav = styled.nav`
  ul {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    list-style: none;
    gap: ${rem(35)};
  }

  ${LinkUnderline} {
    text-transform: none;
  }
`;

export const Subnav = styled(SubnavComponent)`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: ${({ theme }) => `${theme.spacing.s2} ${theme.spacing.s3}`};
  box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.colors.black};
  z-index: 1;
  overflow-x: auto;
  white-space: nowrap;
  ${hiddenScrollbarCSS};

  [data-scrolled='true'] & {
    background: ${({ theme }) => theme.colors.dust};
  }

  ${media.mobile`
    padding: ${rem(8)} ${rem(16)};
  `}
`;
