import { css } from 'styled-components';

// ------------------------------------------------------------
//    TRANSITIONS
// ------------------------------------------------------------

export const linkTransition = css`
  transition-duration: 0.4s;
  transition-timing-function: ${({ theme }) => theme.easings.principle};
`;

export const transitionFillHighlight = css`
  transition-property: color, background;
  transition-duration: 0.2s;
  transition-timing-function: ${({ theme }) => theme.easings.principle};
`;
