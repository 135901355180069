import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from 'components/SEO';
import { Title1 } from 'styles/typography';
import { Hero } from 'components/slices';
import { Container, ContainerGrid } from 'components/Container';
import { LinkUnderline } from 'components/LinkUnderline';
import usePrismicAPI from 'hooks/usePrismicAPI';
import { usePageContext } from 'store/PageProvider';
import { Button } from 'components/Button';
import { rem } from 'styles/utils';
import media from 'styles/media';
import { linkTransition } from 'styles/transitions';
import { useInView } from 'react-intersection-observer';
import { useGlobalContext } from 'store/GlobalProvider';
import {
  ArticleThumbnail,
  FETCH_FIELDS as ARTICLE_FETCH_FIELDS,
} from 'components/ArticleThumbnail';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import linkResolver from 'core/linkResolver';
import { AnimatePresence, motion } from 'framer-motion';
import { dry } from 'constants/springs';

const Blog = ({ data, pageContext }) => {
  const { selectedCategories } = pageContext;
  const articlesDefault =
    data.allPrismicArticle?.edges
      .filter(({ node }) => selectedCategories.includes(node.data?.category?.id) || false)
      .map(({ node }) => node) || [];
  const categories =
    data.allPrismicBlogCategory?.edges
      .filter(({ node }) => selectedCategories.includes(node.id))
      .sort((a, b) => selectedCategories.indexOf(a.node.id) - selectedCategories.indexOf(b.node.id))
      .map(({ node }) => node) || [];
  const { title, hero_image, hero_text_invite, ...others } = data?.prismicBlog?.data || {};

  // ------------------------------------------------------------
  //    REFS
  // ------------------------------------------------------------
  const [$content, inViewGrid] = useInView({ threshold: 0.0, rootMargin: '0px 0px -99% 0px' });

  // ------------------------------------------------------------
  //    STATE & HOOKS
  // ------------------------------------------------------------
  const { setHeaderTheme } = useGlobalContext();
  const { pageSize = 9, categoriesCount = {}, lang, siteData } = usePageContext();
  const [prismic, filter] = usePrismicAPI();
  const [isLoading, setLoading] = useState(false);
  const [state, setState] = useState({
    page: 1,
    total_pages: data?.allPrismicArticle?.pageInfo?.pageCount || 99,
    articles: articlesDefault,
    category: null,
  });

  // ------------------------------------------------------------
  //    EFFECTS
  // ------------------------------------------------------------
  useEffect(() => {
    if (inViewGrid) {
      setHeaderTheme('dark');
    }
  }, [inViewGrid, setHeaderTheme]);

  if (!data?.prismicBlog?.data) return <h1>No data on blog template</h1>;

  // ------------------------------------------------------------
  //    DATA
  // ------------------------------------------------------------
  const heroSliceData = {
    slice_type: 'hero',
    primary: {
      image: hero_image,
      text_invite: hero_text_invite,
    },
  };

  const hasNextButton = state.page < state.total_pages;

  // ------------------------------------------------------------
  //    FUNCTIONS
  // ------------------------------------------------------------
  const fetch = async ({ category, page }) => {
    setLoading(true);
    const nextQuery = [filter.at('document.type', 'article')];

    if (category) {
      nextQuery.push(filter.at('my.article.category', category));
    } else {
      nextQuery.push(filter.any('my.article.category', selectedCategories));
    }

    const response = await prismic.get({
      filters: nextQuery,
      fetch: ARTICLE_FETCH_FIELDS,
      pageSize,
      page,
      lang,
    });

    setLoading(false);

    if (response) {
      setState((prev) => ({
        ...prev,
        category,
        articles: [...prev.articles, ...response.results],
        page: response.page,
        total_pages: response.total_pages,
      }));
    }
  };

  const handleClickCategory = (id) => {
    if (!prismic || isLoading) return false;
    setState((prev) => ({ ...prev, articles: [] }));
    fetch({ category: id, page: 1 });
  };

  const handleClickNext = () => {
    if (!prismic || isLoading) return false;
    fetch({ page: state.page + 1, category: state.category });
  };

  const getNbArticlesByCategory = (uid) => {
    if (uid === null) return Object.values(categoriesCount).reduce((acc, cur) => acc + cur, 0);
    return categoriesCount[uid] || 0;
  };

  return (
    <Module data-loading={isLoading}>
      <SEO {...others} />
      <Title1>{title.text}</Title1>
      <Hero {...heroSliceData} />
      {categories.length > 1 && (
        <Nav>
          <NavScroller>
            <LinkUnderline
              isActive={state.category === null}
              onClick={() => handleClickCategory(null)}
            >
              All
              <sup>{getNbArticlesByCategory(null)}</sup>
            </LinkUnderline>
            {categories.map(({ id, uid, data }) => (
              <LinkUnderline
                key={uid}
                isActive={state.category === id}
                onClick={() => handleClickCategory(id)}
              >
                {data.title?.text}
                <sup>{getNbArticlesByCategory(uid)}</sup>
              </LinkUnderline>
            ))}
          </NavScroller>
        </Nav>
      )}
      <Content ref={$content}>
        <AnimatePresence mode="wait">
          {state.articles.length > 0 && (
            <GridArticles
              key={state.category}
              as={motion.div}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={dry}
            >
              {state.articles.map(({ prismicId, id, uid, data }, index) => {
                const category = categories.find(({ id }) => id === data?.category?.id);
                return (
                  <ArticleThumbnail
                    key={uid + index}
                    {...data}
                    uid={uid}
                    id={prismicId || id}
                    category={category}
                    index={index}
                  />
                );
              })}
            </GridArticles>
          )}
        </AnimatePresence>
        {hasNextButton && (
          <Actions>
            <Button background="medium" color="green" onClick={handleClickNext}>
              {siteData.i18n_load_more}
            </Button>
          </Actions>
        )}
      </Content>
    </Module>
  );
};

const Module = styled.div`
  ${Title1} {
    display: none;
  }

  ${ArticleThumbnail} {
    grid-column-end: span 4;
  }
`;

const Content = styled.div`
  [data-loading='true'] & {
    min-height: 100vh;
  }
`;

const GridArticles = styled(ContainerGrid)`
  padding: ${({ theme }) => theme.spacing.s8} 0;
  grid-row-gap: ${({ theme }) => theme.spacing.s8};

  ${ArticleThumbnail} {
    &:nth-child(3n + 4) {
      margin-top: -${({ theme }) => theme.spacing.s16};
    }

    &:nth-child(3n + 5) {
      margin-top: -${({ theme }) => theme.spacing.s8};
    }

    &:nth-child(2) {
      margin-top: ${({ theme }) => theme.spacing.s8};
    }

    &:nth-child(3) {
      margin-top: ${({ theme }) => theme.spacing.s16};
    }

    ${media.mobile`
      padding-left: ${({ theme }) => theme.spacing.s3};
      padding-right: ${({ theme }) => theme.spacing.s3};
      margin-top: 0 !important;
    `}
  }
`;

const Nav = styled.nav`
  background: ${({ theme }) => theme.colors.green};
  color: ${({ theme }) => theme.colors.dust};
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`;

const NavScroller = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  min-width: 100%;
  padding: ${({ theme }) => `${theme.spacing.s1_5} ${rem(16)}`};

  ${LinkUnderline} {
    white-space: nowrap;
    opacity: 0.3;
    ${linkTransition}
    transition-property: opacity;

    sup {
      position: relative;
      vertical-align: top;
      top: 0.2em;
      font-size: 0.65em;
      line-height: inherit;
      margin-left: 0.5em;
    }

    &.is-active {
      opacity: 1;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        opacity: 1;
      }
    }

    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.s8};

      ${media.mobile`
        margin-right: ${({ theme }) => theme.spacing.s3};
      `}
    }
  }
`;

const Actions = styled(Container)`
  text-align: center;
  padding-bottom: ${({ theme }) => theme.spacing.s8};
`;

export default withPrismicPreview(Blog, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);

export const pageQuery = graphql`
  query BlogQuery($uid: String!, $lang: String!, $pageSize: Int!) {
    prismicBlog(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      id
      uid
      data {
        seo_title
        seo_og_title
        seo_description
        seo_og_description
        schemaorg {
          text
        }
        title {
          text
        }
        hero_image {
          alt
          url
          dimensions {
            width
            height
          }
          thumbnails {
            mobile {
              alt
              url
              dimensions {
                width
                height
              }
            }
          }
        }
        hero_text_invite
      }
    }
    allPrismicBlogCategory(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          id: prismicId
          uid
          data {
            title {
              text
              richText
            }
            accent_color
          }
        }
      }
    }
    allPrismicArticle(filter: { lang: { eq: $lang } }, limit: $pageSize) {
      pageInfo {
        pageCount
      }
      edges {
        node {
          prismicId
          uid
          url
          data {
            is_recipe
            title {
              richText
            }
            category {
              id
              uid
            }
            thumbnail {
              alt
              url
              dimensions {
                width
                height
              }
            }
            extract {
              richText
            }
          }
        }
      }
    }
  }
`;
