import React, { Fragment, useState } from 'react';
import { useMedia } from 'react-use';

import styled from 'styled-components';
import media, { mqMobile } from 'styles/media';

import { ContainerGrid } from './Container';
import { Panel } from './Panel';
import { RecipeIngredients } from './RecipeIngredients';
import { RecipeAccessories } from './RecipeAccessories';
import { RecipeStepsTabs } from './RecipeStepsTabs';
import { RecipeMediasCarousel } from './RecipeMediasCarousel';
import { RecipeStepsList } from './RecipeStepsList';
import { rem } from 'styles/utils';
import { Modal } from './Modal';

const RecipeComponent = ({
  ingredients,
  ingredientsTitle,
  accessories,
  accessoriesTitle,
  steps,
  ...others
}) => {
  const [step, setStep] = useState(0);
  const isMobile = useMedia(mqMobile);

  return (
    <Panel id="recipe" {...others}>
      <ContainerGrid>
        {!isMobile && (
          <CarouselContainer>
            <RecipeMediasCarousel
              key="desktop"
              steps={steps}
              active={step}
              setActive={(index) => setStep(index)}
              prevNext={true}
            />
          </CarouselContainer>
        )}
        <Content>
          <RecipeIngredients ingredients={ingredients} title={ingredientsTitle} />
          <RecipeAccessories accessories={accessories} title={accessoriesTitle} />
          {!isMobile && (
            <RecipeStepsList steps={steps} active={step} setActive={(index) => setStep(index)} />
          )}
          {isMobile && (
            <Fragment>
              <RecipeMediasCarousel
                key="mobile"
                steps={steps}
                active={step}
                setActive={(index) => setStep(index)}
              />
              <RecipeStepsTabs steps={steps} active={step} setActive={(index) => setStep(index)} />
            </Fragment>
          )}
        </Content>
      </ContainerGrid>
    </Panel>
  );
};

const CarouselContainer = styled.div`
  grid-column: 2 / span 4;
  align-self: stretch;

  ${RecipeMediasCarousel} {
    position: sticky;
    top: calc(var(--header-height) + ${rem(30)});

    ${Modal} & {
      top: ${rem(30)};
    }
  }

  ${media.tabletPortrait`
    grid-column: span 5;
  `}
`;

const Content = styled.div`
  grid-column: 7 / span 5;

  ${RecipeMediasCarousel} {
    margin-bottom: ${({ theme }) => theme.spacing.s3};
    padding-left: ${({ theme }) => theme.spacing.s3};
    padding-right: ${({ theme }) => theme.spacing.s3};
  }

  ${media.tabletPortrait`
    grid-column-end : span 6;
  `}
`;

export const Recipe = styled(RecipeComponent)`
  ${ContainerGrid} {
    align-items: start;
  }
`;
