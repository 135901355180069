import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import propsToDom from 'core/helpers/propsToDom';
import { Panel } from 'components/Panel';
import { ContainerGrid } from 'components/Container';
import { Carousel } from 'components/Carousel';
import { Title3 } from 'styles/typography';
import { asText } from '@prismicio/richtext';
import RichText from 'components/RichText';
import media from 'styles/media';

const TitleTextComponent = ({ slice_type, primary, ...others }) => {
  const { main_title, text } = primary;
  const titleRaw = main_title?.richText || main_title;
  const textRaw = text?.richText || text;
  const hasTitle = !!titleRaw?.length > 0;

  return (
    <Panel {...propsToDom(others)} id={slice_type}>
      <ContainerGrid>
        {hasTitle && (
          <Sticky>
            <Title3>{asText(titleRaw)}</Title3>
          </Sticky>
        )}
        <RichText render={textRaw} />
      </ContainerGrid>
    </Panel>
  );
};

export const TitleText = styled(TitleTextComponent)`
  ${Carousel} {
    grid-column: 2 / span 10;
  }

  ${RichText} {
    grid-column: 6 / span 6;
  }

  ${media.mobile`
    ${ContainerGrid} {
      grid-row-gap: ${({ theme }) => theme.spacing.s8};
    }
  `}
`;

const Sticky = styled.div`
  position: relative;
  grid-column: 1 / span 5;

  ${Title3} {
    position: sticky;
    top: var(--header-height, 0);

    ${media.mobile`
      position: relative;
      top: auto;
    `}
  }
`;

export const query = graphql`
  fragment SliceTitleText on PrismicArticleDataBodyTitleText {
    id
    slice_type
    slice_label
    primary {
      main_title {
        richText
      }
      text {
        richText
      }
    }
  }
`;
