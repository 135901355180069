import React from 'react';
import { slow } from 'constants/springs';
import { useSpring, motion, clamp } from 'framer-motion';
import { useRef } from 'react';
import { useIsomorphicLayoutEffect, usePrevious } from 'react-use';
import styled from 'styled-components';
import { CarouselDots } from './CarouselDots';
import Image from './Image';
import Icon from './Icon';
import { buttonReset } from 'styles/others';
import { rem } from 'styles/utils';
import { Swiper, isSmallHorizontalSwipe } from './Swiper';

const MAX_SCALE = 1.25;

const RecipeMediasCarouselComponent = ({
  className,
  steps,
  active,
  setActive,
  prevNext = false,
}) => {
  const $medias = useRef(null);
  const prevActive = usePrevious(active);

  const x = useSpring('0%', slow);
  const scale = useSpring(MAX_SCALE, slow);

  useIsomorphicLayoutEffect(() => {
    x.jump('0%');
    scale.jump(MAX_SCALE);

    x.set('-100%');
    scale.set(1);

    const media = $medias.current?.children[active];
    const prevMedia = $medias.current?.children[prevActive];

    if (media instanceof HTMLVideoElement) {
      media.play();
      media.currentTime = 0;
    }

    if (prevMedia instanceof HTMLVideoElement) {
      prevMedia.pause();
    }
  }, [active]);

  const onSwipe = (event) => {
    if (isSmallHorizontalSwipe(event)) {
      const direction = Math.sign(event.deltaX);
      setActive(clamp(0, steps.length - 1, active + direction));
    }
  };

  return (
    <Swiper className={className} onSwipe={onSwipe}>
      <Medias ref={$medias}>
        {steps.map(({ step_image, step_video }, index) => {
          const hasImage = !!step_image?.url;
          const hasVideo = !!step_video?.url;

          if (!hasImage && !hasVideo) {
            return null;
          }

          const isActive = active === index;
          const isPrev = prevActive === index && prevActive !== active;
          const zIndex = isPrev ? steps.length + 3 : isActive ? steps.length + 2 : index + 1;
          const motionProps = {
            initial: { x: '0%' },
            style: {
              scale: isActive ? scale : 1,
              x: isPrev ? x : '0%',
              zIndex,
            },
            transition: { duration: 3 },
          };

          return hasVideo ? (
            <motion.video
              key={`media-${index}`}
              src={step_video.url}
              loop
              muted
              playsInline
              {...motionProps}
            />
          ) : (
            <Image key={`media-${index}`} {...step_image} naturalSizing={true} {...motionProps} />
          );
        })}
      </Medias>
      <Actions>
        {prevNext && (
          <PrevNext
            onClick={() => setActive(clamp(0, steps.length - 1, active - 1))}
            disabled={active === 0}
          >
            <Icon id="arrow-right" />
          </PrevNext>
        )}
        <CarouselDots
          length={steps.length}
          active={active}
          onClickDot={(index) => setActive(index)}
        />
        {prevNext && (
          <PrevNext
            onClick={() => setActive(clamp(0, steps.length - 1, active + 1))}
            disabled={active === steps.length - 1}
          >
            <Icon id="arrow-right" />
          </PrevNext>
        )}
      </Actions>
    </Swiper>
  );
};

const Medias = styled.div`
  position: relative;
  clip-path: inset(0 0 0 0 round 99em 99em 0 0);

  &:before {
    content: '';
    padding-bottom: ${(574 / 382) * 100}%;
    display: block;
  }

  ${Image},
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.s3};
  gap: ${({ theme }) => theme.spacing.s1};

  ${CarouselDots} {
    margin-top: 0;
  }
`;

const PrevNext = styled.button`
  ${buttonReset};
  font-size: ${rem(20)};
  transition: opacity 0.2s;

  ${Icon} {
    display: block;
  }

  &[disabled] {
    opacity: 0.15;
  }

  &:first-child {
    ${Icon} {
      transform: scaleX(-1);
    }
  }
`;

export const RecipeMediasCarousel = styled(RecipeMediasCarouselComponent)``;
