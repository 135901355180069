import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Image from 'components/Image';
import { rem } from 'styles/utils';
import { LoopText } from 'components/LoopText';
import { Title2 } from 'styles/typography';
import { Panel } from 'components/Panel';
import propsToDom from 'core/helpers/propsToDom';
import media from 'styles/media';
import useParallax from 'hooks/useParallax';
import clamp from 'core/helpers/clamp';

const BottleComponent = ({ slice_type, primary, ...others }) => {
  const { image, first_line_text, second_line_text } = primary;
  const hasImage = !!image?.url;
  const hasFirst = !!first_line_text;
  const hasSecond = !!second_line_text;
  const separator = <Title2 as="span">&nbsp;&nbsp;&nbsp;—&nbsp;&nbsp;&nbsp;</Title2>;
  const [$parallax, y] = useParallax((y, b) => {
    const max = b.h / 8;
    return clamp(y * max, -max, max);
  });

  return (
    <Panel {...propsToDom(others)} id={slice_type}>
      {hasImage && <BottleImage ref={$parallax} style={{ y }} {...image} />}
      <Texts>
        {hasFirst && (
          <LoopTextRed separator={separator}>
            <Title2 as="span">{first_line_text}</Title2>
          </LoopTextRed>
        )}
        {hasSecond && (
          <LoopText isReversed={true} separator={separator}>
            <Title2 as="span">{second_line_text}</Title2>
          </LoopText>
        )}
      </Texts>
    </Panel>
  );
};

export const Bottle = styled(BottleComponent)`
  position: relative;
  overflow: visible;
  padding: ${({ theme }) => theme.spacing.s8} 0;

  ${Title2} {
    line-height: 1.3;
  }
`;

const BottleImage = styled(Image)`
  position: relative;
  width: ${rem(168)};
  margin: auto;
  z-index: 2;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${(256 / 168) * 100}%;
    height: ${(443 / 472) * 100}%;
    background: rgba(89, 50, 42, 0.2);
    mix-blend-mode: multiply;
    filter: blur(100px);
    border-radius: 160px;
  }

  ${media.mobile`
    ${Image} {
      width: ${rem(104)};
    }
  `}
`;

const Texts = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
`;

const LoopTextRed = styled(LoopText)`
  color: ${({ theme }) => theme.colors.red};
`;

export const query = graphql`
  fragment SliceBottle on PrismicPageHomeDataBodyBottle {
    id
    slice_type
    slice_label
    primary {
      image {
        alt
        url
        dimensions {
          width
          height
        }
      }
      first_line_text
      second_line_text
    }
  }
`;
