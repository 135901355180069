import React from 'react';
import styled, { css } from 'styled-components';
import { hover } from 'styles/mixins';
import { rem } from 'styles/utils';
import { SmallCapsCSS } from 'styles/typography';
import Icon from './Icon';

const InputCSS = css`
  font: inherit;
  appearance: none;
  border: none;
  background: transparent;
  outline: none;
  margin: 0;
  width: 100%;
  color: inherit;
  line-height: inherit;
`;

// ------------------------------------------------------------
//    CONTAINER
// ------------------------------------------------------------
const ContainerComponent = ({ error = false, children, ...others }) => (
  <div {...others} data-error={error}>
    {children}
    <Icon id="line-horizontal" />
  </div>
);

export const Container = styled(ContainerComponent)`
  display: block;
  position: relative;
  padding: 0 ${rem(5)};

  input::placeholder,
  .placeholder {
    color: inherit;
  }

  ${Icon}[data-id='line-horizontal'] {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 3px;
    color: var(--form-color, ${({ theme }) => theme.colors.red});
    pointer-events: none;
  }

  &[data-error='true'] {
    ${Icon}[data-id='line-horizontal'] {
      color: ${({ theme }) => theme.colors.red};
    }

    &:after {
      content: attr(data-error-text);
      ${SmallCapsCSS}
      position: absolute;
      right: 0;
      top: 100%;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.red};
    }
  }
`;

export const SelectContainer = styled(Container)`
  display: inline-block;
  position: relative;
  padding-right: ${rem(44)};

  ${Icon}[data-id="arrow-dropdown"] {
    font-size: ${rem(8)};
    position: absolute;
    top: 50%;
    right: ${rem(14)};
    margin-top: ${rem(-4)};
    pointer-events: none;
    color: ${({ theme }) => theme.colors.red};
  }

  p {
    pointer-events: none;
  }
`;

// ------------------------------------------------------------
//    INPUT
// ------------------------------------------------------------
const InputComponent = ({ error = false, errorText = null, ...props }) => (
  <Container error={error} data-error-text={errorText}>
    <input {...props} />
  </Container>
);

export const Input = styled(InputComponent)`
  ${InputCSS}

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
  }
`;

// ------------------------------------------------------------
//    Select
// ------------------------------------------------------------
const SelectComponent = ({ error = false, placeholder, options, ...props }) => {
  const label = options.find(({ value }) => value === props.value)?.label;

  return (
    <SelectContainer error={error}>
      <select {...props}>
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
      <p className={!label ? 'placeholder' : null}>{label || placeholder}</p>
      <Icon id="arrow-dropdown" />
    </SelectContainer>
  );
};

export const Select = styled(SelectComponent)`
  appearance: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 16px;
`;

// ------------------------------------------------------------
//    RADIO
// ------------------------------------------------------------
export const RadioComponent = ({ label, className, ...others }) => (
  <div className={className}>
    <input type="radio" {...others} />
    <label htmlFor={others?.id}>{label}</label>
  </div>
);

export const Radio = styled(RadioComponent)`
  display: inline-block;

  & + & {
    margin-left: ${({ theme }) => theme.spacing.s3};
  }

  input {
    opacity: 0;
    position: absolute;
  }

  label {
    position: relative;
    display: block;
    padding-left: ${rem(16 + 10)};

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      border-radius: 50%;
    }

    &:before {
      width: ${rem(16)};
      height: ${rem(16)};
      transform: translateY(-50%);
      box-shadow: inset 0 0 0 1px var(--form-color, ${({ theme }) => theme.colors.red});
    }

    &:after {
      width: ${rem(10)};
      height: ${rem(10)};
      left: ${rem(8)};
      transform: translate(-50%, -50%);
      background: var(--form-color, ${({ theme }) => theme.colors.red});
      opacity: 0;
    }

    ${hover`
      &:hover:after {
        opacity: 0.5;
      }
    `}
  }

  input:checked ~ label:after {
    opacity: 1;
  }
`;

// ------------------------------------------------------------
//    FIELD
// ------------------------------------------------------------
const FieldComponent = ({ label, htmlFor = null, children, ...others }) => (
  <div {...others}>
    <label htmlFor={htmlFor}>{label}</label>
    {children}
  </div>
);

export const labelCSS = css`
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const Field = styled(FieldComponent)`
  & > label {
    ${labelCSS}
  }
`;
