import React from 'react';
import styled from 'styled-components';
import { Caps, Title4 } from 'styles/typography';
import Image from './Image';
import { rem } from 'styles/utils';

const RecipeAccessoriesComponent = ({ className, accessories, title }) => {
  return (
    <div className={className}>
      <Title4>{title}</Title4>
      <Caps as="div">
        {accessories.map(({ name, image }, index) => {
          const hasImage = !!image?.url;
          return (
            <div key={name + index}>
              {hasImage && <Image {...image} />}
              <p>{name}</p>
            </div>
          );
        })}
      </Caps>
    </div>
  );
};

export const RecipeAccessories = styled(RecipeAccessoriesComponent)`
  margin-bottom: ${({ theme }) => theme.spacing.s3};

  ${Title4} {
    margin-bottom: ${({ theme }) => theme.spacing.s3};
  }

  ${Caps} {
    display: flex;
    font-weight: normal;
    align-items: flex-end;

    ${Image} {
      width: ${rem(58)};
      margin-bottom: ${({ theme }) => theme.spacing.s1};
    }

    & > div {
      &:not(:last-child) {
        margin-right: ${({ theme }) => theme.spacing.s3};
      }
    }
  }
`;
