import styled from 'styled-components';
import { slow } from 'constants/springs';

import classNames from 'classnames';
import { clamp, motion, useSpring } from 'framer-motion';
import React, { Children, memo, useEffect, useState } from 'react';

import { CarouselDots } from './CarouselDots';
import { useOffsetSize } from 'hooks/useOffsetSize';
import { useCarouselMotionEvents } from 'hooks/useCarouselMotionEvents';

const CarouselMotionComponent = memo(
  ({
    className,
    children,
    enableMouse = true,
    magnet = false,
    index: externalIndex = 0,
    group = 1,
    controls = false,
    dots = true,
    onChangeActive = () => {},
    styles = {},
    ...others
  }) => {
    // Translate motion value
    const tx = useSpring(0, slow);
    // Offsets array reference
    const [offsets, setOffsets] = useState([]);
    // Active index
    const [active, setActive] = useState(externalIndex);
    // Sizing
    const [{ innerWidth: containerWidth }, $container] = useOffsetSize();
    const [{ width: wrapperWidth }, $wrapper] = useOffsetSize();
    // Setting min/max values for carousel bounds
    const min = Math.max(0, wrapperWidth - containerWidth) * -1;
    const max = 0;

    // ------------------------------------------------------------
    //    Handlers
    // ------------------------------------------------------------
    const onChangeWidthWrapper = () => {
      /* Calculate offsets */
      const $childs = [...$wrapper.current.children];

      const state = $childs
        .map(($child, index) => {
          if (index % group !== 0) return null;

          if (group > 1) {
            const lastOfGroup = $childs[index + group - 1] || $childs[$childs.length - 1];
            const left = $child.offsetLeft;
            return { left, width: lastOfGroup.offsetLeft + lastOfGroup.offsetWidth - left };
          }

          return { left: $child.offsetLeft, width: $child.offsetWidth * group };
        })
        .filter((i) => i !== null);

      setOffsets(state);
    };

    const goTo = (index) => {
      const next = clamp(0, offsets.length - 1, index);
      setActive(next);
      tx.set(clamp(min, max, offsets[next].left * -1));
    };

    const onChangeExternalIndex = () => {
      /* Set active */
      if (!offsets.length) return;
      goTo(externalIndex);
    };

    /* useEffect handlers */
    useEffect(onChangeWidthWrapper, [wrapperWidth]);
    useEffect(onChangeExternalIndex, [externalIndex]);
    useEffect(() => onChangeActive(active), [active]);

    // ------------------------------------------------------------
    //    Touch/Mouse Events
    // ------------------------------------------------------------
    const { onTouchEvent } = useCarouselMotionEvents({
      tx,
      enableMouse,
      min,
      max,
      start: ({ next }) => {},
      move: ({ next }) => {},
      end: ({ next }) => {
        /* Find next active */
        const nextActive =
          next < min
            ? offsets.length - 1
            : offsets.findIndex((offset, index) => {
                // const prev = offsets[index - 1];
                // const spacing = prev ? offset.left - (prev.left + prev.width) : offset.left;
                const left = offset.left + clamp(min, max, next) - offsets[0].left; /* + spacing */
                const right = left + offset.width; /* + spacing */
                return left <= offset.width / 2 && right >= offset.width / 2;
              });

        /* Set next active */
        setActive(nextActive === -1 ? active : nextActive);

        /* Stick the element at the end if needed */
        if (magnet) {
          const x = offsets[nextActive === -1 ? active : nextActive].left * -1;
          tx.set(Math.max(x, min));
        } else {
          tx.set(next);
        }
      },
    });

    const events = {
      onMouseDown: enableMouse ? onTouchEvent : null,
      onTouchStart: onTouchEvent,
    };

    return (
      <div
        className={classNames(className, { controls, dots, mouse: enableMouse })}
        style={{
          ...styles,
          '--container-width': `${containerWidth}px`,
          '--wrapper-width': `${wrapperWidth}px`,
        }}
        {...others}
        {...events}
      >
        <Overflow ref={$container}>
          <Wrapper ref={$wrapper} style={{ x: tx }}>
            {children}
          </Wrapper>
        </Overflow>
        {dots && (
          <CarouselDots
            length={Children.count(children)}
            active={active}
            onClickDot={(index) => goTo(index)}
          />
        )}
      </div>
    );
  }
);

CarouselMotionComponent.displayName = 'CarouselMotionComponent';

export const Overflow = styled.div`
  position: relative;
  width: 100%;
`;

export const Wrapper = styled(motion.div)`
  position: relative;
  display: flex;
  width: min-content;
`;

export const CarouselMotion = styled(CarouselMotionComponent)`
  position: relative;
  user-select: none;

  &.mouse {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  img {
    pointer-events: none;
  }
`;
