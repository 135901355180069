import { motion, useMotionValue, useSpring } from 'framer-motion';
import React, { Children, cloneElement, useState } from 'react';
import { useIsomorphicLayoutEffect, useMeasure } from 'react-use';
import styled from 'styled-components';
import Image from './Image';
import { gentle, gentle_slow } from 'constants/springs';
import clamp from 'core/helpers/clamp';
import propsToDom from 'core/helpers/propsToDom';
import { Button } from './Button';
import { CarouselDots } from './CarouselDots';
import { usePageContext } from 'store/PageProvider';
import media from 'styles/media';

const CarouselComponent = ({ children, onActiveChange = () => {}, ...others }) => {
  const isCarousel = Children.count(children) > 1;
  const { siteData } = usePageContext();
  const [$ref, { width }] = useMeasure();
  const [$wrapper, { width: wrapperWidth }] = useMeasure();
  const [activeIndex, setActiveIndex] = useState(0);

  const pan = useMotionValue(0);
  const x = useSpring(pan, gentle_slow);

  const dragX = useSpring(0, gentle);
  const dragY = useSpring(0, gentle);
  const dragRotate = useSpring(0, gentle);
  const dragScale = useSpring(0, gentle);
  const dragOpacity = useSpring(0, gentle);
  const length = Children.count(children);

  useIsomorphicLayoutEffect(() => {
    if (activeIndex !== null) {
      const gap = (wrapperWidth - width * length) / (length - 1);
      pan.set(-activeIndex * width - activeIndex * gap);
      onActiveChange?.(activeIndex);
    }
  }, [activeIndex]);

  // ------------------------------------------------------------
  //    EVENTS
  // ------------------------------------------------------------
  const onPan = (event, info) => {
    let value = pan.get() + info.delta.x * (event.pointerType === 'touch' ? 3 : 1);
    value = clamp(value, -(wrapperWidth - width), 0);
    pan.set(value);
  };

  const onPanStart = () => {
    setActiveIndex(null);
  };

  const onPanEnd = (event, info) => {
    const gap = (wrapperWidth - width * length) / (length - 1);
    const index = Math.round(Math.abs(pan.get()) / (width + gap));
    setActiveIndex(index);
  };

  const onMouseEnter = () => {
    dragScale.set(1);
    dragOpacity.set(1);
  };

  const onMouseLeave = () => {
    dragScale.set(0);
    dragOpacity.set(0);
  };

  const onMouseMove = (event) => {
    const bounds = event.currentTarget.getBoundingClientRect();
    const mouseX = event.clientX - bounds.left;
    const mouseY = event.clientY - bounds.top;
    dragX.set(mouseX);
    dragY.set(mouseY);
    dragRotate.set(event.movementX / 5);
  };

  return (
    <div
      ref={$ref}
      {...propsToDom(others)}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      {/* <Sizer
        style={{
          paddingBottom: `${(images[0].dimensions.height / images[0].dimensions.width) * 100}%`,
        }}
      /> */}
      <CarouselWrapper
        ref={$wrapper}
        onPan={onPan}
        onPanStart={onPanStart}
        onPanEnd={onPanEnd}
        style={{ x }}
        className={isCarousel ? 'grab' : null}
      >
        {Children.map(children, (child, index) => {
          return cloneElement(child, {
            style: { width },
            key: index,
          });
        })}
        {/* {images.map((image, index) => (
          <Image style={{ width }} key={image.url + index} {...image} quality={imagesQuality} />
        ))} */}
      </CarouselWrapper>
      {isCarousel && (
        <CarouselDots
          length={length}
          active={activeIndex}
          onClickDot={(index) => setActiveIndex(index)}
        />
      )}
      {isCarousel && (
        <Button
          component={motion.div}
          background="small"
          color="green"
          style={{ x: dragX, y: dragY, rotate: dragRotate, scale: dragScale, opacity: dragOpacity }}
          transformTemplate={({ x, y, rotate, scale }) =>
            `translateX(-50%) translate(${x}, ${y}) rotate(${rotate}) scale(${scale})`
          }
        >
          {siteData.i18n_drag}
        </Button>
      )}
    </div>
  );
};

export const Carousel = styled(CarouselComponent)`
  position: relative;
  width: 100%;
  overflow: visible;
  grid-column: 1 / -1;

  & > ${Button} {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    color: ${({ theme }) => theme.colors.dust};
  }

  ${media.mobile`
    & > ${Button} {
      display: none;
    }
  `}
`;

export const CarouselWrapper = styled(motion.div)`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  width: max-content;
  user-select: none;
  touch-action: pan-y;

  &.grab {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  ${Image} {
    flex-shrink: 0;
    pointer-events: none;
  }
`;
