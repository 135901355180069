import { Panel } from 'components/Panel';
import propsToDom from 'core/helpers/propsToDom';
import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { ContainerGrid } from 'components/Container';
import { Title2, Title4 } from 'styles/typography';
import Image from 'components/Image';
import RichText from 'components/RichText';
import { rem } from 'styles/utils';
import media from 'styles/media';
import useParallax from 'hooks/useParallax';
import clamp from 'core/helpers/clamp';
import { motion } from 'framer-motion';
import propsToLink from 'core/helpers/propsToLink';
import { Button } from 'components/Button';
import { LoopText } from 'components/LoopText';

const CasaEminenteComponent = ({ slice_type, primary, ...others }) => {
  const { looped_text, main_title, text, button_link, button_text, left_image, right_image } = primary;
  const hasTitle = !!main_title?.text;
  const hasImageLeft = !!left_image?.url;
  const hasImageRight = !!right_image?.url;
  const hasText = text?.richText?.length > 0;
  const hasButton = button_link?.link_type !== 'Any' && button_text;

  const [$parallax, y] = useParallax((y, b) => {
    const max = b.h / 10;
    return clamp(y * -max, -max, max);
  });

  return (
    <Panel {...propsToDom(others)} id={slice_type}>
      {looped_text && (
        <LoopText>
          <Title2 as="span">
            {looped_text}
            <img src="/images/illustrations/start.png" role="presentation" loading="lazy" />
          </Title2>
        </LoopText>
      )}
      <Container>
        {hasImageLeft && (
          <div>
            <Image {...left_image} />
            <Door
              src="/images/illustrations/door-green.png"
              role="presentation"
              loading="lazy"
              ref={$parallax}
              style={{ y }}
              transformTemplate={({y}) => `translate(-50%, -50%) translateY(${y})`}
            />
          </div>
        )}
        {hasImageRight && <div><Image {...right_image} /></div>}
        {hasText && (
          <Content>
            {hasTitle && <Title4 as="h2">{main_title.text}</Title4>}
            <RichText render={text.richText} />
            {hasButton && (
              <Button color="green" background="medium" {...propsToLink(button_link)}>
                {button_text}
              </Button>
            )}
          </Content>
        )}

      </Container>
    </Panel>
  );
};

export const CasaEminente = styled(CasaEminenteComponent)`
  padding-top: ${rem(20)};

  ${LoopText} {
    margin-bottom: ${rem(50)};
  }

  ${Title2} {
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;

    img {
      width: ${rem(60)};
      height: ${rem(60)};
      margin: 0 ${rem(80)};
    }
  }
`;

const Container = styled(ContainerGrid)`
  & > div {
    position: relative;

    &:nth-child(1) {
      grid-column: auto / span 8;
    }

    &:nth-child(2) {
      grid-column: auto / span 4;
    }
  }

  ${media.mobile`
    & > div:nth-child(1) {
      margin-bottom: ${rem(40)};
    }
  `}
`;

const Content = styled.div`
  grid-column: 8 / -2;
  padding-top: ${rem(60)};

  ${Title4} {
    margin-bottom: ${rem(40)};
  }

  ${RichText} {
    position: relative;
    padding-top: ${rem(20)};

    &:before {
      content: '';
      display: block;
      width: ${rem(110)};
      height: 1px;
      background: ${({ theme }) => theme.colors.green};
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  ${Button} {
    margin-top: ${rem(40)};
  }
`;

const Door = styled(motion.img)`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: ${rem(200)};

  ${media.mobile`
    width: ${rem(87)};
  `}
`;

export const query = graphql`
  fragment SliceCasaEminente on PrismicPageHomeDataBodyCasaeminente {
    id
    slice_type
    slice_label
    primary {
      looped_text
      main_title {
        text
      }
      text {
        richText
      }
      button_link {
        link_type
        target
        url
      }
      button_text
      left_image {
        url
        dimensions {
          width
          height
        }
      }
      right_image {
        url
        dimensions {
          width
          height
        }
      }
    }
  }
`;
