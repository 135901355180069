import React from 'react';
import styled from 'styled-components';
import LineVertical from '-!svg-react-loader!../assets/svgs/line-vertical.svg';
import LineHorizontal from '-!svg-react-loader!../assets/svgs/line-horizontal.svg';
import ButtonLineSmall from '-!svg-react-loader!../assets/svgs/button-line-small.svg';
import ButtonLineMedium from '-!svg-react-loader!../assets/svgs/button-line-medium.svg';
import ButtonLineBuy from '-!svg-react-loader!../assets/svgs/button-line-buy.svg';
import ButtonLineBuyMobile from '-!svg-react-loader!../assets/svgs/button-line-buy-mobile.svg';
import Dash from '-!svg-react-loader!../assets/svgs/dash.svg';
import Logo from '-!svg-react-loader!../assets/svgs/logo.svg';
import Burger from '-!svg-react-loader!../assets/svgs/burger.svg';
import Close from '-!svg-react-loader!../assets/svgs/close.svg';
import Checkbox from '-!svg-react-loader!../assets/svgs/checkbox.svg';
import ArrowDropdown from '-!svg-react-loader!../assets/svgs/arrow-dropdown.svg';
import CarouselDot from '-!svg-react-loader!../assets/svgs/carousel-dot.svg';
import Plus from '-!svg-react-loader!../assets/svgs/plus.svg';
import Minus from '-!svg-react-loader!../assets/svgs/minus.svg';
import ArrowRight from '-!svg-react-loader!../assets/svgs/arrow-right.svg';
import Facebook from '-!svg-react-loader!../assets/svgs/facebook.svg';
import Instagram from '-!svg-react-loader!../assets/svgs/instagram.svg';
import Pinterest from '-!svg-react-loader!../assets/svgs/pinterest.svg';
import Spotify from '-!svg-react-loader!../assets/svgs/spotify.svg';
import Twitter from '-!svg-react-loader!../assets/svgs/twitter.svg';
import Search from '-!svg-react-loader!../assets/svgs/search.svg';
import BigBag from '-!svg-react-loader!../assets/svgs/big-bag.svg';

const Icon = ({ id, ...others }) => {
  switch (id) {
    case 'line-vertical':
      return <LineVertical data-id={id} {...others} />;
    case 'line-horizontal':
      return <LineHorizontal data-id={id} {...others} />;
    case 'button-line-small':
      return <ButtonLineSmall data-id={id} {...others} />;
    case 'button-line-medium':
      return <ButtonLineMedium data-id={id} {...others} />;
    case 'button-line-buy':
      return <ButtonLineBuy data-id={id} {...others} />;
    case 'button-line-buy-mobile':
      return <ButtonLineBuyMobile data-id={id} {...others} />;
    case 'dash':
      return <Dash data-id={id} {...others} />;
    case 'logo':
      return <Logo data-id={id} {...others} />;
    case 'burger':
      return <Burger data-id={id} {...others} />;
    case 'close':
      return <Close data-id={id} {...others} />;
    case 'checkbox':
      return <Checkbox data-id={id} {...others} />;
    case 'arrow-dropdown':
      return <ArrowDropdown data-id={id} {...others} />;
    case 'carousel-dot':
      return <CarouselDot data-id={id} {...others} />;
    case 'plus':
      return <Plus data-id={id} {...others} />;
    case 'minus':
      return <Minus data-id={id} {...others} />;
    case 'arrow-right':
      return <ArrowRight data-id={id} {...others} />;
    case 'facebook':
      return <Facebook data-id={id} {...others} />;
    case 'instagram':
      return <Instagram data-id={id} {...others} />;
    case 'pinterest':
      return <Pinterest data-id={id} {...others} />;
    case 'spotify':
      return <Spotify data-id={id} {...others} />;
    case 'twitter':
      return <Twitter data-id={id} {...others} />;
    case 'big-bag':
      return <BigBag data-id={id} {...others} />;
    case 'search':
      return <Search data-id={id} {...others} />;
    default:
      return null;
  }
};

export default styled(Icon)`
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  vertical-align: top;

  &[data-id='line-vertical'] {
    width: ${3 / 81}em;
  }

  &[data-id='line-horizontal'] {
    width: ${81 / 3}em;
  }

  &[data-id='button-line-small'] {
    width: ${108 / 50}em;
  }

  &[data-id='button-line-buy'] {
    width: ${206 / 50}em;
  }

  &[data-id='button-line-buy-mobile'] {
    width: ${56 / 50}em;
  }

  &[data-id='button-line-medium'] {
    width: ${206 / 50}em;
  }

  &[data-id='dash'] {
    width: ${108 / 10}em;
  }

  &[data-id='logo'] {
    width: ${166 / 25}em;
  }

  &[data-id='burger'] {
    width: ${30 / 18}em;
  }

  &[data-id='close'] {
    width: ${20 / 18}em;
  }

  &[data-id='arrow-dropdown'] {
    width: ${18 / 10}em;
  }

  &[data-id='carousel-dot'] {
    width: ${10 / 4}em;
  }

  &[data-id='minus'] {
    width: ${18 / 4}em;
  }

  &[data-id='arrow-right'] {
    width: ${12 / 10}em;
  }

  &[data-id='facebook'],
  &[data-id='instagram'],
  &[data-id='pinterest'],
  &[data-id='spotify'] {
    width: ${20 / 21}em;
  }

  &[data-id='twitter'] {
    width: ${20 / 17}em;
  }

  &[data-id='big-bag'] {
    width: ${22 / 18}em;
  }
`;
