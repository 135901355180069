import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Modal, Container as ModalContainer, Close as ModalClose } from './Modal';
import { LinkUnderline } from './LinkUnderline';
import { BuyRetailers } from './BuyRetailers';
import { usePageContext } from 'store/PageProvider';
import StoreLocator from './StoreLocator';
import MapProvider from 'store/MapProvider';
import { useGlobalContext } from 'store/GlobalProvider';

const PANELS = ['retailers', 'store-locator'];

const ModalBuyComponent = ({ ...others }) => {
  const { modalBuy } = usePageContext();
  const { storeLocatorIso } = useGlobalContext();
  const [panel, setPanel] = useState(PANELS[0]);
  const hasStoreLocator = storeLocatorIso !== null;

  return (
    <Modal
      {...others}
      id="buy"
      isFullWidth={true}
      header={(close) => (
        <Fragment>
          <Nav>
            <LinkUnderline isActive={panel === 'retailers'} onClick={() => setPanel('retailers')}>
              {modalBuy.retailers_nav_title}
            </LinkUnderline>
            {hasStoreLocator && (
              <LinkUnderline
                isActive={panel === 'store-locator'}
                onClick={() => setPanel('store-locator')}
              >
                {modalBuy.sl_nav_title}
              </LinkUnderline>
            )}
          </Nav>
          {close}
        </Fragment>
      )}
    >
      {panel === 'retailers' && <BuyRetailers />}
      {panel === 'store-locator' && hasStoreLocator && (
        <MapProvider storeLocatorIso={storeLocatorIso}>
          <StoreLocator />
        </MapProvider>
      )}
    </Modal>
  );
};

export const ModalBuy = styled(ModalBuyComponent)`
  ${ModalContainer} {
    height: auto;
    max-height: ${({ theme }) => `calc(100% - var(--header-height, ${theme.spacing.s9})) `};
  }

  ${ModalClose} {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Nav = styled.nav`
  margin: 0 auto;

  ${LinkUnderline}:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.s8};
  }

  --accent-color: ${({ theme }) => theme.colors.red};
`;
