import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useMapContext } from 'store/MapProvider';
import StoreLocatorNavItem from './StoreLocatorNavItem';
import { useDebounce, useUpdateEffect } from 'react-use';
import { LinkUnderline } from './LinkUnderline';
import { rem } from 'styles/utils';
import { linkTransition } from 'styles/transitions';
import { buttonReset, hiddenScrollbarCSS } from 'styles/others';
import Icon from './Icon';
import { rgba } from 'polished';
import media from 'styles/media';
import { usePageContext } from 'store/PageProvider';
import { useJsApiLoader } from '@react-google-maps/api';

const libraries = ['places'];

const StoreLocatorNav = ({ ...others }) => {
  // ------------------------------------------------------------
  //    REFS
  // ------------------------------------------------------------
  const autocomplete = useRef(null);
  const geocoder = useRef(null);
  const scrollRef = useRef(null);
  const navScrollRef = useRef(null);
  const inputRef = useRef(null);

  // ------------------------------------------------------------
  //    STATE
  // ------------------------------------------------------------
  const { modalBuy, siteData } = usePageContext();
  const {
    retailers,
    activeRetailer,
    selectedPlace,
    setSelectedPlace,
    activeCategory,
    setActiveRetailer,
    setActiveCategory,
  } = useMapContext();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.GATSBY_GOOGLE_API_KEY,
    libraries,
  });
  const [nonGeocodedSelected, setNonGeocodedSelected] = useState(null);
  const [predictions, setPredictions] = useState([]);
  const [term, setTerm] = useState('');
  const [debouncedTerm, setDebouncedTerm] = useState('');
  const [, cancel] = useDebounce(() => setDebouncedTerm(term), 250, [term]);

  // ------------------------------------------------------------
  //    STATIC DATA
  // ------------------------------------------------------------
  const categories = [
    { id: 'wine merchant', label: modalBuy.sl_wine_merchant_label },
    { id: 'bars & restaurants', label: modalBuy.sl_bars_restaurants_label },
    { id: 'hotels', label: modalBuy.sl_hotels_label },
  ];

  // ------------------------------------------------------------
  //    EFFECTS
  // ------------------------------------------------------------
  // Instanciate autocomplete service
  useEffect(() => {
    if (isLoaded && typeof window !== 'undefined') {
      autocomplete.current = new window.google.maps.places.AutocompleteService();
      geocoder.current = new window.google.maps.Geocoder();
    }
  }, [isLoaded]);

  useEffect(() => {
    const fetch = async () => {
      const res = await geocoder.current.geocode({ placeId: nonGeocodedSelected.id });
      if (res.results.length > 0) {
        setPredictions([]);
        setSelectedPlace({
          location: res.results[0].geometry.location.toJSON(),
          id: res.results[0].place_id,
          address: res.results[0].formatted_address,
        });
      }
    };

    if (nonGeocodedSelected && geocoder.current) {
      fetch();
    }
  }, [nonGeocodedSelected]);

  // Scroll to active retailer
  useUpdateEffect(() => {
    if (activeRetailer?.uid) {
      const $active = scrollRef.current.querySelector(`[data-uid="${activeRetailer.uid}"]`);

      scrollRef.current.scrollTo({
        top: $active.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [activeRetailer]);

  const onReceivePredictions = (response, status) => {
    if (status != window.google.maps.places.PlacesServiceStatus.OK || !response) {
      return;
    }
    setPredictions(
      response.map(({ description, place_id }) => ({ id: place_id, text: description }))
    );
  };

  useEffect(() => {
    // Do nothing if autocomplete is not ready or field is empty
    if (autocomplete.current === null || debouncedTerm === '') return;

    const value = debouncedTerm;
    const selected = predictions.find(({ text }) => text === value);

    if (selected) {
      // This is the selected option from datalist
      inputRef.current.blur();
      setNonGeocodedSelected(selected);
    } else {
      // Query predictions
      autocomplete.current.getQueryPredictions({ input: value }, onReceivePredictions);
    }
  }, [debouncedTerm]);

  // ------------------------------------------------------------
  //    FUNCTIONS
  // ------------------------------------------------------------
  const onClickCategory = (id) => {
    setActiveRetailer(null);
    setActiveCategory(id);
  };

  const scrollKey =
    selectedPlace !== null ? `${activeCategory}-${selectedPlace.address}` : activeCategory;

  return (
    <div {...others}>
      <div>
        <SearchForm
          onSubmit={(e) => {
            e.preventDefault();
            inputRef.current.blur();
            if (predictions.length > 0) {
              cancel();
              setDebouncedTerm(predictions[0].text);
              setTerm(predictions[0].text);
            }
          }}
        >
          <Icon id="search" />
          <input
            type="text"
            list="map-search-data"
            ref={inputRef}
            placeholder={modalBuy.sl_search_placeholder}
            value={term}
            onInput={(e) => setTerm(e.target.value)}
          />
          <datalist id="map-search-data">
            {predictions.map(({ text, id }, index) => (
              <option key={id} value={text} />
            ))}
          </datalist>
          <ButtonReset
            type="button"
            aria-label={modalBuy.search_erase_placeholder}
            onClick={() => {
              cancel();
              setSelectedPlace(null);
              setTerm('');
            }}
          >
            <Icon id="close" />
          </ButtonReset>
        </SearchForm>
        <Nav ref={navScrollRef}>
          <NavScroller>
            <LinkUnderline
              isActive={activeCategory === null}
              data-category="all"
              onClick={() => onClickCategory(null)}
            >
              {siteData.i18n_all}
            </LinkUnderline>
            {categories.map(({ id, label }) => (
              <LinkUnderline
                key={id}
                data-category={id}
                isActive={id === activeCategory}
                onClick={() => onClickCategory(id)}
              >
                {label}
              </LinkUnderline>
            ))}
          </NavScroller>
        </Nav>
      </div>
      <Scroller ref={scrollRef} key={scrollKey}>
        {retailers?.map((retailer, index) => {
          return <StoreLocatorNavItem retailer={retailer} key={retailer.uid} />;
        })}
      </Scroller>
    </div>
  );
};

const SearchForm = styled.form`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing.s1_5} 0 ${theme.spacing.s1_5} ${theme.spacing.s6}`};
  background: ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.dust};
  border-bottom: 1px solid ${({ theme }) => theme.colors.dust};

  ${Icon} {
    font-size: ${rem(14)};
    flex-shrink: 0;
  }

  .input {
    flex-grow: 3;
  }

  input {
    box-sizing: border-box;
    width: 100%;
    ${buttonReset}
    padding-left: ${({ theme }) => theme.spacing.s1};
    color: ${({ theme }) => theme.colors.dust};

    &::placeholder {
      color: ${({ theme }) => rgba(theme.colors.dust, 0.5)};
    }
  }

  ${media.mobile`
    padding-left: ${rem(16)};
  `}
`;

const ButtonReset = styled.button`
  ${buttonReset}
  flex-shrink: 0;
  padding: ${rem(4)} ${rem(18)};
  border-left: 1px solid currentColor;
  display: inline-flex;
  align-items: center;
`;

const Nav = styled.nav`
  ${hiddenScrollbarCSS}
  position: relative;
  background: ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.dust};
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-padding-left: ${({ theme }) => theme.spacing.s6};
`;

const NavScroller = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  min-width: 100%;
  padding: ${({ theme }) => `${theme.spacing.s1_5} ${theme.spacing.s6}`};

  ${LinkUnderline} {
    white-space: nowrap;
    opacity: 0.3;
    ${linkTransition}
    transition-property: opacity;

    &.is-active {
      opacity: 1;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        opacity: 1;
      }
    }

    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.s3};
    }
  }

  ${media.mobile`
    padding-left: ${rem(16)};
    padding-right: ${rem(16)};
  `}
`;

const Scroller = styled.div`
  ${hiddenScrollbarCSS}
  position: relative;
  justify-content: stretch;
  flex-grow: 3;
  overflow-y: scroll;
`;

export default styled(StoreLocatorNav)`
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${media.tabletPortrait`
    width: 100%;
  `}
`;
