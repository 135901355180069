import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useGlobalContext } from 'store/GlobalProvider';
import { usePageContext } from 'store/PageProvider';
import styled from 'styled-components';
import media from 'styles/media';
import { Title4 } from 'styles/typography';
import { rem } from 'styles/utils';
import { Container, ContainerGrid } from './Container';
import Icon from './Icon';
import { NavContactSubscribeSocials } from './NavContactSubscribeSocials';
import { NavLang } from './NavLang';
import { NavRoman } from './NavRoman';
import { NavSecondary } from './NavSecondary';
import RichText from './RichText';
import { Waves } from './Waves';
import { isFilled } from '@prismicio/client';

const FooterComponent = ({ ...others }) => {
  const { siteData } = usePageContext();
  const { setHeaderTheme } = useGlobalContext();
  const {
    crocodile_title,
    crocodile_text,
    authentic_title,
    authentic_text,
    legal_baseline,
    legal_text,
  } = siteData;

  const hasLegalText = isFilled.richText(legal_text.richText);

  const [$footer, inView] = useInView({ threshold: 0.0, rootMargin: '0px 0px -99% 0px' });

  useEffect(() => {
    if (inView) {
      setHeaderTheme('dark');
    }
  }, [inView, setHeaderTheme]);

  return (
    <footer ref={$footer} {...others}>
      <Main>
        <NavRoman menu={siteData.main_menu} />
        <NavContactSubscribeSocials />
      </Main>
      <Secondary>
        <SecondaryLogo>
          <Icon id="logo" />
        </SecondaryLogo>
        <SecondaryWaves>
          <Waves />
          <Container $noMobileMargin={true}>
            <div>
              <Title4>{crocodile_title.text}</Title4>
              <RichText dangerouslySetInnerHTML={{ __html: crocodile_text.html }} />
            </div>
            <div>
              <img src="/images/logo-croc-black.png" width="532" height="172" role="presentation" />
            </div>
            <div>
              <Title4>{authentic_title.text}</Title4>
              <RichText dangerouslySetInnerHTML={{ __html: authentic_text.html }} />
              <Signature
                src="/images/illustrations/signature.png"
                alt="Signature Eminente"
                width="88"
                height="56"
              />
            </div>
          </Container>
        </SecondaryWaves>
        {legal_baseline && (
          <LegalContainer>
            <p>{legal_baseline}</p>
          </LegalContainer>
        )}
        <SecondaryLast>
          <Container $noMobileMargin={true}>
            <Copyright>Eminente {new Date().getFullYear()}©</Copyright>
            <NavSecondary />
            <NavLang />
          </Container>
        </SecondaryLast>
      </Secondary>
      {hasLegalText && (
        <Tertiary>
          <RichText render={legal_text.richText} />
        </Tertiary>
      )}
    </footer>
  );
};

export const Footer = styled(FooterComponent)`
  position: relative;
  overflow: hidden;
  background: url('/images/bg-footer.jpg') repeat ${({ theme }) => theme.colors.dust};
  background-size: 128px;
`;

const Main = styled(ContainerGrid)`
  padding: ${({ theme }) => theme.spacing.s8} 0;

  ${NavRoman} {
    grid-column: 1 / span 4;
  }

  ${NavContactSubscribeSocials} {
    grid-column: -1 / -5;
  }

  ${media.tabletPortrait`
    ${NavContactSubscribeSocials} {
      grid-column: -1 / -6;
    }
  `}

  ${media.mobile`
    padding: ${({ theme }) => theme.spacing.s4} 0;

    ${NavRoman} {
      margin-bottom: ${rem(40)};
    }
  `}
`;

const Secondary = styled.div`
  background-color: ${({ theme }) => theme.colors.dust};
`;

const SecondaryLogo = styled.div`
  padding: ${({ theme }) => theme.spacing.s1};
  text-align: center;
  font-size: ${rem(25)};
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: currentColor;

  ${Icon} {
    display: block;
    margin: auto;
  }
`;

const LegalContainer = styled(Container)`
  text-align: center;
  font-size: ${rem(10.5)};
  line-height: ${20 / 10.5};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  text-transform: uppercase;
`;

const SecondaryWaves = styled.div`
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};

  ${Waves} {
    z-index: 1;
  }

  ${Title4} {
    text-transform: uppercase;
    margin-bottom: ${({ theme }) => theme.spacing.s1};
  }

  ${RichText} {
    position: relative;
    text-transform: uppercase;
    font-size: ${rem(10.5)};
    line-height: ${20 / 10.5};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    background: ${({ theme }) => theme.colors.dust};

    p {
      position: relative;
      z-index: 3;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 99em;
      background: inherit;
    }

    &:before {
      right: 100%;
    }

    &:after {
      left: 100%;
    }
  }

  ${Container} {
    display: flex;
    position: relative;
    z-index: 2;
  }

  ${Container} > * {
    position: relative;
    padding: ${({ theme }) => theme.spacing.s2} 0;

    &:nth-child(1) {
      padding-right: ${({ theme }) => theme.spacing.s5};

      ${RichText} {
        clip-path: ${({ theme }) => `inset(0 -${theme.spacing.s5} 0 -99rem) `};
      }
    }

    &:nth-child(2) {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      width: ${rem(382)};
      padding-left: ${({ theme }) => theme.spacing.s5};
      padding-right: ${({ theme }) => theme.spacing.s5};
      border-width: 0 1px 0 1px;
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.black};

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    &:nth-child(3) {
      padding-left: ${({ theme }) => theme.spacing.s5};

      ${RichText} {
        clip-path: ${({ theme }) => `inset(0 -99rem 0 -${theme.spacing.s5}) `};
      }
    }
  }

  ${media.mobile`
    ${Container} {
      flex-direction: column;
      text-align: center;
    }

    ${Container} > * {
      padding: ${({ theme }) => theme.spacing.s2} 0;

      &:nth-child(1) {
        padding-left: ${rem(16)};
        padding-right: ${rem(16)};
      }

      &:nth-child(2) {
        order: 3;
        width: 100%;
        border-width: 1px 0 0 0;

        img {
          max-width: ${rem(198)};
          margin: 0 auto;
        }
      }

      &:nth-child(3) {
        padding-left: ${rem(16)};
        padding-right: ${rem(16)};
      }
    }
  `}
`;

const Signature = styled.img`
  position: absolute;
  left: ${({ theme }) => `calc(100% - ${theme.spacing.s1}) `};
  bottom: ${({ theme }) => theme.spacing.s3};
  width: ${rem(44)};
  height: auto;

  ${media.mobile`
    left: ${({ theme }) => `calc(100% - ${theme.spacing.s5}) `};
    bottom: ${({ theme }) => theme.spacing.s1};
  `}
`;

const SecondaryLast = styled.div`
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: currentColor;

  ${Container} {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > * {
      padding: ${({ theme }) => `${theme.spacing.s2} ${theme.spacing.s6}`};

      &:nth-child(1) {
        padding-left: 0;
      }

      &:nth-child(2) {
        border-right: 1px solid ${({ theme }) => theme.colors.black};
      }

      &:nth-child(3) {
        padding-right: 0;
      }
    }
  }

  ${media.mobile`
    ${Container} {
      flex-wrap: wrap;
      margin-left: 0;
      margin-right: 0;

      & > * {
        padding: ${({ theme }) => `${theme.spacing.s2} ${rem(16)}`};

        &:nth-child(1) {
          order: 1;
          padding-left: ${rem(16)};
        }

        &:nth-child(2) {
          order: 0;
          border-bottom: 1px solid ${({ theme }) => theme.colors.black};
          border-right: 0;
        }

        &:nth-child(3) {
          order: 2;
          padding-left: ${({ theme }) => theme.spacing.s2};
          padding-right: ${rem(16)};
          border-left: 1px solid ${({ theme }) => theme.colors.black};
        }

      }
    }
  `}
`;

const Copyright = styled.div`
  line-height: ${20 / 14};
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const Tertiary = styled(LegalContainer)`
  line-height: ${20 / 10.5};
`;
