import { useCookie } from 'react-use';
import { useGlobalContext } from 'store/GlobalProvider';

const useAgegateCookie = () => {
  const [cookie, updateCookie, deleteCookie] = useCookie('age-validated');
  const { setShowAgeGate } = useGlobalContext();

  const update = ({ day, month, year = null, country }, options) => {
    if (year !== null) setShowAgeGate(false);
    const value = JSON.stringify({ day, month, year, country });
    updateCookie(value, options);
  };

  const cookieValue = JSON.parse(cookie) || { year: null, country: null };

  return [cookieValue, update, deleteCookie];
};

export default useAgegateCookie;
