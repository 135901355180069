import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import propsToDom from 'core/helpers/propsToDom';
import { Panel } from 'components/Panel';
import { Carousel } from 'components/Carousel';
import { asHTML, isFilled } from '@prismicio/client';
import { ImageCover } from 'components/ImageCover';
import { fontMaar, fontTitle } from 'styles/typography';
import { rem } from 'styles/utils';
import linkResolver from 'core/linkResolver';
import { Button } from 'components/Button';
import { CarouselDots } from 'components/CarouselDots';
import { useGlobalContext } from 'store/GlobalProvider';
import { useInView } from 'framer-motion';
import media from 'styles/media';

function getHeaderTheme(city) {
  switch (city) {
    case 'bordeaux':
    case 'lyon':
    case 'paris':
      return 'light-dust';
    default:
      return 'dark';
  }
}

const STICKERS = {
  paris: { width: 338 },
  lyon: { width: 340 },
  lille: { width: 338 },
  bordeaux: { width: 604 },
  marseille: { width: 604 },
};

const HeroTourCarouselComponent = ({ slice_type, items: sliceItems, ...others }) => {
  const $ref = useRef(null);
  const { setHeaderTheme, isMobile } = useGlobalContext();
  const items = sliceItems.filter(({ image }) => isFilled.image(image));
  const [localHeaderTheme, setLocalHeaderTheme] = useState(
    getHeaderTheme(items?.[0]?.city?.toLowerCase())
  );

  const inView = useInView($ref, {
    margin: '0px 0px -99% 0px',
    amount: 0,
  });

  const onActiveChange = (index) => {
    if (!items[index]?.city) return;
    const { city } = items[index];
    const headerTheme = getHeaderTheme(city?.toLowerCase());
    setLocalHeaderTheme(headerTheme);
  };

  useEffect(() => {
    setHeaderTheme(localHeaderTheme);
  }, [localHeaderTheme]);

  useEffect(() => {
    if (inView) {
      setHeaderTheme(localHeaderTheme);
    }
  }, [inView]);

  return (
    <Panel ref={$ref} {...propsToDom(others)} id={slice_type} noSetupHeaderTheme>
      <Carousel onActiveChange={onActiveChange}>
        {items.map(
          (
            { image, city: cityUppercase, item_title, item_subtitle, link, link_label, legend },
            index
          ) => {
            const hasTitle = isFilled.richText(item_title?.richText);
            const hasSubtitle = isFilled.richText(item_subtitle?.richText);
            const hasLegend = isFilled.keyText(legend);
            const url = link?.url || linkResolver(link);
            const imageProps =
              isMobile && image?.thumbnails?.mobile ? image.thumbnails.mobile : image;

            const city = cityUppercase?.toLowerCase();
            const sticker = STICKERS?.[city] ? `/images/towns/sticker-${city}.png` : null;

            return (
              <Item key={index} data-city={city?.toLowerCase()}>
                <ImageCover {...imageProps} quality={95} />
                <ItemContent>
                  {sticker && (
                    <img
                      src={sticker}
                      alt=""
                      style={{ width: STICKERS[city].width / (isMobile ? 4 : 2) }}
                      loading="lazy"
                    />
                  )}
                  {hasTitle && (
                    <Title dangerouslySetInnerHTML={{ __html: asHTML(item_title.richText) }} />
                  )}
                  {hasSubtitle && (
                    <Subtitle
                      dangerouslySetInnerHTML={{ __html: asHTML(item_subtitle.richText) }}
                    />
                  )}
                  {url && (
                    <Button to={url} color="dust" background="medium">
                      {link_label}
                    </Button>
                  )}
                  {hasLegend && <Legend>{legend}</Legend>}
                </ItemContent>
              </Item>
            );
          }
        )}
      </Carousel>
    </Panel>
  );
};

const Title = styled.h2`
  ${fontMaar};
  text-transform: uppercase;
  font-size: ${rem(80)};
  line-height: ${110 / 96};
  max-width: 70%;
  text-shadow: 0 0 ${rem(5)} rgba(0, 0, 0, 0.2);

  [data-city='lyon'] & {
    max-width: 100%;
  }

  [data-city='marseille'] & {
    max-width: 50%;
  }

  ${media.mobile`
    font-size: ${rem(44)};
    max-width: 100% !important;
    white-space: initial;
  `}
`;

const Subtitle = styled.p`
  ${fontMaar};
  font-size: ${rem(48)};
  line-height: ${110 / 96};

  ${media.mobile`
    font-size: ${rem(32)};
  `}
`;

const Legend = styled.p`
  ${fontTitle};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-top: calc(var(--viewport-height, 100vh) * 0.1);

  ${media.mobile`
    margin-top: ${rem(40)};
    font-size: ${rem(10)};
  `}
`;

const ItemContent = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: ${rem(15)};
  z-index: 2;
  color: #e4dbcc;

  ${Button} {
    margin-top: ${rem(20)};
  }

  ${media.fromMobile`
    padding: ${rem(35)};

    [data-city='lyon'] & {
      padding-left: ${rem(380)};
    }
  `}
`;

const Item = styled.div`
  position: relative;
  width: 100vw;
  height: max(var(--viewport-height, 100vh), 600px);
`;

export const HeroTourCarousel = styled(HeroTourCarouselComponent)`
  ${Carousel} {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  ${CarouselDots} {
    position: absolute;
    left: 50%;
    bottom: ${rem(20)};
    transform: translateX(-50%);
    z-index: 3;
    color: #e4dbcc;
  }

  ${media.mobile`
    ${CarouselDots} {
      bottom: ${rem(13)};
    }
  `}
`;

export const query = graphql`
  fragment SliceHeroTourCarouselHome on PrismicPageHomeDataBodyHeroTourCarousel {
    id
    slice_type
    slice_label
    items {
      city
      item_title {
        richText
      }
      item_subtitle {
        richText
      }
      link {
        target
        url
      }
      link_label
      legend
      image {
        alt
        url
        dimensions {
          width
          height
        }
        thumbnails {
          mobile {
            url
            alt
            dimensions {
              width
              height
            }
          }
        }
      }
    }
  }
`;
