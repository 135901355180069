import React from 'react';
import { ContainerGrid } from 'components/Container';
import Image from 'components/Image';
import { Panel } from 'components/Panel';
import RichText from 'components/RichText';
import propsToDom from 'core/helpers/propsToDom';
import { graphql } from 'gatsby';
import styled, { useTheme } from 'styled-components';
import { Title1, Title3, fontTitle, h4, highlightCSS } from 'styles/typography';
import { usePageContext } from 'store/PageProvider';
import { useGlobalContext } from 'store/GlobalProvider';
import { BuyRetailer, BuyRetailerBorder } from 'components/BuyRetailer';
import { rem } from 'styles/utils';
import Link from 'components/Link';
import media from 'styles/media';
import { asHTML, isFilled } from '@prismicio/client';
import propsToLink from 'core/helpers/propsToLink';
import { linkTransition } from 'styles/transitions';
import { rgba } from 'polished';

const ProductHighlightComponent = ({ slice_type, primary, items, ...others }) => {
  const { colors } = useTheme();
  const {
    color,
    main_title,
    subtitle,
    text,
    image,
    awards,
    link_label,
    retailers_override_link_label,
    retailers_override_link,
  } = primary;
  const { modalBuy } = usePageContext();
  const { userCountry, setModal } = useGlobalContext();

  const hasAwards = isFilled.image(awards);

  const { retailers } = modalBuy;
  const sliceRetailer = items.find(
    ({ country_code, product_url }) => country_code === userCountry && !!product_url?.url
  );
  const localRetailers = retailers.find(
    ({ primary }) => primary?.country_code === userCountry
  )?.items;

  const $croc = (
    <Croc
      src="/images/illustrations/croc-black.png"
      className={`filter-${color}`}
      width="108"
      height="88"
      loading="lazy"
      role="presentation"
    />
  );

  let $retailer = null;
  if (sliceRetailer || localRetailers) {
    const highlighted =
      sliceRetailer || localRetailers.find(({ is_highlighted }) => is_highlighted === true);

    $retailer = (
      <RetailerContainer>
        {$croc}
        <BuyRetailer color={color} is_highlighted={true} {...highlighted} />
      </RetailerContainer>
    );
  }

  const hasTitle = !!main_title?.text;
  const hasSubtitle = !!subtitle?.text;
  const hasText = !!text?.richText.length > 0;
  const hasLink = !!link_label;
  const hasOverrideRetailerLink =
    isFilled.link(retailers_override_link) && isFilled.keyText(retailers_override_link_label);

  return (
    <Panel {...propsToDom(others)} id={slice_type} style={{ '--accent': colors[color] }}>
      <ContainerGrid>
        <Content>
          {hasTitle && (
            <Title1>
              {main_title.text}
              {hasSubtitle && (
                <Title3
                  as="small"
                  dangerouslySetInnerHTML={{ __html: asHTML(subtitle.richText) }}
                />
              )}
            </Title1>
          )}
          {hasText && <RichText render={text.richText} />}
          {hasOverrideRetailerLink && (
            <RetailerContainer>
              <OverrideLinkRetailer {...propsToLink(retailers_override_link)} data-color={color}>
                {$croc}
                <BuyRetailerBorder color={colors[color]} />
                <span>{retailers_override_link_label}</span>
              </OverrideLinkRetailer>
            </RetailerContainer>
          )}
          {!hasOverrideRetailerLink && $retailer}
          {hasLink && (
            <LinkRetailer onClick={() => setModal({ id: 'buy' })}>{link_label}</LinkRetailer>
          )}
        </Content>
        <ImageContainer>
          <Image {...image} />
          {hasAwards && <ImageAwards {...awards} />}
        </ImageContainer>
      </ContainerGrid>
    </Panel>
  );
};

const RetailerContainer = styled.div`
  position: relative;
  max-width: ${rem(490)};
  margin-top: ${({ theme }) => theme.spacing.s9};
`;

const Croc = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-65%);
`;

const LinkRetailer = styled(Link)`
  ${fontTitle};
  font-size: ${rem(20)};
  line-height: ${24 / 20};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-top: ${rem(15)};
  text-decoration: underline;
`;

const Content = styled.div`
  grid-column: auto / span 8;

  ${Title1} {
    text-transform: uppercase;
    color: var(--accent);
  }

  ${Title3} {
    display: block;
  }

  ${RichText} {
    ${highlightCSS};
    margin-top: ${({ theme }) => theme.spacing.s3};
  }

  ${media.mobile`
    order: 2;
  `}
`;

const ImageContainer = styled.div`
  position: relative;
  grid-column: 9 / -1;
  padding: 0 ${rem(75)};
  align-self: flex-start;
  text-align: center;

  ${media.mobile`
    ${Image}:first-child {
      max-width: 140px;
      margin-left: auto;
      margin-right: auto;
    }
  `}
`;

const ImageAwards = styled(Image)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: ${rem(52)};
  transform: translateY(-50%);

  ${media.mobile`
    transform: translate(40%, -50%);
  `}
`;

const OverrideLinkRetailer = styled(Link)`
  transition-property: opacity, background-color, color;
  ${linkTransition}
  display: flex;
  align-items: center;
  align-content: center;
  padding: ${({ theme }) => theme.spacing.s1} 0;
  box-sizing: content-box;
  min-height: ${rem(58)};
  color: var(--accent, ${({ theme }) => theme.colors.red});
  padding: ${({ theme }) => theme.spacing.s5};
  text-align: center;
  justify-content: center;

  ${h4};
  text-transform: uppercase;

  & > svg {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      opacity: 0.5;
    }

    &:hover {
      opacity: 1;
      color: ${({ theme }) => theme.colors.dust};
      background-color: ${({ theme }) => rgba(theme.colors.red, 0.5)};
    }

    &[data-color='green']:hover {
      background-color: ${({ theme }) => rgba(theme.colors.green, 0.5)};
    }

    &[data-color='greenLight']:hover {
      background-color: ${({ theme }) => rgba(theme.colors.greenLight, 0.5)};
    }

    &[data-color='black']:hover {
      background-color: ${({ theme }) => rgba(theme.colors.black, 0.5)};
    }
  }
`;

export const ProductHighlight = styled(ProductHighlightComponent)``;

export const query = graphql`
  fragment SliceProductHighlight on PrismicPageRichDataBodyProductHighlight {
    id
    slice_type
    primary {
      color
      main_title {
        text
      }
      subtitle {
        richText
        text
      }
      text {
        richText
      }
      image {
        url
        alt
        dimensions {
          width
          height
        }
      }
      awards {
        url
        alt
        dimensions {
          width
          height
        }
      }
      link_label
      retailers_override_link_label
      retailers_override_link {
        url
        target
      }
    }
    items {
      logo {
        alt
        url
      }
      country_code
      bottle_price
      shipping_delivery
      shipping_price
      product_link_text
      product_url {
        url
        target
      }
    }
  }
`;
