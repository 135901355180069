import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { h3 } from 'styles/typography';
import RichText from 'components/RichText';
import propsToLink from 'core/helpers/propsToLink';
import { rem } from 'styles/utils';
import { Panel } from 'components/Panel';
import propsToDom from 'core/helpers/propsToDom';
import { Button } from 'components/Button';
import media from 'styles/media';

const IntroThumbsComponent = ({ slice_type, primary, ...others }) => {
  const { text, link, link_text, borders } = primary;
  const hasText = !!text?.richText?.length > 0;
  const hasLink = !!link?.url && !!link_text;

  return (
    <Panel {...propsToDom(others)} id={slice_type} data-borders={borders}>
      {hasText && <RichText render={text.richText} />}
      {hasLink && (
        <Button background="medium" color="green" {...propsToLink(link)}>
          {link_text}
        </Button>
      )}
    </Panel>
  );
};

export const IntroThumbs = styled(IntroThumbsComponent)`
  padding-top: ${({ theme }) => theme.spacing.s8};
  padding-bottom: ${({ theme }) => theme.spacing.s8};
  text-align: center;

  ${RichText} {
    ${h3}
    width: ${rem(912 + 16 * 2)};
    max-width: 100%;
    padding-left: ${rem(16)};
    padding-right: ${rem(16)};
    margin: 0 auto;

    p {
      display: inline;
      vertical-align: middle;
    }

    figure {
      display: inline-block;
      vertical-align: middle;
      line-height: 0;
      width: ${rem(80)};

      img {
        display: inline;
      }
    }

    figcaption {
      display: none;
    }
  }

  ${media.mobile`
    ${RichText} {
      font-size: ${rem(24)};
      line-height: ${29 / 24};
    }
  `}
`;

export const query = graphql`
  fragment SliceIntroThumbs on PrismicPageHomeDataBodyIntroThumbs {
    id
    slice_type
    slice_label
    primary {
      borders
      text {
        richText
        html
      }
      link {
        target
        url
      }
      link_text
    }
  }

  fragment SliceIntroThumbsRich on PrismicPageRichDataBodyIntroThumbs {
    id
    slice_type
    slice_label
    primary {
      borders
      text {
        richText
        html
      }
      link {
        target
        url
      }
      link_text
    }
  }
`;
