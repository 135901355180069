import React from 'react';
import * as Slices from './slices';

const SliceZone = ({ allSlices = [], openedPostCareers, categoriesCareers }) => {
  const slices = allSlices.map((s, index) => {
    const key = s.id ? s.id : `${s.slice_type}-${index}`;
    const indexOfType = allSlices.filter((slice) => slice.slice_type === s.slice_type).indexOf(s);
    const props = { ...s, indexOfType };

    switch (s.slice_type) {
      case 'hero':
        return <Slices.Hero key={key} {...props} />;
      case 'bottle':
        return <Slices.Bottle key={key} {...props} />;
      case 'intro_thumbs':
        return <Slices.IntroThumbs key={key} {...props} />;
      case 'tasting':
        return <Slices.Tasting key={key} {...props} />;
      case 'how_to_drink':
        return <Slices.HowToDrink key={key} {...props} />;
      case 'movie':
        return <Slices.Movie key={key} {...props} />;
      case 'push_explore':
        return <Slices.PushExplore key={key} {...props} />;
      case 'duo_images_text':
        return <Slices.DuoImagesText key={key} {...props} />;
      case 'quote_bottle':
        return <Slices.QuoteBottle key={key} {...props} />;
      case 'image_text':
        return <Slices.ImageText key={key} {...props} />;
      case 'slider_isla_cocodrilo':
        return <Slices.SliderIslaCocodrilo key={key} {...props} />;
      case 'text':
        return <Slices.Text key={key} {...props} />;
      case 'accordion':
        return <Slices.Accordion key={key} {...props} />;
      case 'slider':
        return <Slices.Slider key={key} {...props} />;
      case 'title_text':
        return <Slices.TitleText key={key} {...props} />;
      case 'relateds':
        return <Slices.Relateds key={key} {...props} />;
      case 'push_map':
        return <Slices.PushMap key={key} {...props} />;
      case 'casaeminente':
        return <Slices.CasaEminente key={key} {...props} />;
      case 'productspush':
        return <Slices.ProductsPush key={key} {...props} />;
      case 'push_center':
        return <Slices.PushCenter key={key} {...props} />;
      case 'product_highlight':
        return <Slices.ProductHighlight key={key} {...props} />;
      case 'latest_news':
        return <Slices.LatestNews key={key} {...props} />;
      case 'submenu':
        return <Slices.Submenu key={key} {...props} />;
      case 'slider_text':
        return <Slices.SliderText key={key} {...props} />;
      case 'awards':
        return <Slices.Awards key={key} {...props} />;
      case 'carousel_three_cols':
        return <Slices.CarouselThreeCols key={key} {...props} />;
      case 'hero_tour':
        return <Slices.HeroTour key={key} {...props} />;
      case 'tour_list':
      case 'tour_list_':
        return <Slices.TourList key={key} {...props} />;
      case 'hero_tour_carousel':
        return <Slices.HeroTourCarousel key={key} {...props} />;
      default:
        return null;
    }
  });

  return slices;
};

export default SliceZone;
