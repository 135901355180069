import propsToDom from 'core/helpers/propsToDom';
import propsToLink from 'core/helpers/propsToLink';
import React from 'react';
import { useGlobalContext } from 'store/GlobalProvider';
import { usePageContext } from 'store/PageProvider';
import styled, { css } from 'styled-components';
import { linkTransition } from 'styles/transitions';
import { h3 } from 'styles/typography';
import Icon from './Icon';
import Link from './Link';

function romanize(num) {
  if (isNaN(num)) return NaN;
  var digits = String(+num).split(''),
    /* eslint-disable-next-line */
    key = ['','C','CC','CCC','CD','D','DC','DCC','DCCC','CM','','X','XX','XXX','XL','L','LX','LXX','LXXX','XC','','I','II','III','IV','V','VI','VII','VIII','IX'],
    roman = '',
    i = 3;
  while (i--) roman = (key[+digits.pop() + i * 10] || '') + roman;
  return Array(+digits.join('') + 1).join('M') + roman;
}

const NavRomanComponent = ({ menu, ...others }) => {
  const { siteData } = usePageContext();
  const { setModal } = useGlobalContext();
  const filtered = menu
    .filter(({ link }) => !!link?.url)
    .reduce((acc, curr) => {
      if (curr.submenu_id) {
        return acc;
      }

      acc.push(curr);
      return acc;
    }, []);

  return (
    <nav {...propsToDom(others)}>
      {filtered.map(({ link, text, link_text, type }, index) => (
        <RomanLink key={link.url + index} {...propsToLink(link)} activeClassName="is-active">
          <span>
            <span>{link_text}</span>
            <Icon id="line-horizontal" />
          </span>
          <span>.{romanize(index + 1)}</span>
        </RomanLink>
      ))}
      <RomanLink onClick={() => setModal({ id: 'buy' })}>
        <span>
          <span>{siteData.header_buy_button_text}</span>
          <Icon id="line-horizontal" />
        </span>
        <span>.{romanize(filtered.length + 1)}</span>
      </RomanLink>
    </nav>
  );
};

export const NavRoman = styled(NavRomanComponent)``;

const RomanLink = styled(Link)`
  ${h3}

  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  ${linkTransition}
  transition-property: opacity;
  width: 100%;

  & + & {
    margin-top: ${({ theme }) => theme.spacing.s2};
  }

  span {
    position: relative;
  }

  ${Icon} {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 4px;
    transform: scaleX(0);
    transform-origin: 100% 50%;
    opacity: 0;

    ${({ theme }) => css`
      transition: transform 0.5s ${theme.easings.inOutExpo}, opacity 0.4s ${theme.easings.outSine};
    `}
  }

  &.is-active ${Icon} {
    transform-origin: 0 50%;
    transform: scaleX(1);
    opacity: 1;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      opacity: 0.5;
    }
  }
`;
