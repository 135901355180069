import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from 'components/SEO';
import { Container } from 'components/Container';
import propsToLink from 'core/helpers/propsToLink';
import { Button } from 'components/Button';
import { fontTitle, Title4 } from 'styles/typography';
import { rem } from 'styles/utils';
import { useMount } from 'react-use';
import { useGlobalContext } from 'store/GlobalProvider';
import Icon from 'components/Icon';
import { Background404 } from 'components/Background404';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';
import { bgPaper } from 'styles/others';

const Page404 = ({ data }) => {
  const { setHeaderTheme } = useGlobalContext();

  useMount(() => {
    setHeaderTheme('dark');
  });

  if (!data?.prismicPage404?.data) return <h1>Page not found</h1>;

  const { title, baseline, back_link, back_link_text } = data.prismicPage404.data;

  return (
    <Module>
      <SEO {...data.prismicPage404.data} />
      <Background404 />
      <Container>
        <Content>
          <Title>
            <Icon id="dash" />
            <span>{title.text}</span>
            <Icon id="dash" />
          </Title>
          <Title4 as="p">{baseline}</Title4>
          <Button background="small" color="red" {...propsToLink(back_link)}>
            {back_link_text}
          </Button>
        </Content>
      </Container>
    </Module>
  );
};

export default withPrismicUnpublishedPreview(Page404);

const Module = styled.div`
  ${bgPaper}
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${Container} {
    position: relative;
    overflow: hidden;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

const Content = styled.div`
  ${Button} {
    margin-top: ${({ theme }) => theme.spacing.s6};
  }
`;

const Title = styled.h1`
  position: relative;
  ${fontTitle}
  font-size: ${rem(112)};
  line-height: ${136 / 112};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: ${({ theme }) => theme.spacing.s8};
  display: inline-block;

  ${Icon} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: ${rem(10)};

    &:first-child {
      right: calc(100% + ${rem(44)});
    }

    &:last-child {
      left: calc(100% + ${rem(44)});
    }
  }
`;

export const pageQuery = graphql`
  query Paeg404Query($uid: String!, $lang: String!) {
    prismicPage404(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      id
      uid
      data {
        seo_title
        seo_og_title
        seo_description
        seo_og_description
        schemaorg {
          text
        }
        title {
          text
        }
        baseline
        back_link {
          target
          url
        }
        back_link_text
      }
    }
  }
`;
