import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { rgba } from 'polished';
import { dry } from 'constants/springs';
import { LinkUnderline } from './LinkUnderline';
import { useGlobalContext } from 'store/GlobalProvider';
import { Container as RowContainer } from './Container';
import { usePageContext } from 'store/PageProvider';
import { rem } from 'styles/utils';
import media from 'styles/media';
import beziers from 'core/beziers';
import { useIsomorphicLayoutEffect, useMeasure } from 'react-use';

const OVERLAY = {
  visible: {
    opacity: 1,
    transition: dry,
  },
  hidden: {
    opacity: 0,
    transition: dry,
  },
};

const ModalComponent = ({
  id,
  ready = true,
  setScroll = true,
  isFullWidth = false,
  header = (close) => close,
  children,
  ...others
}) => {
  const $scroller = useRef(null);
  const { setModal } = useGlobalContext();
  const { siteData } = usePageContext();
  const [isScrolled, setScrolled] = useState(false);
  const [$header, { height: headerHeight }] = useMeasure();

  useIsomorphicLayoutEffect(() => {
    if ($scroller.current) {
      const handleScroll = () => setScrolled($scroller.current.scrollTop > 0);

      $scroller.current.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
        $scroller.current.removeEventListener('scroll', handleScroll);
      };
    }
  }, [ready]);

  const close = () => setModal(null);

  return (
    <div
      {...others}
      data-id={id}
      data-full={isFullWidth}
      data-scrolled={isScrolled}
      style={{ '--modal-header-height': `${headerHeight}px` }}
    >
      <Overlay
        variants={OVERLAY}
        initial="hidden"
        animate="visible"
        exit="hidden"
        onClick={close}
      />
      {ready && (
        <Container
          variants={{
            visible: {
              y: isFullWidth ? 0 : null,
              opacity: isFullWidth ? 1 : 1,
              transition: { type: 'ease', duration: 1.25, ease: beziers.expoOut },
            },
            hidden: {
              y: isFullWidth ? '100%' : null,
              opacity: isFullWidth ? 1 : 0,
            },
            exit: {
              y: isFullWidth ? '100%' : null,
              opacity: isFullWidth ? 1 : 0,
              transition: dry,
            },
          }}
          initial="hidden"
          animate="visible"
          exit="exit"
          transformTemplate={!isFullWidth && (({ y }) => `translate(-50%, -50%) translateY(${y})`)}
        >
          <Header ref={$header}>
            <RowContainer>
              {header(<Close onClick={close}>{siteData.i18n_close}</Close>)}
            </RowContainer>
          </Header>
          <Scroller className="scrollable" ref={$scroller}>
            {children}
          </Scroller>
        </Container>
      )}
    </div>
  );
};

export const Modal = styled(ModalComponent)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.modal};
`;

const Overlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => rgba(theme.colors.black, 0.3)};
  cursor: pointer;
`;

export const Close = styled(LinkUnderline)`
  margin-left: auto;
`;

export const Container = styled(motion.div)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: ${({ theme }) => `calc(100% - var(--header-height, ${theme.spacing.s9})) `};
  background: ${({ theme }) => theme.colors.dust};
  color: ${({ theme }) => theme.colors.black};
  border-radius: ${({ theme }) => `${theme.spacing.s4} ${theme.spacing.s4} 0 0 `};
  display: flex;
  flex-direction: column;
  align-items: stretch;

  [data-full='false'] & {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    width: ${rem(698)};
    min-width: 698px;
    border-radius: ${({ theme }) => theme.spacing.s4};
    clip-path: inset(0 0 0 0 round ${({ theme }) => theme.spacing.s4});

    ${media.mobile`
      width: calc(100% - ${rem(32)});
      min-width: 0;
    `}
  }
`;

export const Header = styled.div`
  flex-grow: 0;
  flex-shrink: 0;

  ${RowContainer} {
    position: relative;
    display: flex;
    justify-content: center;
    padding: ${({ theme }) => theme.spacing.s3} 0;
  }

  [data-scrolled='true'] & {
    box-shadow: inset 0 -1px 0 0 ${({ theme }) => rgba(theme.colors.black, 0.5)};
  }
`;

export const Scroller = styled.div`
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: ${({ theme }) => theme.colors.dust};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => `var(--accent-color, ${theme.colors.green}) `};
    border-radius: 2.5px;

    [data-id='buy'] & {
      background-color: ${({ theme }) => theme.colors.red};
    }
  }
`;
