import propsToDom from 'core/helpers/propsToDom';
import React from 'react';
import { useMeasure } from 'react-use';
import styled from 'styled-components';

const WavesComponent = ({ ...others }) => {
  const [$ref, { width, height }] = useMeasure();
  const radius = 45;
  const xInterval = 35;
  const yInterval = 5;

  const xRepeat = Math.ceil(width / (xInterval * 2));
  const yRepeat = Math.ceil(height / yInterval) + 1;

  const getPathForY = (j) => {
    return new Array(xRepeat).fill(0).map((_, i) => {
      /* eslint-disable-next-line */
      return `M ${i * 70}, ${j * yInterval} a ${radius}, ${radius}, 0, 0, 1, ${xInterval}, 0, ${radius}, ${radius}, 0, 0, 0, ${xInterval}, 0`;
    });
  };

  return (
    <div ref={$ref} {...propsToDom(others)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
      >
        {new Array(yRepeat).fill(0).map((_, i) => (
          <path key={`line-${i}`} d={getPathForY(i)} fill="none" stroke="#fff" />
        ))}
      </svg>
    </div>
  );
};

export const Waves = styled(WavesComponent)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }
`;
