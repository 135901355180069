import React, { Fragment } from 'react';
import styled, { useTheme } from 'styled-components';
import { graphql } from 'gatsby';
import propsToDom from 'core/helpers/propsToDom';
import { Panel } from 'components/Panel';
import { isFilled } from '@prismicio/client';
import { Title1, fontMaar, fontTitle, h3 } from 'styles/typography';
import { asText } from '@prismicio/client/richtext';
import Image from 'components/Image';
import { rem } from 'styles/utils';
import { Container } from 'components/Container';
import RichText from 'components/RichText';
import media from 'styles/media';
import classNames from 'classnames';

const HeroTourComponent = ({ slice_type, primary, ...others }) => {
  const { colors } = useTheme();
  const { main_title, subtitle, description, image, color, no_image_decoration } = primary;
  const hasTitle = isFilled.richText(main_title?.richText);
  const hasSubtitle = isFilled.richText(subtitle?.richText);
  const hasDescription = isFilled.richText(description?.richText);
  const hasImage = isFilled.image(image);
  const hasDecorations = no_image_decoration !== true;

  return (
    <Panel {...propsToDom(others)} id={slice_type} style={{ '--accent': colors[color] }}>
      <Container>
        <Content>
          {hasTitle && <Title>{asText(main_title.richText)}</Title>}
          {hasSubtitle && <Subtitle render={subtitle.richText} />}
          {hasDescription && <Description render={description.richText} />}
          {!hasTitle && (
            <Bottle
              src="/images/illustrations/bottle-star.png"
              role="presentation"
              loading="lazy"
            />
          )}
        </Content>
        {hasImage && (
          <Media className={classNames({ shift: !hasDecorations })}>
            <Image {...image} />
            {hasDecorations && (
              <Fragment>
                <Tree src="/images/illustrations/tree-red.png" role="presentation" loading="lazy" />
                <Leaves
                  src="/images/illustrations/leaves-solo-red.png"
                  role="presentation"
                  loading="lazy"
                />
              </Fragment>
            )}
          </Media>
        )}
      </Container>
    </Panel>
  );
};

const percent = (value) => `${(value / 750) * 100}%`;

const Tree = styled.img`
  position: absolute;
  width: ${percent(150)};
  top: ${percent(-58)};
  right: ${percent(-45)};
`;

const Leaves = styled.img`
  position: absolute;
  width: ${percent(300)};
  bottom: ${percent(-50)};
  left: ${percent(-147)};
`;

const Bottle = styled.img`
  width: ${percent(172)};
`;

const Content = styled.div`
  position: relative;

  ${media.fromMobile`
    flex: 1 1 47%;
  `}
`;

const Title = styled(Title1)`
  ${fontMaar};
  font-size: ${rem(84)};
  font-weight: bold;
  text-transform: uppercase;
  color: var(--accent);

  ${media.mobile`
    font-size: ${rem(62)};
  `}
`;

const Subtitle = styled(RichText)`
  ${h3};
  line-height: ${48 / 40};
  text-transform: uppercase;
  color: var(--accent);
  margin-bottom: ${rem(12)};

  ${media.mobile`
    font-size: ${rem(24)};
  `}
`;

const Description = styled(RichText)`
  ${fontTitle};
  font-size: ${rem(24)};
  line-height: ${29 / 24};
  text-transform: uppercase;
`;

const Media = styled.div`
  position: relative;
  padding-bottom: ${({ theme }) => theme.spacing.s4};

  &.shift {
    padding-bottom: 0;

    ${media.fromMobile`
      ${Image} {
        margin-top: calc(${({ theme }) => theme.spacing.s8} * -1);
        margin-bottom: calc(${({ theme }) => theme.spacing.s8} * -1);
      }
    `}
  }

  ${media.fromMobile`
    flex: 1 1 53%;
  `}
`;

export const HeroTour = styled(HeroTourComponent)`
  position: relative;
  overflow-x: hidden;

  ${Container} {
    display: flex;
    align-items: flex-start;
    gap: ${rem(80)};
  }

  ${media.mobile`
    ${Container} {
      flex-direction: column;
      align-items: stretch;
      gap: ${rem(20)};
    }
  `}
`;

export const query = graphql`
  fragment SliceHeroTourRich on PrismicPageRichDataBodyHeroTour {
    id
    slice_type
    slice_label
    primary {
      no_image_decoration
      color
      main_title {
        richText
      }
      subtitle {
        richText
      }
      description {
        richText
      }
      image {
        alt
        url
        dimensions {
          width
          height
        }
      }
    }
  }
`;
