import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from 'components/SEO';
import { Article as ArticleBody } from 'components/Article';
import { rem } from 'styles/utils';
import media from 'styles/media';
import { useMount } from 'react-use';
import { useGlobalContext } from 'store/GlobalProvider';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import linkResolver from 'core/linkResolver';

const Article = ({ data }) => {
  const { setHeaderTheme } = useGlobalContext();

  useMount(() => setHeaderTheme('dark'));

  if (!data?.prismicArticle?.data) return <h1>No data on article template</h1>;

  return (
    <Module>
      <SEO {...data.prismicArticle.data} />
      <ArticleBody isGatsbySource={true} {...data.prismicArticle.data} />
    </Module>
  );
};

const Module = styled.div`
  ${ArticleBody} {
    padding-top: ${rem(156)};

    ${media.tabletPortrait`
      padding-top: ${rem(236)};
    `}

    ${media.mobile`
      padding-top: ${rem(156)};
    `}
  }
`;

export default withPrismicPreview(Article, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);

export const pageQuery = graphql`
  query ArticleQuery($uid: String!, $lang: String!) {
    prismicArticle(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      id
      uid
      data {
        seo_title
        seo_og_title
        seo_description
        seo_og_description
        schemaorg {
          text
        }
        title {
          text
        }
        category {
          uid
          document {
            ... on PrismicBlogCategory {
              data {
                title {
                  text
                }
              }
            }
          }
        }
        is_recipe
        recipe_ingredients {
          ingredient
          quantity
        }
        recipe_ingredients_title
        recipe_accessories {
          name
          image {
            alt
            url
            dimensions {
              width
              height
            }
          }
        }
        recipe_accessories_title
        recipe_steps {
          step_image {
            alt
            url
            dimensions {
              width
              height
            }
          }
          step_video {
            url
          }
          step_text {
            richText
          }
        }
        body {
          ...SliceSlider
          ...SliceTitleText
          ...SliceRelateds
          ...SlicePushMap
        }
      }
    }
  }
`;
