import getLatLngDistance from 'core/helpers/getLatLngDistance';
import propsToDom from 'core/helpers/propsToDom';
import { rgba } from 'polished';
import React from 'react';
import { useMapContext } from 'store/MapProvider';
import styled from 'styled-components';
import media from 'styles/media';
import { buttonReset } from 'styles/others';
import { Caps } from 'styles/typography';
import { rem } from 'styles/utils';
import Icon from './Icon';

const StoreLocatorNavItemComponent = ({ retailer, ...others }) => {
  const { activeCategory, activeRetailer, setActiveRetailer, location } = useMapContext();
  const { uid, url, name, address, lat, lng } = retailer;
  const hasUserLocation = location.lat && location.lng;
  const hasUrl = !!url;
  const AddressTag = hasUrl ? 'a' : 'address';

  // console.log(retailer);

  return (
    <div
      {...propsToDom(others)}
      data-uid={uid}
      data-active={activeRetailer?.uid === retailer.uid}
      onClick={() => setActiveRetailer(retailer)}
    >
      <Title>
        <span>{name}</span>{' '}
        {activeCategory === null && <CategoryTag>{retailer.catLabel}</CategoryTag>}
      </Title>
      <AddressTag target={hasUrl ? '_blank' : null} href={hasUrl ? url : null}>
        <span>
          {address}
          {hasUrl && <Icon id="arrow-right" />}
        </span>
      </AddressTag>
      {hasUserLocation && <p>{getLatLngDistance({ lat, lng }, location)} KM</p>}
      <ProductsLabels>
        {retailer.productsLabels?.map?.((label) => (
          <span key={label}>{label}</span>
        ))}
      </ProductsLabels>
    </div>
  );
};

const ProductsLabels = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${rem(20)};
  text-transform: uppercase;
  font-size: ${rem(11)};
  letter-spacing: 2px;

  span + span {
    color: ${({ theme }) => theme.colors.green};
  }
`;

const Title = styled(Caps)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const CategoryTag = styled.span`
  text-transform: uppercase;
  border: 1px solid currentColor;
  padding: 0 ${rem(10)};
  border-radius: 99em;
  display: flex;
  align-items: center;
  height: ${rem(20)};
  font-size: ${rem(12)};
  line-height: ${14 / 12};
  white-space: nowrap;
  margin-left: ${rem(5)};

  ${media.mobile`
    padding-top: .2em;
    margin-top: -.1em;
  `}
`;

export default styled(StoreLocatorNavItemComponent)`
  ${buttonReset}

  position: relative;
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing.s3} ${theme.spacing.s6}`};
  border-top: 1px solid ${({ theme }) => theme.colors.red};
  -webkit-tap-highlight-color: transparent;

  a {
    color: currentColor;
    text-decoration: none;

    span {
      position: relative;
      text-decoration: underline;
    }

    ${Icon} {
      position: absolute;
      margin-left: ${rem(5)};
      bottom: 0.45em;
      vertical-align: middle;
      display: inline;
      font-size: ${rem(8)};
      white-space: nowrap;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: ${({ theme }) => rgba(theme.colors.red, 0.5)};
      color: ${({ theme }) => theme.colors.dust};
    }

    a:hover {
      span {
        text-decoration: none;
      }
    }
  }

  &[data-active='true'] {
    background: ${({ theme }) => rgba(theme.colors.red, 0.5)};
    color: ${({ theme }) => theme.colors.dust};
  }

  ${media.mobile`
    padding-left: ${rem(16)};
    padding-right: ${rem(16)};
  `}
`;
