import React from 'react';
import styled, { useTheme } from 'styled-components';
import { graphql } from 'gatsby';
import { Panel } from 'components/Panel';
import propsToDom from 'core/helpers/propsToDom';
import { Title1, h3 } from 'styles/typography';
import Image from 'components/Image';
import RichText from 'components/RichText';
import { Button } from 'components/Button';
import propsToLink from 'core/helpers/propsToLink';
import { ContainerGrid } from 'components/Container';
import { rem } from 'styles/utils';
import classNames from 'classnames';
import media from 'styles/media';
import useParallax from 'hooks/useParallax';
import clamp from 'core/helpers/clamp';

const ProductsPushComponent = ({ slice_type, primary, items, ...others }) => {
  const { colors } = useTheme();
  const { main_title } = primary;
  const hasTitle = !!main_title.text;

  const [$parallaxBottle1, yBottle1] = useParallax((y, b) => {
    const max = b.h / 8;
    return clamp(y * -max, -max, max);
  });

  const [$parallaxBottle2, yBottle2] = useParallax((y, b) => {
    const max = b.h / 8;
    return clamp(y * -max, -max, max);
  });

  const [$parallaxImage1, yImage1] = useParallax((y, b) => {
    const max = b.h / 8;
    return clamp(y * max, -max, max);
  });

  const [$parallaxImage2, yImage2] = useParallax((y, b) => {
    const max = b.h / 8;
    return clamp(y * max, -max, max);
  });

  return (
    <Panel {...propsToDom(others)} id={slice_type}>
      {hasTitle && (
        <Header>
          <Title1 as="h2">{main_title.text}</Title1>
        </Header>
      )}

      <Rows>
        {items.map(
          (
            { image, bottle_image, color, title1, subtitle, text, button_link, button_label },
            index
          ) => {
            const reversed = index % 2 === 1;
            const hasImage = !!image.url;
            const hasBottleImage = !!bottle_image.url;

            const $parallaxBottle = index === 0 ? $parallaxBottle1 : $parallaxBottle2;
            const yBottle = index === 0 ? yBottle1 : yBottle2;
            const $parallaxImage = index === 0 ? $parallaxImage1 : $parallaxImage2;
            const yImage = index === 0 ? yImage1 : yImage2;

            return (
              <Row
                key={index}
                className={classNames({ reversed })}
                style={{ '--accent': colors[color] }}
              >
                <ImageContainer>
                  {hasImage && <Image ref={$parallaxImage} style={{ y: yImage }} {...image} />}
                  {hasBottleImage && (
                    <Image ref={$parallaxBottle} style={{ y: yBottle }} {...bottle_image} />
                  )}
                  {color === 'red' && (
                    <TreeMoon
                      src="/images/illustrations/tree-moon-red.png"
                      width="532"
                      height="494"
                      role="presentation"
                      loading="lazy"
                    />
                  )}
                  {color === 'green' && (
                    <Croc
                      src="/images/illustrations/croc-green.png"
                      width="108"
                      height="88"
                      loading="lazy"
                      role="presentation"
                    />
                  )}
                </ImageContainer>
                <RowContent>
                  <Title1 as="h3">
                    {title1}
                    <small>{subtitle}</small>
                  </Title1>
                  <RichText render={text.richText} />
                  <Button {...propsToLink(button_link)} background="small" color={color}>
                    {button_label}
                  </Button>
                  {color === 'red' && (
                    <Bird
                      src="/images/illustrations/bird-red.png"
                      width="96"
                      height="108"
                      role="presentation"
                      loading="lazy"
                    />
                  )}
                </RowContent>
              </Row>
            );
          }
        )}
      </Rows>
    </Panel>
  );
};

const Header = styled(ContainerGrid)`
  position: relative;
  margin-bottom: ${rem(60)};
  z-index: 2;

  ${Title1} {
    grid-column: 1 / span 10;
  }

  ${media.mobile`
    margin-bottom: ${rem(32)};
  `}
`;

const Rows = styled.div``;

const Row = styled(ContainerGrid)`
  & + & {
    margin-top: ${rem(200)};
  }

  ${media.mobile`
    & + & {
      margin-top: ${rem(65)};
    }
  `}
`;

const ImageContainer = styled.div`
  position: relative;
  grid-column: 1 / span 5;
  grid-row: 1 / span 1;

  .reversed & {
    grid-column: 8 / -1;
  }

  ${Image} + ${Image} {
    position: absolute;
    bottom: 0;
    left: ${(430 / 490) * 100}%;
    bottom: ${rem(-45)};
    width: ${(167 / 490) * 100}%;

    .reversed & {
      left: auto;
      right: ${(430 / 490) * 100}%;
    }
  }

  ${media.mobile`
    width: 70%;
    grid-row-start: auto;
    margin-bottom: ${rem(40)};

    .reversed & {
      margin-left: auto;
    }
  `}
`;

const TreeMoon = styled.img`
  position: absolute;
  left: ${(360 / 490) * 100}%;
  top: ${(30 / 734) * 100}%;
  width: ${(266 / 490) * 100}%;
`;

const Bird = styled.img`
  position: absolute;
  width: ${rem(96)};
  bottom: ${rem(104)};
  right: ${rem(140)};
  z-index: -1;

  ${media.mobile`
    right: ${rem(40)};
    bottom: ${rem(40)};
  `}
`;

const Croc = styled.img`
  position: absolute;
  left: 0;
  top: ${(90 / 734) * 100}%;
  transform: translateX(-50%);
  width: ${(166 / 490) * 100}%;
`;

const RowContent = styled.div`
  position: relative;
  grid-column: 7 / -1;
  grid-row: 1 / span 1;
  align-self: center;

  .reversed & {
    grid-column: 1 / span 6;
  }

  ${Title1} {
    color: var(--accent);

    small {
      display: block;
      ${h3};
    }
  }

  ${RichText} {
    ${h3};
  }

  ${Button} {
    margin-top: ${rem(16)};
  }

  ${media.mobile`
    grid-row-start: auto;

    .reversed & {
      text-align: right;
    }

    ${Title1} {
      small {
        font-size: ${rem(24)};
      }
    }

    ${RichText} {
      font-size: ${rem(24)};
      line-height: ${29 / 24};
    }
  `}
`;

export const ProductsPush = styled(ProductsPushComponent)`
  position: relative;
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing.s8} 0;

  ${Title1} {
    text-transform: uppercase;
  }
`;

export const query = graphql`
  fragment SliceProductsPush on PrismicPageHomeDataBodyProductspush {
    id
    slice_type
    slice_label
    primary {
      main_title {
        text
      }
    }
    items {
      image {
        url
        dimensions {
          width
          height
        }
      }
      bottle_image {
        url
        dimensions {
          width
          height
        }
      }
      color
      title1
      subtitle
      text {
        richText
      }
      button_link {
        url
      }
      button_label
    }
  }
`;
