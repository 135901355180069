import { Panel } from 'components/Panel';
import propsToDom from 'core/helpers/propsToDom';
import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { ContainerGrid } from 'components/Container';
import { Title1 } from 'styles/typography';
import Image from 'components/Image';
import RichText from 'components/RichText';
import { Carousel, CarouselWrapper } from 'components/Carousel';
import media from 'styles/media';
import useParallax from 'hooks/useParallax';
import clamp from 'core/helpers/clamp';
import { motion } from 'framer-motion';

const SliderIslaCocodriloComponent = ({ slice_type, primary, items, ...others }) => {
  const { main_title, first_text, first_image, second_text, second_image } = primary;
  const hasTitle = !!main_title?.text;
  const hasFirstImage = !!first_image?.url;
  const hasFirstText = first_text?.richText?.length > 0;
  const hasSecondImage = !!second_image?.url;
  const hasSecondText = second_text?.richText?.length > 0;

  const images = items
    .filter((item) => !!item?.slider_image?.url)
    .map(({ slider_image }) => slider_image);

  const [$pTitle, yTitle] = useParallax((y, b) => {
    const max = b.h / 2;
    return clamp(y * max, -max, max);
  });

  const [$p2, y2] = useParallax((y, b) => {
    const max = b.h / 10;
    return clamp(y * -max, -max, max);
  });

  return (
    <Panel {...propsToDom(others)} id={slice_type}>
      {hasTitle && (
        <Title1 ref={$pTitle} style={{ y: yTitle }} as={motion.h2}>
          {main_title.text}
        </Title1>
      )}
      <ContainerGrid>
        <Carousel>
          {images.map((image, index) => (
            <Image key={image.url + index} {...image} quality={95} />
          ))}
        </Carousel>
      </ContainerGrid>
      <Isla>
        {hasFirstImage && <Image {...first_image} />}
        {hasFirstText && <RichText render={first_text.richText} />}
      </Isla>
      <Inspiration>
        {hasSecondText && <RichText render={second_text.richText} />}
        {hasSecondImage && (
          <InspirationImageContainer>
            <Image ref={$p2} style={{ y: y2 }} {...second_image} />
            <Jungle
              src="/images/illustrations/jungle.png"
              role="presentation"
              loading="lazy"
              width="700"
              height="880"
            />
          </InspirationImageContainer>
        )}
      </Inspiration>
    </Panel>
  );
};

export const SliderIslaCocodrilo = styled(SliderIslaCocodriloComponent)`
  position: relative;
  overflow: hidden;

  ${Title1} {
    position: relative;
    text-transform: uppercase;
    margin-bottom: -0.5em;
    line-height: 1;
    z-index: 2;
    color: ${({ theme }) => theme.colors.green};
    text-align: center;
  }

  ${Carousel} {
    grid-column: 2 / span 10;
    margin-bottom: ${({ theme }) => theme.spacing.s8};

    ${CarouselWrapper} > *:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.gridColumnGap};
    }
  }
`;

const Isla = styled(ContainerGrid)`
  margin-bottom: ${({ theme }) => theme.spacing.s8};
  grid-row-gap: ${({ theme }) => theme.spacing.s8};

  ${Image} {
    grid-column-end: span 6;
    align-self: center;
  }

  ${RichText} {
    grid-column-end: span 6;
  }
`;

const Inspiration = styled(ContainerGrid)`
  grid-row-gap: ${({ theme }) => theme.spacing.s3};

  ${RichText} {
    padding-top: ${({ theme }) => theme.spacing.s8};
    grid-column-end: span 6;
  }

  ${media.mobile`
    ${RichText} {
      padding-top: 0;
    }
  `}
`;

const InspirationImageContainer = styled.div`
  position: relative;
  grid-column-end: span 6;

  ${Image} {
    width: ${(382 / 598) * 100}%;
  }

  ${media.mobile`
    grid-row-start: 1;
  `}
`;

const Jungle = styled.img`
  position: relative;
  display: block;
  width: ${(350 / 598) * 100}%;
  margin-top: ${(-251 / 598) * 100}%;
  margin-left: auto;
  margin-right: 0;
  z-index: 2;
`;

export const query = graphql`
  fragment SliceSliderIslaCocodrilo on PrismicPageRichDataBodySliderIslaCocodrilo {
    id
    slice_type
    slice_label
    primary {
      main_title {
        text
      }
      first_text {
        richText
      }
      first_image {
        alt
        url
        dimensions {
          width
          height
        }
      }
      second_text {
        richText
      }
      second_image {
        alt
        url
        dimensions {
          width
          height
        }
      }
    }
    items {
      slider_image {
        alt
        url
        dimensions {
          width
          height
        }
      }
    }
  }
`;
