import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import propsToDom from 'core/helpers/propsToDom';
import { Panel } from 'components/Panel';
import { ContainerGrid } from 'components/Container';
import { Carousel } from 'components/Carousel';
import { Title1 } from 'styles/typography';
import { asText } from '@prismicio/richtext';
import { ArticleThumbnail } from 'components/ArticleThumbnail';
import media from 'styles/media';
import { hiddenScrollbarCSS } from 'styles/others';
import { rem } from 'styles/utils';
import { motion } from 'framer-motion';
import useParallax from 'hooks/useParallax';
import clamp from 'core/helpers/clamp';

const RelatedsComponent = ({ slice_type, primary, items, ...others }) => {
  const { main_title } = primary;
  const title = main_title?.richText || main_title;
  const hasTitle = !!title?.length > 0;

  const [$pTitle, yTitle] = useParallax((y, b) => {
    const max = b.h;
    return clamp(y * max, -max, max);
  });

  const articles = items
    .filter(({ article }) => !!article?.uid)
    .map(({ article }) => {
      // document exists if it's from Gatsby, but not if it's fetch from the client API
      return article?.document ? { ...article.document, uid: article.uid } : article;
    });

  return (
    <Panel {...propsToDom(others)} id={slice_type}>
      {hasTitle && (
        <Title1 ref={$pTitle} style={{ y: yTitle }} as={motion.h2}>
          {asText(title)}
        </Title1>
      )}
      <Scroller>
        <ContainerGrid>
          {articles.map(({ uid, id, data }, index) => {
            return <ArticleThumbnail key={uid + index} {...data} uid={uid} id={id} />;
          })}
        </ContainerGrid>
      </Scroller>
    </Panel>
  );
};

export const Relateds = styled(RelatedsComponent)`
  position: relative;

  ${ContainerGrid} {
    grid-row-gap: ${({ theme }) => theme.spacing.s8};
  }

  ${Title1} {
    position: absolute;
    top: 0.5em;
    left: 0;
    right: 0;
    text-transform: uppercase;
    line-height: 1;
    z-index: 2;
    padding-left: ${({ theme }) => theme.spacing.s1_5};
    color: ${({ theme }) => `var(--accent-color, ${theme.colors.red}) `};
  }

  ${Carousel} {
    grid-column: 2 / span 10;
  }

  ${ArticleThumbnail} {
    grid-column-end: span 4;
  }

  ${media.mobile`
    ${ContainerGrid} {
      display: flex;
      gap: ${rem(16)};
      padding-left: ${rem(44)};
      padding-right: ${rem(44)};
      margin-left: 0;
      margin-right: 0;
      width: max-content;
    }

    ${ArticleThumbnail} {
      flex-shrink: 0;
      width: calc(100vw - ${rem(88)});
    }
  `}
`;

const Scroller = styled.div`
  ${media.mobile`
    ${hiddenScrollbarCSS}
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  `}
`;

export const query = graphql`
  fragment SliceRelateds on PrismicArticleDataBodyRelateds {
    id
    slice_type
    slice_label
    primary {
      main_title {
        richText
      }
    }
    items {
      article {
        id
        uid
        url
        document {
          ... on PrismicArticle {
            data {
              title {
                richText
              }
              category {
                id
                uid
                document {
                  ... on PrismicBlogCategory {
                    data {
                      title {
                        richText
                      }
                    }
                  }
                }
              }
              thumbnail {
                alt
                url
                dimensions {
                  width
                  height
                }
              }
              extract {
                richText
              }
            }
          }
        }
      }
    }
  }
`;
