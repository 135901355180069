import React from 'react';
import styled, { useTheme } from 'styled-components';
import { graphql } from 'gatsby';
import { Title1, Title4 } from 'styles/typography';
import RichText from 'components/RichText';
import propsToDom from 'core/helpers/propsToDom';
import { rem } from 'styles/utils';
import { Panel } from 'components/Panel';
import { ContainerGrid } from 'components/Container';
import Image from 'components/Image';
import propsToLink from 'core/helpers/propsToLink';
import { Button } from 'components/Button';
import media from 'styles/media';
import useParallax from 'hooks/useParallax';
import clamp from 'core/helpers/clamp';
import { motion } from 'framer-motion';

const HowToDrinkComponent = ({ slice_type, primary, items, ...others }) => {
  const { colors } = useTheme();
  const { main_title, link, link_text, color = 'red' } = primary;
  const hasTitle = !!main_title?.text;
  const hasLink = !!link?.url && !!link_text;

  const [$p1, y1] = useParallax((y, b) => {
    const max = b.h / 10;
    return clamp(y * -max, -max, max);
  });

  const [$p2, y2] = useParallax((y, b) => {
    const max = b.h / 10;
    return clamp(y * max, -max, 0);
  });

  const [$pTree, yTree] = useParallax((y, b) => {
    const max = b.h / 10;
    return clamp(y * -max, -max, max);
  });

  const $refs = [$p1, $p2];
  const ys = [y1, y2];

  return (
    <Panel
      {...propsToDom(others)}
      id={slice_type}
      style={{ '--accent': colors[color] }}
      data-color={color}
    >
      <ContainerGrid>
        {hasTitle && <Title1 as="h2">{main_title.text}</Title1>}
        <Items>
          {items
            .filter(({ image }) => !!image?.url)
            .map(({ image, main_title, text }, index) => {
              const hasTitle = !!main_title;
              const hasText = !!text?.html;
              return (
                <Item key={image.url + index} $isReversed={index % 2 !== 0} $nbCols={2}>
                  <ImageContainer>
                    <Image ref={$refs[index]} style={{ y: ys[index] }} {...image} />
                    {index === 1 && (
                      <Tree
                        ref={$pTree}
                        style={{ y: yTree }}
                        src="/images/illustrations/tree-dust.svg"
                        role="presentation"
                        loading="lazy"
                      />
                    )}
                  </ImageContainer>
                  <ItemText>
                    {hasTitle && <Title4 as="h3">{main_title}</Title4>}
                    {hasText && <RichText dangerouslySetInnerHTML={{ __html: text.html }} />}
                  </ItemText>
                </Item>
              );
            })}
        </Items>
      </ContainerGrid>
      <Footer>
        {hasLink && (
          <Button background="medium" color="dust" {...propsToLink(link)}>
            {link_text}
          </Button>
        )}
      </Footer>
    </Panel>
  );
};

export const HowToDrink = styled(HowToDrinkComponent)`
  position: relative;
  overflow: hidden;
  background: url('/images/bg-red-noise.jpg') repeat ${({ theme }) => theme.colors.red};
  background-size: 128px;
  color: ${({ theme }) => theme.colors.dust};

  &[data-color='green'] {
    background: url('/images/bg-green-noise.jpg') repeat ${({ theme }) => theme.colors.green};
  }

  &[data-color='greenLight'] {
    background: url('/images/bg-green-light-noise.jpg') repeat
      ${({ theme }) => theme.colors.greenLight};
  }

  ${Title1} {
    position: relative;
    grid-column: 2 / -2;
    text-transform: uppercase;
    margin-bottom: -0.5em;
    z-index: 2;
  }
`;

const Tree = styled(motion.img)`
  position: absolute;
  top: ${(83 / 574) * 100}%;
  right: ${(-108 / 382) * 100}%;
  width: ${(216 / 382) * 100}%;

  ${media.mobile`
    top: ${(213 / 432) * 100}%;
    right: ${(-43 / 288) * 100}%;
    width: ${(88 / 288) * 100}%;
  `}
`;

const ItemText = styled.div`
  ${Title4} {
    margin-bottom: ${({ theme }) => theme.spacing.s3};
  }
`;

const Items = styled.div`
  grid-column: 2 / span 8;

  ${media.tabletPortrait`
    grid-column: 2 / span 10;
  `}

  ${media.mobile`
    padding-left: ${({ theme }) => theme.spacing.s3};
    padding-right: ${({ theme }) => theme.spacing.s3};
  `}
`;

const ImageContainer = styled.div`
  position: relative;
`;

const Item = styled(ContainerGrid)`
  position: relative;
  align-items: center;
  grid-row-gap: ${({ theme }) => theme.spacing.s3};

  ${ItemText} {
    grid-row: 1 / span 1;
    grid-column-start: ${({ $isReversed }) => ($isReversed ? 1 : 'auto')};
    text-align: ${({ $isReversed }) => ($isReversed ? 'right' : 'left')};
  }

  ${ImageContainer} {
    grid-row: 1 / span 1;
    grid-column-start: ${({ $isReversed }) => ($isReversed ? 2 : 'auto')};

    ${Image} {
      position: relative;
      border-radius: 99rem 99rem 0 0;
      clip-path: inset(0 0 0 0 round 99em 99em 0 0);
      overflow: hidden;
    }
  }

  ${media.mobile`
    &:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing.s8};
    }

    ${ItemText} {
      grid-row: auto / span 1;
    }

    ${ImageContainer} {
      grid-row: auto / span 1;
    }
  `}
`;

const Footer = styled.div`
  text-align: center;
  margin-top: ${rem(60)};
`;

export const query = graphql`
  fragment SliceHowToDrink on PrismicPageHomeDataBodyHowToDrink {
    id
    slice_type
    slice_label
    primary {
      color
      main_title {
        text
      }
      link {
        target
        url
      }
      link_text
    }
    items {
      image {
        alt
        url
        dimensions {
          width
          height
        }
      }
      main_title
      text {
        html
      }
    }
  }

  fragment SliceHowToDrinkRich on PrismicPageRichDataBodyHowToDrink {
    id
    slice_type
    slice_label
    primary {
      color
      main_title {
        text
      }
      link {
        target
        url
      }
      link_text
    }
    items {
      image {
        alt
        url
        dimensions {
          width
          height
        }
      }
      main_title
      text {
        html
      }
    }
  }
`;
