import React, { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { graphql } from 'gatsby';
import propsToDom from 'core/helpers/propsToDom';
import { Panel } from 'components/Panel';
import { ContainerGrid } from 'components/Container';
import { Carousel } from 'components/Carousel';
import { Title1, highlightCSS } from 'styles/typography';
import RichText from 'components/RichText';
import { useGlobalContext } from 'store/GlobalProvider';
import { rem } from 'styles/utils';
import useParallax from 'hooks/useParallax';
import { motion } from 'framer-motion';
import clamp from 'core/helpers/clamp';

const SliderTextComponent = ({ slice_type, primary, items, ...others }) => {
  const { colors } = useTheme();
  const { ww, wh } = useGlobalContext();
  const { main_title, color } = primary;
  const hasTitle = !!main_title.text;
  const images = items.filter((item) => !!item?.image?.url).map(({ image }) => image);

  const $texts = useRef(null);
  const [active, setActive] = useState(0);
  const [textsHeight, setTextsHeight] = useState(0);

  useEffect(() => {
    const $childs = $texts.current.children;
    const heights = [...$childs].map(($child) => $child.offsetHeight);
    setTextsHeight(Math.max(...heights));
  }, [ww, wh]);

  const [$pTitle, yTitle] = useParallax((y, b) => {
    const max = b.h / 2;
    return clamp(y * max, -max, max);
  });

  return (
    <Panel {...propsToDom(others)} id={slice_type} style={{ '--accent': colors[color] }}>
      <ContainerGrid>
        {hasTitle && (
          <Title1 ref={$pTitle} as={motion.h2} style={{ y: yTitle }}>
            {main_title.text}
          </Title1>
        )}
        <Carousel images={images} onActiveChange={(active) => setActive(active)} />
        <Texts ref={$texts} style={{ height: textsHeight }}>
          {items.map(({ text }, index) => (
            <Text key={index} $active={active === index} render={text.richText} />
          ))}
        </Texts>
      </ContainerGrid>
    </Panel>
  );
};

const Texts = styled.div`
  position: relative;
  grid-column: 2 / -2;
  margin-top: ${rem(20)};
`;

const Text = styled(RichText)`
  ${highlightCSS};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: ${({ $active }) => ($active ? 1 : 0)};
  text-align: center;
  transition: opacity 0.3s;
`;

export const SliderText = styled(SliderTextComponent)`
  position: relative;
  overflow: hidden;

  ${Carousel} {
    grid-column: 2 / span 10;
  }

  ${Title1} {
    padding-top: ${rem(20)};
    grid-column: 2 / -2;
    text-align: center;
    margin-bottom: -0.5em;
    line-height: 1;
    text-align: center;
    z-index: 2;
    text-transform: uppercase;
    color: var(--accent);
  }
`;

export const query = graphql`
  fragment SliceSliderTextRich on PrismicPageRichDataBodySliderText {
    id
    slice_type
    slice_label
    primary {
      color
      main_title {
        text
      }
    }
    items {
      text {
        richText
      }
      image {
        alt
        url
        dimensions {
          width
          height
        }
      }
    }
  }
`;
