import React from 'react';
import styled from 'styled-components';
import { LinkUnderline } from './LinkUnderline';
import { rem } from 'styles/utils';
import RichText from './RichText';
import { isFilled } from '@prismicio/client';

const RecipeStepsListComponent = ({ className, steps, active, setActive, children }) => {
  return (
    <div className={className}>
      {steps.map(({ step_text, step_title }, index) => {
        const title = isFilled.keyText(step_title) ? step_title : `Step ${index + 1}`;
        return (
          <Step key={index} $isActive={active === index}>
            <LinkUnderline
              isActive={active === index}
              onClick={() => setActive(index)}
              onMouseEnter={() => setActive(index)}
            >
              {title}
            </LinkUnderline>
            <RichText render={step_text?.richText || step_text} />
          </Step>
        );
      })}
    </div>
  );
};

const Step = styled.div`
  ${LinkUnderline} {
    margin-bottom: ${rem(23)};

    @media (hover: hover) and (pointer: fine) {
      opacity: 0.5;

      &.is-active,
      &:hover {
        opacity: 1;
      }
    }
  }

  & + & {
    margin-top: ${rem(65)};
  }
`;

export const RecipeStepsList = styled(RecipeStepsListComponent)``;
