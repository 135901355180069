import propsToDom from 'core/helpers/propsToDom';
import { motion } from 'framer-motion';
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useGlobalContext } from 'store/GlobalProvider';
import styled from 'styled-components';

function getImageUrlAtWidth(targetWidth, image) {
  const { width, height } = image.dimensions;
  const scale = targetWidth / width;
  const w = Math.round(width * scale);
  const h = Math.round(height * scale);
  const url = new URL(image.url);
  url.searchParams.set('w', w);
  url.searchParams.set('h', h);
  return url.href;
}

function getSrcSet(image) {
  if (image.url.split('.').pop() === 'svg') {
    return image.url;
  }

  const url = new URL(image.url);
  const isWidthSet = url.searchParams.has('w');

  const breakpoints = [750, 768, 1024, 1280, 1440, 1600, 1920, 2560];

  return (
    breakpoints
      .concat([image.dimensions.width])
      .sort()
      // Set srcSet only for smaller images than original,
      // but keeping a 2x possibility
      .filter((bp) => bp <= Math.min(2560, image.dimensions.width * (isWidthSet ? 2 : 1)))
      // Generate url
      .map((bp) => `${getImageUrlAtWidth(bp, image)} ${bp}w`)
      // Generate srcSet
      .join(', ')
  );
}

function getPreviewUrl(image) {
  const url = new URL(getImageUrlAtWidth(100, image));
  url.searchParams.append('blur', '100');
  return url.href;
}

const BLANK_PX =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

const Image = forwardRef(
  (
    {
      dimensions,
      url: originalUrl,
      alt,
      loading = 'lazy',
      sizes: originalSizes = null,
      naturalSizing = false,
      blur = true,
      style = {},
      quality = false,
      ...others
    },
    $ref
  ) => {
    const objectUrl = new URL(originalUrl);
    if (quality) objectUrl.searchParams.set('q', quality);
    const url = objectUrl.href;
    const $preview = useRef(null);
    const $picture = useRef(null);
    const $element = $ref || $picture;
    const { isClient } = useGlobalContext();
    const [isLoaded, setLoaded] = useState(false);
    const [isPreviewVisible, setPreviewVisible] = useState(true);
    const [sizes, setSizes] = useState(originalSizes);
    const srcSet = getSrcSet({ url, dimensions });
    const previewSrc = getPreviewUrl({ url, dimensions });
    const paddingBottom = `${(dimensions.height / dimensions.width) * 100}%`;

    useEffect(() => {
      const observer = new ResizeObserver(([entry]) => {
        setSizes(`${Math.round(entry.contentRect.width)}px`);
      });

      if ($element.current && originalSizes === null) {
        observer.observe($element.current);
      } else {
        setSizes(originalSizes);
      }

      return () => observer.disconnect();
    }, [$element, setSizes, originalSizes]);

    const onPreviewTransitionEnd = useCallback(() => {
      setPreviewVisible(false);
    }, [setPreviewVisible]);

    const onLoad = useCallback(
      (event) => {
        if (event.target.currentSrc === BLANK_PX) {
          return;
        }

        if ($preview.current) {
          $preview.current.addEventListener('transitionend', onPreviewTransitionEnd);
        }

        setLoaded(true);
      },
      [onPreviewTransitionEnd]
    );

    return (
      <motion.picture ref={$element} style={style} {...propsToDom(others)} data-loaded={isLoaded}>
        {!naturalSizing && <span style={{ paddingBottom }} />}
        <Img
          key={isClient ? 'client' : 'server'}
          $isAbsolute={!naturalSizing}
          src={BLANK_PX}
          srcSet={sizes ? srcSet : null}
          data-srcset={!sizes ? srcSet : null}
          sizes={sizes}
          onLoad={onLoad}
          loading={loading}
          width={dimensions.width}
          height={dimensions.height}
          alt={alt}
        />
        {isPreviewVisible && (
          <Blurred
            className={isPreviewVisible}
            ref={$preview}
            src={previewSrc}
            alt={alt}
            loading="eager"
          />
        )}
      </motion.picture>
    );
  }
);

Image.displayName = 'Image';

const Img = styled.img`
  position: ${({ $isAbsolute }) => ($isAbsolute ? 'absolute' : null)};
  top: ${({ $isAbsolute }) => ($isAbsolute ? 0 : null)};
  left: ${({ $isAbsolute }) => ($isAbsolute ? 0 : null)};
  width: ${({ $isAbsolute }) => ($isAbsolute ? '100%' : null)};
  height: ${({ $isAbsolute }) => ($isAbsolute ? '100%' : null)};
  object-fit: cover;
  object-position: center center;
  z-index: 0;
`;

const Blurred = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.8s;

  [data-loaded='true'] & {
    opacity: 0;
  }
`;

export default styled(Image)`
  position: relative;
  display: block;

  & > span {
    display: block;
  }
`;
