import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { RichText as PrismicRichText, Elements } from 'prismic-reactjs';
import Link from './Link';
import linkResolver from 'core/linkResolver';
import { h4 } from 'styles/typography';
import Markdown from 'markdown-to-jsx';
import { motion } from 'framer-motion';
import { buttonReset } from 'styles/others';

const MarkdownLinkProxy = ({ href, ...others }) => <Link to={href} {...others} />;
const MarkdownTableProxy = (props) => (
  <TableContainer>
    <table {...props} />
  </TableContainer>
);

const reactSerializer = function (type, element, content, children, index) {
  const key = `element-${type}-${index + 1}`;

  switch (type) {
    // Don't wrap images in a <p> tag
    case Elements.image:
      return (
        <figure key={key}>
          <img src={element.url} alt={element.alt} />
          {!!element.alt && <figcaption>{element.alt}</figcaption>}
        </figure>
      );

    // Dynamic link with gatsby router
    case Elements.hyperlink:
      const url = linkResolver(element.data);

      return (
        <Link key={key} to={url} target={element.data.target}>
          {content}
        </Link>
      );

    case Elements.listItem:
    case Elements.oListItem:
      return (
        <li key={key}>
          <span>{children}</span>
        </li>
      );

    case Elements.preformatted:
      return (
        <Markdown
          key={key}
          options={{
            overrides: {
              a: {
                component: MarkdownLinkProxy,
              },
              table: {
                component: MarkdownTableProxy,
              },
            },
          }}
        >
          {element.text}
        </Markdown>
      );

    // Return null to stick with the default behavior for all other elements
    default:
      return null;
  }
};

const DynamicRichText = forwardRef(
  ({ dangerouslySetInnerHTML = false, animated = false, render, ...others }, ref) => {
    const Element = animated ? motion.div : 'div';
    if (dangerouslySetInnerHTML) {
      return <Element ref={ref} dangerouslySetInnerHTML={dangerouslySetInnerHTML} {...others} />;
    } else {
      return (
        <Element ref={ref} {...others}>
          <PrismicRichText render={render} htmlSerializer={reactSerializer} />
        </Element>
      );
    }
  }
);

DynamicRichText.displayName = 'DynamicRichText';

const TableContainer = styled.div`
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  pointer-events: auto;
  background: ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.dust};
  border: 2px solid ${({ theme }) => theme.colors.black};
  margin-top: 1em;
  margin-bottom: 1em;

  table {
    margin: 0;
  }
`;

const RichText = styled(DynamicRichText)`
  pointer-events: none;

  &:hover .word {
    opacity: 0.5;
    animation-play-state: paused;
  }

  .word {
    transition: opacity 0.5s;
  }

  a {
    pointer-events: auto;

    &:hover .word {
      opacity: 1;
      animation-play-state: paused;
    }
  }

  button {
    ${buttonReset};
    pointer-events: auto;
  }

  & > *:first-child,
  & > div > *:first-child {
    margin-top: 0;
  }

  & > *:last-child,
  & > div > *:last-child {
    margin-bottom: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${h4}
    margin-top: 1em;
    margin-bottom: 1em;
  }

  p {
    margin: 1em 0;
  }

  img {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  i,
  em {
    font-style: italic;
  }

  b,
  strong {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  u {
    text-decoration: underline;
  }

  ul {
    list-style: disc inside;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  ol {
    list-style: decimal inside;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  table {
    min-width: 100%;

    th,
    td {
      padding: ${({ theme }) => `${theme.spacing.s1} ${theme.spacing.s2}`};
      font-weight: normal;
    }

    td {
      border-top: 1px solid ${({ theme }) => theme.colors.dust};
    }
  }
`;

export default RichText;
