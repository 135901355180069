import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import useAgegateCookie from 'hooks/useAgegateCookie';
import { useLifecycles } from 'react-use';
import { useGlobalContext } from 'store/GlobalProvider';
import { usePageContext } from 'store/PageProvider';
import media from 'styles/media';
import theme from 'styles/theme';
import { SmallCapsCSS, Title4 } from 'styles/typography';
import { Button } from './Button';
import { Container as FormInputContainer, Field, Input, Radio } from './Form';
import { Modal, Header as ModalHeader, Close as ModalClose } from './Modal';
import RichText from './RichText';
import iso2to3 from 'data/iso2to3';
import { getAge } from 'core/helpers/getAge';
import { DEFAULT_LEGAL_AGE } from 'core/global';

const ModalNewsletterComponent = ({ ...others }) => {
  const { siteData, localeIso, countries } = usePageContext();
  const { setSavedScrollTop, setScrollEnabled, userCountry } = useGlobalContext();
  const [agegate] = useAgegateCookie();
  const [data, setData] = useState({
    title: 'Mr.',
    firstname: '',
    lastname: '',
    email: '',
    birthday: agegate?.day || '',
    birthmonth: agegate?.month || '',
    birthyear: agegate?.year || '',
  });
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const {
    newsletter_title,
    newsletter_introduction,
    newsletter_form_title_label,
    newsletter_form_mr_label,
    newsletter_form_mrs_label,
    newsletter_form_firstname_label,
    newsletter_form_firstname_placeholder,
    newsletter_form_lastname_label,
    newsletter_form_lastname_placeholder,
    newsletter_form_email_label,
    newsletter_form_email_placeholder,
    newsletter_form_birthdate_label,
    newsletter_form_birthday_label,
    newsletter_form_birthmonth_label,
    newsletter_form_birthyear_label,
    newsletter_form_consent_text,
    newsletter_form_submit_text,
    newsletter_success_message,
    newsletter_error_message,
    newsletter_required,
  } = siteData;

  const hasTitle = !!newsletter_title?.text;
  const hasIntro = !!newsletter_introduction?.html;
  const hasConsent = !!newsletter_form_consent_text?.html;

  const input = {
    required: true,
    errorText: newsletter_required,
    onChange: (e) => {
      const { value, name } = event.target;
      const error = !e.target.checkValidity();
      setData((prev) => ({ ...prev, [name]: value }));
      setErrors((prev) => ({ ...prev, [name]: error }));
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const $form = e.currentTarget;

    const today = new Date();
    const date = new Date(`${data.birthyear}-${data.birthmonth}-${data.birthday}`);
    const age = getAge(today, date);
    const country = countries.find(({ code }) => userCountry.toUpperCase() === code);
    const ageLimit = country ? country.age : DEFAULT_LEGAL_AGE;
    const isOverLimit = age >= ageLimit;

    if (!isOverLimit) {
      setErrors((prev) => ({ ...prev, birthday: true, birthmonth: true, birthyear: true }));
    } else if ($form.checkValidity() === false) {
      const $fields = [...$form.querySelectorAll('[required]')];

      $fields.forEach(($field) => {
        setErrors((prev) => ({ ...prev, [$field.name]: !$field.checkValidity() }));
      });
    } else {
      fetch(process.env.GATSBY_ENGAGE_API_URL, {
        method: 'POST',
        body: JSON.stringify({
          object: 'contacts',
          type: 'create',
          body: {
            optinRelational: true,
            optinEmailChannel: true,
            optinMessagingChannel: false,
            civility: data.title || null,
            firstname: data.firstname || null,
            lastname: data.lastname || null,
            email: data.email || null,
            locationOfResidence: iso2to3[userCountry.toUpperCase()],
            market: iso2to3[localeIso.toUpperCase()],
            birthDate: `${data.birthyear}-${data.birthmonth}-${data.birthday}`,
            brand: 'EMI',
            typeOfTouchPoint: 'newsletter',
            dataSource: 'Website',
            sourceType: 'Newsletter Form',
            touchPoint: window.location.href,
          },
        }),
        headers: {
          Api_Key: process.env.GATSBY_ENGAGE_API_KEY,
          Accept: 'application/json',
          'Content-type': 'application/json',
        },
      })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response);
          } else {
            return Promise.reject(response);
          }
        })
        .then(() => {
          setSuccess(true);
        })
        .catch(({ data }) => {
          setError(true);
        });
    }
  };

  // Disable/Enable scroll on Mount/Unmount
  useLifecycles(
    () => {
      const { scrollTop } = document.scrollingElement || document.documentElement;
      setSavedScrollTop(scrollTop);
      setScrollEnabled(false);
    },
    () => setScrollEnabled(true)
  );

  return (
    <Modal
      {...others}
      id="newsletter"
      header={(close) => (
        <Fragment>
          {hasTitle && <Title4>{newsletter_title.text}</Title4>}
          {close}
        </Fragment>
      )}
    >
      {success ? (
        <Success>{newsletter_success_message}</Success>
      ) : (
        <Form style={{ '--form-color': theme.colors.green }} onSubmit={handleSubmit} noValidate>
          {hasIntro && (
            <RichText dangerouslySetInnerHTML={{ __html: newsletter_introduction.html }} />
          )}
          <Fields>
            <Field label={newsletter_form_title_label}>
              <Radio
                label={newsletter_form_mr_label}
                id="Mr."
                name="title"
                value="Mr."
                checked={data.title === 'Mr.'}
                {...input}
              />
              <Radio
                label={newsletter_form_mrs_label}
                id="Mrs."
                name="title"
                value="Mrs."
                checked={data.title === 'Mrs.'}
                {...input}
              />
            </Field>
            <Field label={newsletter_form_firstname_label}>
              <Input
                type="text"
                name="firstname"
                placeholder={newsletter_form_firstname_placeholder}
                value={data.firstname}
                error={!!errors?.firstname}
                {...input}
              />
            </Field>
            <Field label={newsletter_form_lastname_label}>
              <Input
                type="text"
                name="lastname"
                placeholder={newsletter_form_lastname_placeholder}
                value={data.lastname}
                error={!!errors?.lastname}
                {...input}
              />
            </Field>
            <Field label={newsletter_form_email_label}>
              <Input
                type="email"
                name="email"
                placeholder={newsletter_form_email_placeholder}
                pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,8}$"
                value={data.email}
                error={!!errors?.email}
                {...input}
              />
            </Field>
            <Field label={newsletter_form_birthdate_label}>
              <BirthdateInputs>
                <Input
                  type="number"
                  name="birthday"
                  inputMode="numeric"
                  min="1"
                  max="31"
                  placeholder={newsletter_form_birthday_label}
                  value={data.birthday}
                  error={!!errors?.birthday}
                  {...input}
                />
                <Input
                  type="number"
                  name="birthmonth"
                  inputMode="numeric"
                  min="1"
                  max="12"
                  placeholder={newsletter_form_birthmonth_label}
                  value={data.birthmonth}
                  error={!!errors?.birthmonth}
                  {...input}
                />
                <Input
                  type="number"
                  name="birthyear"
                  inputMode="numeric"
                  min="1866"
                  max={new Date().getFullYear()}
                  placeholder={newsletter_form_birthyear_label}
                  value={data.birthyear}
                  error={!!errors?.birthyear}
                  {...input}
                />
              </BirthdateInputs>
            </Field>
          </Fields>
          <Footer>
            {error && <Error>{newsletter_error_message}</Error>}

            {hasConsent && (
              <RichText dangerouslySetInnerHTML={{ __html: newsletter_form_consent_text.html }} />
            )}

            <Button background="small" color="green">
              {newsletter_form_submit_text}
            </Button>
          </Footer>
        </Form>
      )}
    </Modal>
  );
};

export const ModalNewsletter = styled(ModalNewsletterComponent)`
  ${ModalHeader} {
    margin-left: ${({ theme }) => theme.spacing.s5};
    margin-right: ${({ theme }) => theme.spacing.s5};
  }

  ${ModalClose} {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  ${RichText} a {
    color: inherit;
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        text-decoration: none;
      }
    }
  }

  ${media.mobile`
    ${ModalHeader} {
      margin-left: ${({ theme }) => theme.spacing.s3};
      margin-right: ${({ theme }) => theme.spacing.s3};
      justify-content: flex-start;
    }
  `}
`;

const Success = styled.p`
  text-align: center;
  padding: ${({ theme }) => `0 ${theme.spacing.s2} ${theme.spacing.s3}`};
`;

const Error = styled.p`
  color: ${({ theme }) => theme.colors.red};
  margin-top: ${({ theme }) => theme.spacing.s2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const Form = styled.form`
  padding: ${({ theme }) => `0 ${theme.spacing.s5} ${theme.spacing.s4}`};

  & > ${RichText} {
    margin-bottom: ${({ theme }) => theme.spacing.s3};
    text-align: center;
  }

  ${media.mobile`
    padding-left: ${({ theme }) => theme.spacing.s3};
    padding-right: ${({ theme }) => theme.spacing.s3};
  `}
`;

const Fields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => `${theme.spacing.s4} ${theme.spacing.s2}`};

  ${Field} {
    &:first-child {
      grid-column: 1 / -1;
    }
  }

  ${media.mobile`
    grid-template-columns: 1fr;
  `}
`;

const BirthdateInputs = styled.div`
  display: flex;

  ${FormInputContainer} {
    display: inline-block;
    min-width: 4em;

    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.s2};
    }
  }
`;

const Footer = styled.div`
  text-align: center;

  ${RichText} {
    ${SmallCapsCSS}
    margin: ${({ theme }) => theme.spacing.s4} 0;
    line-height: ${14 / 10.5};
  }
`;
