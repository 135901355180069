import propsToDom from 'core/helpers/propsToDom';
import React from 'react';
import { usePageContext } from 'store/PageProvider';
import styled from 'styled-components';
import { LinkUnderline } from './LinkUnderline';
import { rem } from 'styles/utils';
import { useLocalStorage } from 'react-use';

const NavLangComponent = ({ ...others }) => {
  const { hreflang, lang: currentLang } = usePageContext();
  const [, setChoosenLocale] = useLocalStorage('user-selected-locale');

  const handleClick = (locale) => {
    setChoosenLocale(locale);
  };

  return (
    <nav {...propsToDom(others)}>
      {hreflang.map(({ lang, url, others }) => {
        const [locale] = lang.split('-');
        return (
          <LinkUnderline
            key={lang}
            to={url}
            onClick={() => handleClick(locale)}
            isActive={currentLang === lang}
          >
            {locale}
          </LinkUnderline>
        );
      })}
    </nav>
  );
};

export const NavLang = styled(NavLangComponent)`
  display: flex;
  flex-grow: 0;
  align-items: center;

  ${LinkUnderline}:not(:last-child) {
    margin-right: ${rem(30)};
  }
`;
