import React from 'react';
import styled from 'styled-components';
import { Caps, Title4 } from 'styles/typography';

const RecipeIngredientsComponent = ({ className, ingredients, title }) => {
  return (
    <div className={className}>
      <Title4>{title}</Title4>
      {ingredients.map(({ ingredient, quantity }, index) => (
        <Caps as="div" key={ingredient + quantity + index}>
          <p>
            <span>{ingredient}</span>
          </p>
          <p>{quantity}</p>
        </Caps>
      ))}
    </div>
  );
};

export const RecipeIngredients = styled(RecipeIngredientsComponent)`
  margin-bottom: ${({ theme }) => theme.spacing.s3};

  ${Title4} {
    margin-bottom: ${({ theme }) => theme.spacing.s3};
  }

  ${Caps} {
    display: flex;

    &:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing.s1};
    }

    p {
      position: relative;
      overflow: hidden;

      &:first-child {
        flex-grow: 3;
        font-weight: normal;
      }
    }

    span {
      position: relative;
      display: inline;

      &:after {
        content: '..........................................................................................';
        position: absolute;
        left: 100%;
        bottom: -2px;
      }
    }
  }
`;
