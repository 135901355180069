import React from 'react';
import styled from 'styled-components';
import { Panel } from 'components/Panel';
import RichText from 'components/RichText';
import { graphql } from 'gatsby';
import propsToLink from 'core/helpers/propsToLink';
import { Button } from 'components/Button';
import { ContainerGrid } from 'components/Container';

const PushMapComponent = ({ slice_type, primary, ...others }) => {
  const { text, link, link_text } = primary;
  const textRaw = text?.richText || text;

  return (
    <Panel {...others} id={slice_type} headerTheme="light-dust">
      <ContainerGrid>
        <ImageContainer>
          <img src="//via.placeholder.com/814x574" width="814" height="574" alt="" />
        </ImageContainer>
        <Content>
          <RichText render={textRaw} />
          <Button background="medium" color="dust" {...propsToLink(link)}>
            {link_text}
          </Button>
        </Content>
      </ContainerGrid>
    </Panel>
  );
};

export const PushMap = styled(PushMapComponent)`
  background-color: ${({ theme }) => theme.colors.green};
  color: ${({ theme }) => theme.colors.dust};

  ${ContainerGrid} {
    grid-row-gap: ${({ theme }) => theme.spacing.s3};
  }
`;

const ImageContainer = styled.div`
  grid-column: 1 / span 8;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const Content = styled.div`
  grid-column: 9 / span 4;
  align-self: center;

  ${Button} {
    margin-top: ${({ theme }) => theme.spacing.s6};
  }
`;

export const query = graphql`
  fragment SlicePushMap on PrismicArticleDataBodyPushMap {
    id
    slice_type
    slice_label
    primary {
      text {
        richText
      }
      link {
        target
        url
      }
      link_text
    }
  }
`;
