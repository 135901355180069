import { useEffect, useRef, useState } from 'react';
import { useSpring, useTransform, useMotionValue } from 'framer-motion';
import { slow } from 'constants/springs';
import { useGlobalContext } from 'store/GlobalProvider';

const useParallax = (t, options = {}) => {
  const { wh } = useGlobalContext();
  const { smooth = true, animation = 'parallax', log = false } = options;
  const $ref = useRef(null);
  const [bounds, setBounds] = useState({ y: null, height: null });
  const scrollY = useMotionValue(0);

  const y = useTransform(scrollY, (y) => {
    if (bounds.y === null || !bounds.h === null || !wh === null) {
      return 0;
    }

    if (animation === 'parallax') {
      y = (y - bounds.y + wh / 2 - bounds.h / 2) / wh;
    }

    return t(y, bounds, wh);
  });

  const ySmooth = useSpring(y, slow);

  const handleResize = ([entry]) => {
    const $el = $ref.current;
    if ($el) {
      const { transform } = $el.style;
      $el.style.removeProperty('transform');
      const clientRect = $el.getBoundingClientRect();
      $el.style.transform = transform;
      const { scrollTop } = document.scrollingElement;
      setBounds({ y: clientRect.top + scrollTop, h: $el.offsetHeight });
    }
  };

  useEffect(() => {
    if ($ref.current) {
      const resizer = new ResizeObserver(handleResize);
      const scroller = $ref.current.closest('.scrollable') || window;

      const handleScroll = (event) => {
        scrollY.set(scroller === window ? scroller.scrollY : scroller.scrollTop);
      };

      resizer.observe($ref.current);
      scroller.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
        resizer.disconnect();
        scroller.removeEventListener('scroll', handleScroll);
      };
    }
  }, [scrollY]);

  return [$ref, smooth ? ySmooth : y];
};

export default useParallax;
