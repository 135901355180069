import React from 'react';
import { Container } from 'components/Container';
import { Panel } from 'components/Panel';
import { graphql } from 'gatsby';
import styled, { useTheme } from 'styled-components';
import propsToDom from 'core/helpers/propsToDom';
import { ArticleThumbnail } from 'components/ArticleThumbnail';
import { Title1 } from 'styles/typography';
import { CarouselMotion, Wrapper as CarouselWrapper } from 'components/CarouselMotion';
import { useGlobalContext } from 'store/GlobalProvider';
import { rem } from 'styles/utils';
import media from 'styles/media';
import useParallax from 'hooks/useParallax';
import clamp from 'core/helpers/clamp';
import { motion } from 'framer-motion';

const LatestNewsComponent = ({ slice_type, primary, items, ...others }) => {
  const { colors } = useTheme();
  const { isMobile } = useGlobalContext();
  const { main_title, color } = primary;
  const hasTitle = !!main_title?.text;

  const articles = items
    .filter(({ article }) => !!article?.document?.data)
    .map(({ article }) => article.document);

  const isCarousel = (isMobile && articles.length > 1) || (!isMobile && articles.length > 3);
  const ItemsComponent = isCarousel ? CarouselMotion : Grid;
  const carouselProps = isCarousel ? { magnet: true } : {};

  const [$pTitle, yTitle] = useParallax((y, b) => {
    const max = b.h / 2;
    return clamp(y * max, -max, max);
  });

  return (
    <Panel {...propsToDom(others)} id={slice_type} style={{ '--accent': colors[color] }}>
      <Container>
        {hasTitle && (
          <Header>
            <Title1 ref={$pTitle} as={motion.h2} style={{ y: yTitle }}>
              {main_title.text}
            </Title1>
          </Header>
        )}

        <ItemsComponent {...carouselProps}>
          {articles.map(({ prismicId, id, uid, data }) => {
            const key = prismicId || id;
            return <ArticleThumbnail key={key} id={key} uid={uid} {...data} />;
          })}
        </ItemsComponent>
      </Container>
    </Panel>
  );
};

const Header = styled.header`
  position: absolute;
  top: ${rem(30)};
  left: 0;
  color: var(--accent);
  z-index: 3;
  pointer-events: none;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(10px, 1fr));
  grid-gap: var(--margin);
`;

export const LatestNews = styled(LatestNewsComponent)`
  --margin: ${rem(30)};
  position: relative;
  overflow: hidden;

  ${Container} {
    position: relative;
  }

  ${Title1} {
    text-transform: uppercase;
  }

  ${CarouselWrapper} {
    gap: var(--margin);
  }

  ${ArticleThumbnail} {
    display: block;
    flex-shrink: 0;
    width: calc((var(--container-width, 100vw) - var(--margin) * 2) / 3);
    max-width: 100%;
  }

  ${media.mobile`
    ${ArticleThumbnail} {
      width: ${rem(240)};
    }
  `}
`;

export const query = graphql`
  fragment SliceLatestNews on PrismicPageHomeDataBodyLatestNews {
    id
    slice_type
    slice_label
    primary {
      main_title {
        text
      }
      color
    }
    items {
      article {
        id
        uid
        document {
          ... on PrismicArticle {
            id
            uid
            prismicId
            url
            data {
              is_recipe
              title {
                richText
              }
              category {
                id
                uid
              }
              thumbnail {
                alt
                url
                dimensions {
                  width
                  height
                }
              }
              extract {
                richText
              }
            }
          }
        }
      }
    }
  }
`;
