import React from 'react';
import styled from 'styled-components';
import { buttonReset } from 'styles/others';
import Icon from './Icon';

const CarouselDotsComponent = ({ length, active, onClickDot, ...others }) => {
  return (
    <div {...others}>
      {new Array(length).fill(0).map((_, index) => (
        <Dot $isActive={index === active} key={`dot-${index}`} onClick={() => onClickDot(index)}>
          <Icon id="carousel-dot" />
        </Dot>
      ))}
    </div>
  );
};

export const CarouselDots = styled(CarouselDotsComponent)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.s3};
`;

const Dot = styled.button`
  ${buttonReset}
  font-size: 4px;
  padding: 4px;
  flex-shrink: 0;
  color: ${({ $isActive, theme }) => ($isActive ? theme.colors.red : null)};
`;
