import { createGlobalStyle } from 'styled-components';
import { rem } from 'polished';
import media from 'styles/media';

const GlobalStyle = createGlobalStyle`
  :root {
    --viewport-height: ${({ viewportHeight }) => `${viewportHeight}px`};
    --header-height: ${({ headerHeight }) => `${headerHeight}px`};
  }

  html {
    font-size: max(${rem(9)}, ${(14 / 1440) * 100}vw);
    line-height: ${({ theme }) => theme.lineHeight.base};
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.font};
    -webkit-font-smoothing: antialiased;

    ${media.mobile`
      font-size: 14px;
    `}
  }

  body {
    position: ${({ scrollEnabled }) => (scrollEnabled ? null : 'fixed')};
    overflow-y: ${({ scrollEnabled }) => (scrollEnabled ? null : 'scroll')};
    width: 100%;
    font-size: max(${rem(16)}, 16px);
    overscroll-behavior: none;
  }

  .scrollable {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .mobile-only {
    ${media.fromMobile`
      display: none !important;
    `}
  }

  .no-mobile {
    ${media.mobile`
      display: none !important;
    `}
  }
`;

export default GlobalStyle;
