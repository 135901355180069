import React from 'react';
import styled, { css } from 'styled-components';
import Icon from './Icon';
import Link from './Link';
import { CapsCSS } from 'styles/typography';
import { linkTransition } from 'styles/transitions';

const LinkUnderlineComponent = ({ tag: Tag = Link, isActive, children, className, ...others }) => {
  className = `${className} ${isActive ? 'is-active' : ''}`;

  return (
    <Tag className={className} {...others}>
      <span>{children}</span>
      <Icon id="line-horizontal" />
    </Tag>
  );
};

export const LinkUnderline = styled(LinkUnderlineComponent)`
  ${CapsCSS}
  position: relative;

  & > span {
    transition-property: opacity;
    ${linkTransition}
  }

  & ${Icon}[data-id="line-horizontal"] {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 4px;
    transform: scaleX(0);
    transform-origin: 100% 50%;
    opacity: 0;

    ${({ theme }) => css`
      transition: transform 0.5s ${theme.easings.inOutExpo}, opacity 0.4s ${theme.easings.outSine};
    `}
  }

  @media (hover: hover) and (pointer: fine) {
    &:not(.is-active):hover {
      span {
        opacity: 0.5;
      }
    }

    a:hover &,
    &:hover {
      ${Icon}[data-id="line-horizontal"] {
        transform-origin: 0 50%;
        transform: scaleX(1);
        opacity: 1;
      }
    }
  }

  &.is-active ${Icon}[data-id="line-horizontal"] {
    transform-origin: 0 50%;
    color: ${({ theme }) => `var(--accent-color, currentColor) `};
    transform: scaleX(1);
    opacity: 1;
  }
`;
