import propsToDom from 'core/helpers/propsToDom';
import propsToLink from 'core/helpers/propsToLink';
import React from 'react';
import { useGlobalContext } from 'store/GlobalProvider';
import { usePageContext } from 'store/PageProvider';
import styled from 'styled-components';
import { Title4 } from 'styles/typography';
import { Button } from './Button';
import Icon from './Icon';
import Link from './Link';
import { rem } from 'styles/utils';
import { linkTransition } from 'styles/transitions';

const SOCIALS = ['Facebook', 'Instagram', 'Pinterest', 'Spotify', 'Twitter'];

const NavContactSubscribeSocialsComponent = ({ ...others }) => {
  const { siteData } = usePageContext();
  const { setModal } = useGlobalContext();
  const { contact_title, contact_email, subscribe_title, subscribe_link_text, ...siteOthers } =
    siteData;
  const hasContact = !!contact_title?.text && !!contact_email;
  const hasSubscribe = !!subscribe_title?.text;

  const onClickSubscribe = (e) => {
    e.preventDefault();
    setModal({ id: 'newsletter' });
  };

  const socialLinks = SOCIALS.map((name) => {
    const key = name.toLowerCase();
    const link = siteOthers[`${key}_url`];
    return link?.url ? { link, name } : null;
  }).filter((obj) => obj !== null);

  return (
    <div {...propsToDom(others)}>
      {hasContact && (
        <ContactSubscribe>
          <Title4>{contact_title.text}</Title4>
          <ContactEmail href={`mailto:${contact_email}`}>{contact_email}</ContactEmail>
        </ContactSubscribe>
      )}
      {hasSubscribe && (
        <ContactSubscribe>
          <Title4>{subscribe_title.text}</Title4>
          <Button onClick={onClickSubscribe} background="medium" color="green">
            {subscribe_link_text || 'Missing text'}
          </Button>
        </ContactSubscribe>
      )}
      <Socials>
        {socialLinks.map(({ link, name }, index) => (
          <SocialsLink key={name} {...propsToLink(link)} title={name}>
            <Icon id={name.toLowerCase()} />
          </SocialsLink>
        ))}
      </Socials>
    </div>
  );
};

export const NavContactSubscribeSocials = styled(NavContactSubscribeSocialsComponent)``;

const ContactSubscribe = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${Title4} {
    margin-bottom: ${({ theme }) => theme.spacing.s1};
    margin-right: ${({ theme }) => theme.spacing.s4};
  }

  & + & {
    margin-top: ${({ theme }) => theme.spacing.s3};
  }
`;

const ContactEmail = styled.a`
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.black};
  ${linkTransition}
  transition-property: opacity;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      opacity: 0.5;
      text-decoration: none;
    }
  }
`;

const Socials = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: ${({ theme }) => `${theme.spacing.s3} -${theme.spacing.s1} 0 `};
`;

const SocialsLink = styled(Link)`
  font-size: ${rem(20)};
  padding: ${({ theme }) => theme.spacing.s1};
  ${linkTransition}
  transition-property: opacity;

  ${Icon} {
    display: block;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      opacity: 0.5;
    }
  }
`;
