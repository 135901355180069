import 'styles/global.css';

import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from 'styles/theme';
import GlobalProvider from 'store/GlobalProvider';
import Layout from 'components/Layout';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import linkResolver from 'core/linkResolver';

import PageHome from 'templates/home';
import PageRich from 'templates/rich';
import PageContent from 'templates/content';
import Page404 from 'templates/404';
import Blog from 'templates/blog';
import Article from 'templates/article';

export const wrapRootElement = ({ element }) => {
  return (
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
          linkResolver,
          componentResolver: {
            page_home: PageHome,
            page_rich: PageRich,
            page_content: PageContent,
            page_404: Page404,
            blog: Blog,
            article: Article,
          },
        },
      ]}
    >
      <ThemeProvider theme={theme}>
        <GlobalProvider>{element}</GlobalProvider>
      </ThemeProvider>
    </PrismicPreviewProvider>
  );
};

export const wrapPageElement = ({ element, props }) => {
  if (props.pageContext.fromPrismic) {
    return <Layout {...props}>{element}</Layout>;
  } else {
    return element;
  }
};
