import usePrismicAPI from 'hooks/usePrismicAPI';
import React, { useCallback, useState } from 'react';
import { useIsomorphicLayoutEffect, useLifecycles } from 'react-use';
import { useGlobalContext } from 'store/GlobalProvider';
import styled from 'styled-components';
import theme from 'styles/theme';
import { Article } from './Article';
import { Modal } from './Modal';

const ModalArticleComponent = ({ previousUrl, url, uid, id, lang, ...others }) => {
  const { setModal } = useGlobalContext();
  const [prismic] = usePrismicAPI();
  const [article, setArticle] = useState(null);
  const hasArticle = article !== null;

  const fetch = async () => {
    try {
      const response = await prismic.getByID(id, {
        lang,
        fetchLinks: [
          'blog_category.title',
          'blog_category.accent_color',
          'article.title',
          'article.category',
          'article.thumbnail',
          'article.extract',
          'article.is_recipe',
        ],
      });

      if (response?.data) {
        setArticle(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useIsomorphicLayoutEffect(() => {
    if (prismic && article === null) fetch();
  }, [prismic, article]);

  const handlePopState = useCallback(
    (event) => {
      if (window.location.pathname === previousUrl) {
        setModal(null);
      }
    },
    [setModal, previousUrl]
  );

  useLifecycles(
    () => {
      history.pushState(null, null, url);
      window.addEventListener('popstate', handlePopState);
    },
    () => {
      history.pushState(null, null, previousUrl);
      window.removeEventListener('popstate', handlePopState);
    }
  );

  return (
    <Modal
      {...others}
      id="article"
      ready={hasArticle}
      isFullWidth={true}
      style={{
        '--accent-color': hasArticle && theme.colors[article.category?.data?.accent_color],
      }}
    >
      {hasArticle && <Article isGatsbySource={false} {...article} />}
    </Modal>
  );
};

export const ModalArticle = styled(ModalArticleComponent)``;
