import useAgegateCookie from 'hooks/useAgegateCookie';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useMedia, useWindowSize } from 'react-use';
import { mqMobile, mqPortrait } from 'styles/media';

export const GlobalContext = createContext({
  savedScrollTop: 0,
  scrollEnabled: true,
  headerHeight: null,
  headerTheme: null,
  isClient: false,
  isMenuOpen: false,
  showAgeGate: false,
  userCountry: null,
  storeLocatorIso: null,
  modal: null,
  isMobile: false,
  isPortrait: false,
  hasMouse: false,
  ww: null,
  wh: null,
  submenu: { id: null, timeout: null },
  subnav: null,
});

const GlobalProvider = ({ children }) => {
  const [{ year, country }] = useAgegateCookie();
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [savedScrollTop, setSavedScrollTop] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(null);
  const [headerTheme, setHeaderTheme] = useState(null);
  const [isClient, setClient] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [submenu, setSubmenu] = useState({ id: null, timeout: null });
  const [showAgeGate, setShowAgeGate] = useState(year === null || country === null);
  const [userCountry, setUserCountry] = useState(null);
  const [storeLocatorIso, setStoreLocatorIso] = useState(null);
  const [modal, setModal] = useState(null);
  const [subnav, setSubnav] = useState(null);
  const isMobile = useMedia(mqMobile);
  const isPortrait = useMedia(mqPortrait);
  const hasMouse = useMedia('(pointer: fine)', true);

  const { width: ww, height: wh } = useWindowSize(null, null);

  useEffect(() => {
    setClient(true);

    if (typeof window !== 'undefined') {
      if (window.location.hash.includes('#buy')) {
        setModal({ id: 'buy' });
      }
    }
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        storeLocatorIso,
        setStoreLocatorIso,
        userCountry,
        setUserCountry,
        modal,
        setModal,
        showAgeGate,
        setShowAgeGate,
        isMobile,
        isPortrait,
        isMenuOpen,
        setMenuOpen,
        submenu,
        setSubmenu,
        headerTheme,
        setHeaderTheme,
        ww,
        wh,
        isClient,
        headerHeight,
        setHeaderHeight,
        scrollEnabled,
        setScrollEnabled,
        savedScrollTop,
        setSavedScrollTop,
        hasMouse,
        subnav,
        setSubnav,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw Error('useGlobalContext must be used inside a `GlobalProvider`');
  }
  return context;
};
