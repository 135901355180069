import React, { useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import Link from 'components/Link';
import propsToLink from 'core/helpers/propsToLink';
import { useGlobalContext } from 'store/GlobalProvider';
import styled from 'styled-components';
import { rem } from 'styles/utils';
import { CapsCSS } from 'styles/typography';

const SubmenuComponent = ({ className, primary, items, ...others }) => {
  const { submenu, setSubmenu } = useGlobalContext();
  const { id } = primary;
  const open = submenu?.id === id;

  const onMouseEnter = useCallback(
    (event) => {
      clearTimeout(submenu?.timeout);
    },
    [submenu.timeout]
  );

  const onMouseLeave = (event) => {
    if (submenu.id !== id) return;
    const timeout = setTimeout(() => setSubmenu({ active: null, timeout: null }), 400);
    setSubmenu((prev) => ({ ...prev, timeout }));
  };

  const style = useMemo(() => {
    if (open) {
      return {
        left: submenu?.left,
        top: submenu?.top + submenu?.height,
      };
    }

    return {};
  }, [submenu, open]);

  return (
    <div
      className={classNames(className, { open })}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      style={style}
    >
      {items.map(({ link, text }, index) => (
        <Link {...propsToLink(link)} key={index}>
          {text}
        </Link>
      ))}
    </div>
  );
};

export const Submenu = styled(SubmenuComponent)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  visibility: hidden;
  background: ${({ theme }) => theme.colors.dust};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.black};
  margin-top: ${rem(15)};
  min-width: 270px;

  ${Link} {
    ${CapsCSS};
    display: block;
    text-transform: uppercase;
    padding: ${rem(25)} ${rem(25)};

    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.black};
    }
  }

  &.open {
    visibility: visible;
  }
`;
