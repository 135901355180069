import styled, { css } from 'styled-components';
import { rem } from 'styles/utils';
import media from './media';

export const fontTitle = css`
  font-family: 'Louize', serif;
`;

export const fontMaar = css`
  font-family: 'Marr', sans-serif;
`;

// ------------------------------------------------------------
//    TITLES
// ------------------------------------------------------------

export const h1 = css`
  ${fontTitle}
  font-size: ${rem(84)};
  line-height: ${102 / 84};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  white-space: pre-line;

  ${media.mobile`
    font-size: ${rem(49)};
  `}

  ${media.smallMobile`
    font-size: ${rem(42)};
  `}
`;

export const Title1 = styled.h1`
  ${h1}
`;

export const h2 = css`
  ${fontTitle}
  font-size: ${rem(56)};
  line-height: ${100 / 56};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  white-space: pre-line;

  ${media.mobile`
    font-size: ${rem(42)};
    line-height: ${80 / 42};
  `}
`;

export const Title2 = styled.h2`
  ${h2}
`;

export const h3 = css`
  ${fontTitle}
  font-size: ${rem(42)};
  line-height: ${60 / 42};
  white-space: pre-line;

  ${media.mobile`
    font-size: ${rem(35)};
  `}
`;

export const Title3 = styled.h3`
  ${h3}
`;

export const h4 = css`
  ${fontTitle}
  font-size: ${rem(28)};
  line-height: ${34 / 28};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  ${media.mobile`
    font-size: ${rem(17.5)};
  `}
`;

export const Title4 = styled.h4`
  ${h4}
`;

export const h5 = css`
  ${fontTitle}
  font-size: ${rem(17.5)};
  line-height: ${21 / 17.5};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const Title5 = styled.h5`
  ${h5}
`;

export const highlightCSS = css`
  ${fontTitle};
  font-size: ${rem(36)};
  line-height: ${44 / 36};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  ${media.mobile`
    font-size: ${rem(24)};
  `}
`;

export const Highlight = styled.p`
  ${highlightCSS};
`;

export const CapsCSS = css`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: ${20 / 14};
  letter-spacing: 0.1em;
  text-transform: uppercase;
`;

export const Caps = styled.p`
  ${CapsCSS}
`;

export const SmallCapsCSS = css`
  text-transform: uppercase;
  font-size: max(10.5px, ${rem(10.5)});
  line-height: ${20 / 14};
  letter-spacing: 0.2rem;
`;

export const SmallCaps = styled.p`
  ${SmallCapsCSS}
`;
