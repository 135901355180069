'use client';

import { useRef, useState, useMemo } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';

export const useOffsetSize = (outerRef = null, decorate = () => ({})) => {
  const innerRef = useRef(null);
  const ref = outerRef || innerRef;
  const [rect, setRect] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    ...decorate(ref.current),
  });

  const update = ($node) => {
    const style = getComputedStyle($node);
    setRect({
      top: $node.offsetTop,
      left: $node.offsetLeft,
      width: $node.offsetWidth,
      innerWidth:
        $node.clientWidth - parseFloat(style.paddingLeft) - parseFloat(style.paddingRight),
      height: $node.offsetHeight,
      innerHeight:
        $node.clientHeight - parseFloat(style.paddingTop) - parseFloat(style.paddingBottom),
      ...decorate($node),
    });
  };

  const observer = useMemo(() => {
    if (typeof window !== 'undefined')
      return new ResizeObserver((entries) => {
        if (entries[0]) {
          const $node = entries[0].target;
          update($node);
        }
      });
  }, []);

  useIsomorphicLayoutEffect(() => {
    const element = ref.current;
    if (!element) return;
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [ref.current]);

  return [rect, ref];
};
