import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import RichText from 'components/RichText';
import { Title4 } from 'styles/typography';
import { rem } from 'styles/utils';

import {
  Accordion as ReactAccordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Icon from 'components/Icon';

const AccordionComponent = ({ slice_type, items, ...others }) => {
  return (
    <ReactAccordion {...others} allowMultipleExpanded={true} allowZeroExpanded={true}>
      {items.map(({ item_title, text }, index) => (
        <Item key={item_title.text + index}>
          <AccordionItemHeading>
            <Toggle>
              <Title4>{item_title.text}</Title4>
              <Icon id="plus" />
              <Icon id="minus" />
            </Toggle>
          </AccordionItemHeading>
          <Panel>
            <RichText render={text.richText} />
          </Panel>
        </Item>
      ))}
    </ReactAccordion>
  );
};

export const Accordion = styled(AccordionComponent)``;

const Item = styled(AccordionItem)``;

const Toggle = styled(AccordionItemButton)`
  position: relative;
  padding: ${({ theme }) => theme.spacing.s3} 0;
  border-bottom: 1px solid currentColor;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;

  ${Item}:first-child & {
    padding-top: 0;

    ${Icon} {
      top: ${({ theme }) => `calc(50% - ${theme.spacing.s1_5}) `};
    }
  }

  ${Icon} {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);

    &[data-id='minus'] {
      font-size: ${rem(4)};
      display: none;
    }

    &[data-id='plus'] {
      font-size: ${rem(18)};
    }
  }

  &[aria-expanded='true'] {
    ${Icon}[data-id="minus"] {
      display: block;
    }

    ${Icon}[data-id="plus"] {
      display: none;
    }
  }
`;

const Panel = styled(AccordionItemPanel)`
  padding: ${({ theme }) => theme.spacing.s3} 0;
`;

export const query = graphql`
  fragment SliceAccordion on PrismicPageContentDataBodyAccordion {
    id
    slice_type
    slice_label
    items {
      item_title {
        text
      }
      text {
        richText
      }
    }
  }
`;
