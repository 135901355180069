import * as prismic from '@prismicio/client';

const apiEndpoint = 'https://eminente.cdn.prismic.io/api/v2';

const usePrismicAPI = () => {
  if (typeof window === 'undefined') return [null, null];
  const client = prismic.createClient(apiEndpoint);
  return [client, prismic.filter];
};

export default usePrismicAPI;
