import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from 'components/SEO';
import SliceZone from 'components/SliceZone';
import { Title1 } from 'styles/typography';
import { ContainerGrid } from 'components/Container';
import { rem } from 'styles/utils';
import { useLifecycles, useMount } from 'react-use';
import { useGlobalContext } from 'store/GlobalProvider';
import { Text, Accordion } from 'components/slices';
import media from 'styles/media';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import linkResolver from 'core/linkResolver';
import useParallax from 'hooks/useParallax';
import clamp from 'core/helpers/clamp';
import { motion } from 'framer-motion';

const Page = ({ data }) => {
  const { setHeaderTheme, setShowAgeGate, showAgeGate } = useGlobalContext();
  const [hasAgeGateOnMount, setHasAgeGateOnMout] = useState();

  useLifecycles(
    () => {
      setHeaderTheme('dark');
      if (data.is_agegate_disabled) {
        setHasAgeGateOnMout(showAgeGate);
        setShowAgeGate(false);
      }
    },
    () => {
      if (data.is_agegate_disabled) {
        setShowAgeGate(hasAgeGateOnMount);
      }
    }
  );

  const [$left, yLeft] = useParallax((y, b, wh) => {
    const max = b.h;
    return clamp(y * 0.3, 0, max);
  });

  const [$right, yRight] = useParallax((y, b, wh) => {
    const max = b.h / 6;
    return clamp(y * -max, -max, max);
  });

  if (!data?.prismicPageContent?.data) return <h1>No data on home template</h1>;

  const { title, body, illustration_top_left, illustration_right_center, ...others } =
    data.prismicPageContent.data;

  return (
    <Module>
      <SEO {...others} />
      {illustration_top_left === 'leaves' && (
        <LeavesTopLeft
          ref={$left}
          style={{ y: yLeft }}
          src="/images/illustrations/leaves-red.svg"
          role="presentation"
          loading="lazy"
          width="274"
          height="266"
          transformTemplate={({ y }) => `translateY(${y}) translateZ(0) scaleX(-1)`}
        />
      )}
      {illustration_top_left === 'column' && (
        <ColumnTopLeft
          ref={$left}
          style={{ y: yLeft }}
          src="/images/illustrations/column.png"
          role="presentation"
          loading="lazy"
          width="216"
          height="1020"
        />
      )}
      {illustration_right_center === 'leaves' && (
        <LeavesCenterRight
          ref={$right}
          style={{ y: yRight }}
          src="/images/illustrations/leaves-red.svg"
          role="presentation"
          loading="lazy"
          width="274"
          height="266"
        />
      )}
      {illustration_right_center === 'sugarcane' && (
        <SugarCaneCenterRight
          ref={$right}
          style={{ y: yRight }}
          src="/images/illustrations/sugarcane.png"
          role="presentation"
          loading="lazy"
          width="216"
          height="820"
        />
      )}
      <ContainerGrid>
        <Title1>{title.text}</Title1>
        <Content>
          <SliceZone allSlices={body} />
        </Content>
      </ContainerGrid>
    </Module>
  );
};

const Module = styled.div`
  padding-top: ${rem(156)};
  padding-bottom: ${({ theme }) => theme.spacing.s8};

  ${Title1} {
    grid-column: 3 / span 8;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.red};
    margin-bottom: ${({ theme }) => theme.spacing.s8};
  }

  ${media.tabletPortrait`
    padding-top: ${rem(236)};

    ${Title1} {
      grid-column: 1 / -1;
    }
  `}

  ${media.mobile`
    padding-top: ${rem(156)};
  `}
`;

const LeavesTopLeft = styled(motion.img)`
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(-1);
  width: ${rem(226)};

  ${media.mobile`
    width: ${rem(144)};
  `}
`;

const ColumnTopLeft = styled(motion.img)`
  position: absolute;
  top: ${({ theme }) => theme.spacing.s2};
  left: ${({ theme }) => theme.spacing.s6};
  width: ${rem(108)};

  ${media.tabletPortrait`
    width: ${rem(40)};
  `}

  ${media.mobile`
    width: ${rem(28)};
  `}
`;

const LeavesCenterRight = styled(motion.img)`
  position: absolute;
  top: ${rem(455)};
  right: 0;
  width: ${rem(226)};

  ${media.tabletPortrait`
    display: none;
  `}

  ${media.mobile`
    width: ${rem(144)};
  `}
`;

const SugarCaneCenterRight = styled(motion.img)`
  position: absolute;
  top: ${rem(455)};
  right: ${({ theme }) => theme.spacing.s6};
  width: ${rem(108)};

  ${media.tabletPortrait`
    right:  ${({ theme }) => theme.spacing.s3};
  `}

  ${media.mobile`
    display: none;
  `}
`;

const Content = styled.div`
  grid-column: 4 / span 6;

  ${Text},
  ${Accordion} {
    margin: ${({ theme }) => theme.spacing.s3} 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${Accordion} + ${Text} {
    margin-top: ${({ theme }) => theme.spacing.s6};
  }

  ${media.tabletPortrait`
    grid-column: 2 / -2;
  `}
`;

export default withPrismicPreview(Page, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);

export const pageQuery = graphql`
  query PageContentQuery($uid: String!, $lang: String!) {
    prismicPageContent(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      id
      uid
      data {
        seo_title
        seo_og_title
        seo_description
        seo_og_description
        schemaorg {
          text
        }
        title {
          text
        }
        illustration_top_left
        illustration_right_center
        is_agegate_disabled
        body {
          ...SliceText
          ...SliceAccordion
        }
      }
    }
  }
`;
