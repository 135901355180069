import React, { Fragment, useState } from 'react';
import propsToDom from 'core/helpers/propsToDom';
import propsToLink from 'core/helpers/propsToLink';
import { useIsomorphicLayoutEffect } from 'react-use';
import { useGlobalContext } from 'store/GlobalProvider';
import { DEFAULT_LOCALE, usePageContext } from 'store/PageProvider';
import styled from 'styled-components';
import media from 'styles/media';
import { buttonReset } from 'styles/others';
import { rem, em } from 'styles/utils';
import { Button } from './Button';
import Icon from './Icon';
import Link from './Link';
import { LinkUnderline } from './LinkUnderline';
import { useMotionValueEvent, useScroll } from 'framer-motion';
import SliceZone from './SliceZone';
import { useOffsetSize } from 'hooks/useOffsetSize';
import { Subnav } from './Subnav';

const HeaderComponent = ({ isPage404, ...others }) => {
  const {
    headerTheme,
    setHeaderHeight,
    isMenuOpen,
    setMenuOpen,
    setModal,
    scrollEnabled,
    submenu,
    setSubmenu,
    subnav,
  } = useGlobalContext();
  const { siteData, locale } = usePageContext();
  const { header_menu, i18n_close, i18n_open } = siteData;
  const { scrollY } = useScroll();
  const [isScrolled, setScrolled] = useState();

  const [{ height }, $header] = useOffsetSize();

  const menu = header_menu.filter(({ link }) => !!link?.url);

  useMotionValueEvent(scrollY, 'change', (y) => {
    setScrolled(y > 0);
  });

  useIsomorphicLayoutEffect(() => {
    const $subnav = document.getElementById('subnav'); // TODO - refactor
    const nextHeight = $subnav ? $subnav.offsetHeight + height : height;
    setHeaderHeight(nextHeight);
  }, [height, subnav]);

  const handleClickBurger = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleNavItemEnter = (event) => {
    const { submenu: id } = event.currentTarget.dataset;
    if (!id) return;

    const bounds = event.currentTarget.getBoundingClientRect();
    clearTimeout(submenu?.timeout);
    setSubmenu({
      id,
      timeout: null,
      left: bounds.left,
      top: bounds.top,
      width: bounds.width,
      height: bounds.height,
    });
  };

  const handleNavItemLeave = (event) => {
    const { submenu: id } = event.currentTarget.dataset;
    if (!id) return;
    const timeout = setTimeout(() => {
      setSubmenu({ id: null, timeout: null });
    }, 400);
    setSubmenu((submenu) => ({ ...submenu, timeout }));
  };

  return (
    <Fragment>
      <header
        ref={$header}
        {...propsToDom(others)}
        data-theme={isMenuOpen ? 'dark' : headerTheme}
        data-404={isPage404}
        data-scrolled={!scrollEnabled || isScrolled}
        data-subnav={subnav ? true : null}
      >
        <Container>
          <ButtonBurger
            onClick={handleClickBurger}
            $isMenuOpen={isMenuOpen}
            aria-label={isMenuOpen ? i18n_close : i18n_open}
          >
            <Icon id="burger" />
            <Icon id="close" />
          </ButtonBurger>
          <Logo to={`/${locale === DEFAULT_LOCALE ? '' : locale}`}>
            <Icon id="logo" />
            <p>Ron de cuba</p>
          </Logo>
          <Nav>
            {menu.map(({ link, link_text, submenu_id }, index) => (
              <LinkUnderline
                key={link.url + index}
                {...propsToLink(link)}
                data-submenu={submenu_id}
                onMouseEnter={handleNavItemEnter}
                onMouseLeave={handleNavItemLeave}
              >
                <span>{link_text}</span>
                {submenu_id && <Icon id="arrow-right" />}
              </LinkUnderline>
            ))}
          </Nav>
          <Actions>
            <ButtonBuy
              isMenuOpen={isMenuOpen}
              id="buy"
              background="small"
              color="red"
              onClick={() => setModal({ id: 'buy' })}
            >
              <span className="text">{siteData.header_buy_button_text}</span>
              <Icon id="big-bag" />
            </ButtonBuy>
          </Actions>
        </Container>
        <Subnav />
      </header>
      <SliceZone allSlices={siteData?.body} />
    </Fragment>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  z-index: 2;

  [data-scrolled='true'] & {
    background: ${({ theme }) => theme.colors.dust};
  }

  [data-scrolled='true'] &,
  [data-subnav='true'] & {
    box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.colors.black};
  }

  ${media.tabletPortrait`
    padding: ${({ theme }) => `${theme.spacing.s2} ${rem(16)}`};
  `}
`;

export const Header = styled(HeaderComponent)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.header};
  color: ${({ theme }) => theme.colors.dust};
  transition: color 0.2s ${({ theme }) => theme.easings.principle};

  &[data-theme='dark'] {
    color: ${({ theme }) => theme.colors.black};
  }

  &[data-scrolled='true'] {
    color: ${({ theme }) => theme.colors.black};
  }

  &[data-404='true'] {
    ${Container} > * {
      visibility: hidden;
    }
  }
`;

const Logo = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: ${rem(25)};
  visibility: visible !important;
  margin-right: ${rem(30)};
  padding: ${({ theme }) => `${theme.spacing.s2} ${theme.spacing.s3}`};
  align-self: stretch;

  ${Icon} {
    display: block;
  }

  p {
    font-size: ${rem(10.5)};
    line-height: 1;
    text-transform: uppercase;
    margin-top: ${({ theme }) => theme.spacing.s1};
    letter-spacing: 0.2em;
    white-space: nowrap;
    text-align: center;
  }

  [data-scrolled='true'] &,
  [data-subnav='true'] & {
    box-shadow: inset -1px 0 0 0 ${({ theme }) => theme.colors.black};
  }

  ${media.tabletPortrait`
    font-size: ${rem(25)};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    box-shadow: none !important;
  `}
`;

const Nav = styled.nav`
  display: flex;
  gap: ${rem(35)};

  ${Icon}[data-id="arrow-right"] {
    transform: rotate(90deg);
    margin-left: ${rem(5)};
    font-size: ${rem(10)};
    vertical-align: baseline;
  }

  ${media.tabletPortrait`
    display: none;
  `}
`;

const ButtonBurger = styled.button`
  ${buttonReset}
  display: none;
  width: ${em(32)};
  height: ${em(32)};
  align-items: center;
  justify-content: center;

  ${Icon} {
    font-size: ${em(16)};

    &[data-id='close'] {
      display: ${({ $isMenuOpen }) => ($isMenuOpen ? 'block' : 'none')};
    }

    &[data-id='burger'] {
      display: ${({ $isMenuOpen }) => ($isMenuOpen ? 'none' : 'block')};
    }
  }

  ${media.tabletPortrait`
    display: flex;
  `}
`;

const Actions = styled.div`
  padding: ${({ theme }) => `${theme.spacing.s2} ${theme.spacing.s3}`};
  margin-left: auto;

  ${media.tabletPortrait`
    padding: 0;
  `}
`;

const ButtonBuy = styled(Button)`
  position: relative;
  pointer-events: ${({ isMenuOpen }) => (isMenuOpen ? 'none' : 'auto')};
  visibility: ${({ isMenuOpen }) => (isMenuOpen ? 'hidden' : 'visible')};

  ${Icon}[data-id="big-bag"] {
    display: block;
    margin-top: ${rem(-3)};
    font-size: ${rem(18)};
  }

  .text {
    margin-right: ${({ theme }) => theme.spacing.s1};
  }

  .background path {
    transition: fill 0.2s ${({ theme }) => theme.easings.principle};

    [data-theme='light-dust'] & {
      fill: ${({ theme }) => theme.colors.black};
    }
  }

  ${media.tabletPortrait`
    .text {
      display: none;
    }
  `}
`;
