import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import RichText from 'components/RichText';

const TextComponent = ({ slice_type, primary, ...others }) => {
  return (
    <div {...others}>
      <RichText render={primary.text.richText} />
    </div>
  );
};

export const Text = styled(TextComponent)``;

export const query = graphql`
  fragment SliceText on PrismicPageContentDataBodyText {
    id
    slice_type
    slice_label
    primary {
      text {
        richText
      }
    }
  }
`;
