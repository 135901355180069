import { isFilled } from '@prismicio/client';
import { ContainerGrid } from 'components/Container';
import Image from 'components/Image';
import { Panel } from 'components/Panel';
import clamp from 'core/helpers/clamp';
import propsToDom from 'core/helpers/propsToDom';
import { motion } from 'framer-motion';
import { graphql } from 'gatsby';
import useParallax from 'hooks/useParallax';
import React from 'react';
import styled from 'styled-components';
import media from 'styles/media';
import { Title1 } from 'styles/typography';
import { rem } from 'styles/utils';

const AwardsComponents = ({ slice_type, primary, items, ...others }) => {
  const { main_title } = primary;
  const hasTitle = isFilled.richText(main_title.richText);

  const [$pTitle, yTitle] = useParallax((y, b) => {
    const max = b.h / 2;
    return clamp(y * max, -max, max);
  });

  const images = items.filter(({ award }) => isFilled.image(award));

  return (
    <Panel {...propsToDom(others)} id={slice_type}>
      <ContainerGrid>
        {hasTitle && (
          <Title1 ref={$pTitle} style={{ y: yTitle }} as={motion.h2}>
            {main_title.text}
          </Title1>
        )}
        <Images>
          {images.map(({ award }, index) => {
            return <Image key={index} {...award} />;
          })}
        </Images>
      </ContainerGrid>
    </Panel>
  );
};

export const Awards = styled(AwardsComponents)`
  ${Title1} {
    position: relative;
    grid-column: 1 / -1;
    text-transform: uppercase;
    z-index: 2;
    text-align: center;
  }
`;

const Images = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${rem(16)};
  margin-top: ${rem(50)};
  grid-column: 1 / -1;

  ${Image} {
    flex: 0 0 ${rem(130)};
    width: ${rem(130)};
    height: ${rem(130)};
  }

  ${media.mobile`
    justify-content: center;

    ${Image} {
      flex: 0 0 ${rem(80)};
      width: ${rem(80)};
      height: ${rem(80)};
    }
  `}
`;

export const query = graphql`
  fragment SliceAwards on PrismicPageHomeDataBodyAwards {
    id
    slice_type
    slice_label
    primary {
      main_title {
        richText
        text
      }
    }
    items {
      award {
        alt
        url
        dimensions {
          width
          height
        }
      }
    }
  }
`;
