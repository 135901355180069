import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from 'components/SEO';
import SliceZone from 'components/SliceZone';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

const Page = ({ data }) => {
  if (!data?.prismicPageHome?.data) return <h1>No data on home template</h1>;

  const { body, ...others } = data.prismicPageHome.data;

  return (
    <Module>
      <SEO {...others} />
      <SliceZone allSlices={body} />
    </Module>
  );
};

const Module = styled.div``;

export default withPrismicPreview(Page);

export const pageQuery = graphql`
  query PageQuery($uid: String!, $lang: String!) {
    prismicPageHome(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      id
      uid
      data {
        seo_title
        seo_og_title
        seo_description
        seo_og_description
        schemaorg {
          text
        }
        title {
          text
        }
        body {
          ...SliceHero
          ...SliceBottle
          ...SliceIntroThumbs
          ...SliceTasting
          ...SliceHowToDrink
          ...SliceMovie
          ...SlicePushExplore
          ...SliceCasaEminente
          ...SliceProductsPush
          ...SlicePushCenter
          ...SliceLatestNews
          ...SliceAwards
          ...SliceHeroTourCarouselHome
        }
      }
    }
  }
`;
