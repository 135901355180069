import { Panel } from 'components/Panel';
import propsToDom from 'core/helpers/propsToDom';
import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { ContainerGrid } from 'components/Container';
import { Title1 } from 'styles/typography';
import Image from 'components/Image';
import RichText from 'components/RichText';
import { rem } from 'styles/utils';
import media from 'styles/media';
import useParallax from 'hooks/useParallax';
import clamp from 'core/helpers/clamp';
import { motion } from 'framer-motion';

const DuoImagesTextComponent = ({ slice_type, primary, ...others }) => {
  const { main_title, is_landscape_first, image_portrait, image_landscape, text } = primary;
  const hasTitle = !!main_title?.text;
  const hasImagePortrait = !!image_portrait?.url;
  const hasImageLandscape = !!image_landscape?.url;
  const hasText = text?.richText?.length > 0;

  const [$pTitle, yTitle] = useParallax((y, b) => {
    const max = b.h / 2;
    return clamp(y * max, -max, max);
  });

  const [$pSugar, ySugar] = useParallax((y, b) => {
    const max = b.h / 8;
    return clamp(y * max, -max, max);
  });

  return (
    <Panel {...propsToDom(others)} id={slice_type}>
      {hasTitle && (
        <Title1 ref={$pTitle} as={motion.h2} style={{ y: yTitle }}>
          {main_title.text}
        </Title1>
      )}
      <Container $isReversed={is_landscape_first}>
        {hasImagePortrait && <Image {...image_portrait} />}
        {hasImageLandscape && <Image {...image_landscape} />}
        {hasText && (
          <Content>
            <Sugar
              ref={$pSugar}
              style={{ y: ySugar }}
              src="/images/illustrations/sugarcane.png"
              width="216"
              height="820"
              role="presentation"
              loading="lazy"
            />
            <RichText render={text.richText} />
          </Content>
        )}
      </Container>
    </Panel>
  );
};

export const DuoImagesText = styled(DuoImagesTextComponent)`
  ${Title1} {
    position: relative;
    text-transform: uppercase;
    margin-bottom: -0.5em;
    line-height: 1;
    z-index: 2;
    color: ${({ theme }) => theme.colors.red};
    padding-left: ${({ theme }) => theme.spacing.s1_5};
  }
`;

const Container = styled(ContainerGrid)`
  ${Image} {
    &:nth-child(1) {
      grid-row: 1 / span 1;
      grid-column-end: span 4;
      grid-column-start: ${({ $isReversed }) => ($isReversed ? 9 : 1)};
    }

    &:nth-child(2) {
      grid-row: 1 / span 1;
      grid-column-end: span 8;
      grid-column-start: ${({ $isReversed }) => ($isReversed ? 1 : 5)};
    }
  }

  ${media.mobile`
    ${Image} {
      &:nth-child(1) {
        grid-row-start: ${({ $isReversed }) => ($isReversed ? 2 : 1)};
        margin-right: ${rem(54)};
        margin-bottom: ${({ $isReversed, theme }) => ($isReversed ? null : theme.spacing.s3)};
      }

      &:nth-child(2) {
        grid-row-start: ${({ $isReversed }) => ($isReversed ? 1 : 2)};
        margin-bottom: ${({ $isReversed, theme }) => ($isReversed ? theme.spacing.s3 : null)};
      }
  `}
`;

const Content = styled.div`
  position: relative;
  margin-top: ${rem(216)};
  grid-column: 5 / span 6;

  ${media.tabletPortrait`
    grid-column-end: -2;
  `}

  ${media.mobile`
    margin-top: ${rem(100)};
  `}
`;

const Sugar = styled(motion.img)`
  position: absolute;
  left: ${(-198 / 598) * 100}%;
  bottom: calc(100% + 1.5em);
  width: ${(108 / 598) * 100}%;

  ${media.mobile`
      left: auto;
      right: 15%;
  `}
`;

export const query = graphql`
  fragment SliceDuoImagesText on PrismicPageRichDataBodyDuoImagesText {
    id
    slice_type
    slice_label
    primary {
      main_title {
        text
      }
      is_landscape_first
      image_portrait {
        alt
        url
        dimensions {
          width
          height
        }
      }
      image_landscape {
        alt
        url
        dimensions {
          width
          height
        }
      }
      text {
        richText
      }
    }
  }
`;
