import React from 'react';
import styled from 'styled-components';
import propsToDom from 'core/helpers/propsToDom';
import { usePageContext } from 'store/PageProvider';
import { ImageCover } from './ImageCover';
import { useGlobalContext } from 'store/GlobalProvider';
import { useLifecycles } from 'react-use';
import Icon from './Icon';
import { rem } from 'styles/utils';
import { Container } from './Container';
import { SmallCaps, Title4 } from 'styles/typography';
import { AgeGatewayForm } from './AgeGatewayForm';

const AgeGatewayComponent = ({ ...others }) => {
  const { isMobile, setScrollEnabled } = useGlobalContext();
  const { ageGatewayData } = usePageContext();

  const { main_text, secondary_text, background_image, ...form } = ageGatewayData;

  const hasBackgroundImage = !!background_image?.url;
  const hasMainText = !!main_text?.html;
  const hasSecondaryText = !!secondary_text?.html;

  // Disable/Enable scroll on Mount/Unmount
  useLifecycles(
    () => setScrollEnabled(false),
    () => setScrollEnabled(true)
  );

  return (
    <div {...propsToDom(others)}>
      {hasBackgroundImage && (
        <ImageCover {...(isMobile ? background_image.thumbnails.mobile : background_image)} />
      )}
      <Scroller className="scrollable">
        <Logo>
          <Icon id="logo" />
          <p>Ron de cuba</p>
        </Logo>
        <Content>
          {hasMainText && <Title4 as="div" dangerouslySetInnerHTML={{ __html: main_text.html }} />}
          <AgeGatewayForm {...form} />
          {hasSecondaryText && (
            <SmallCaps as="div" dangerouslySetInnerHTML={{ __html: secondary_text.html }} />
          )}
        </Content>
      </Scroller>
    </div>
  );
};

export const AgeGateway = styled(AgeGatewayComponent)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;

  z-index: ${({ theme }) => theme.zIndex.ageGateway};
  color: ${({ theme }) => theme.colors.dust};
`;

const Scroller = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
`;

const Logo = styled.div`
  position: absolute;
  top: ${rem(30)};
  left: 50%;
  transform: translate(-50%, 0);
  font-size: ${rem(25)};

  ${Icon} {
    display: block;
  }

  p {
    font-size: ${rem(10.5)};
    line-height: 1;
    text-transform: uppercase;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: ${({ theme }) => theme.spacing.s1};
    letter-spacing: 0.2em;
    white-space: nowrap;
  }
`;

const Content = styled(Container)`
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  min-height: 100vh;
  min-height: fill-available;
  padding-top: ${rem(126)};
  padding-bottom: ${({ theme }) => theme.spacing.s3};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  & > ${Title4} {
    width: 100%;
    max-width: ${rem(810)};
    margin: auto auto ${({ theme }) => theme.spacing.s5};
  }

  & > ${SmallCaps} {
    margin-top: ${({ theme }) => theme.spacing.s5};
    margin-bottom: auto;

    a {
      color: currentColor;
    }
  }
`;
