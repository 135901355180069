import React from 'react';
import styled from 'styled-components';
import { LinkUnderline } from './LinkUnderline';
import media from 'styles/media';
import { isFilled } from '@prismicio/client';
import RichText from './RichText';

const RecipeStepsTabsComponent = ({ className, steps, active, setActive, children }) => {
  return (
    <div className={className}>
      {children}
      <StepsNav>
        {steps.map(({ step_title }, index) => {
          const title = isFilled.keyText(step_title) ? step_title : `Step ${index + 1}`;
          return (
            <LinkUnderline
              key={`step-nav-${index}`}
              isActive={active === index}
              onClick={() => setActive(index)}
              onMouseEnter={() => setActive(index)}
            >
              {title}
            </LinkUnderline>
          );
        })}
      </StepsNav>
      <StepsText>
        {steps.map(({ step_text }, index) => (
          <StepText key={`step-text-${index}`} $isActive={active === index}>
            <RichText render={step_text?.richText || step_text} />
          </StepText>
        ))}
      </StepsText>
    </div>
  );
};

const StepsNav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => `${theme.spacing.s1_5} 0 `};
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${({ theme }) => theme.spacing.s3};

  ${LinkUnderline} {
    margin-right: ${({ theme }) => theme.spacing.s3};

    &:last-child {
      margin-right: auto;
    }

    @media (hover: hover) and (pointer: fine) {
      opacity: 0.5;

      &.is-active,
      &:hover {
        opacity: 1;
      }
    }
  }

  ${media.mobile`
    justify-content: center;

    ${LinkUnderline} {
      margin: 0 ${({ theme }) => theme.spacing.s1_5};

      &:last-child {
        margin-right: ${({ theme }) => theme.spacing.s1_5};
      }
    }
  `}
`;
const StepsText = styled.div``;
const StepText = styled.div`
  display: ${({ $isActive }) => ($isActive ? 'block' : 'none')};
`;

export const RecipeStepsTabs = styled(RecipeStepsTabsComponent)``;
