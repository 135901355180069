import React, { createContext, useContext } from 'react';

export const DEFAULT_LANG = 'fr-FR';
export const [DEFAULT_LOCALE] = DEFAULT_LANG.split('-');

export const PageContext = createContext({
  uid: null,
  lang: DEFAULT_LANG,
});

const PageProvider = ({ children, pageContext }) => {
  return <PageContext.Provider value={pageContext}>{children}</PageContext.Provider>;
};

export default PageProvider;

export const usePageContext = () => {
  const context = useContext(PageContext);

  if (!context) {
    throw Error('usePageContext must be used inside a `PageProvider`');
  }

  return context;
};
