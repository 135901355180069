import propsToDom from 'core/helpers/propsToDom';
import propsToLink from 'core/helpers/propsToLink';
import React from 'react';
import { usePageContext } from 'store/PageProvider';
import styled from 'styled-components';
import { rem } from 'styles/utils';
import { LinkUnderline } from './LinkUnderline';

const NavSecondaryComponent = ({ ...others }) => {
  const { siteData } = usePageContext();
  const secondary_menu = siteData.secondary_menu.filter(({ link }) => !!link?.url);
  return (
    <nav {...propsToDom(others)}>
      {secondary_menu.map(({ link, link_text }, index) => (
        <LinkUnderline key={link.url + index} {...propsToLink(link)}>
          {link_text}
        </LinkUnderline>
      ))}
      <LinkUnderline className="optanon-toggle-display">
        {siteData.cookies_preferences}
      </LinkUnderline>
    </nav>
  );
};

export const NavSecondary = styled(NavSecondaryComponent)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: ${rem(20)} ${rem(60)};

  ${LinkUnderline} {
    white-space: nowrap;
  }
`;
