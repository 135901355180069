import React, { createContext, useState, useContext } from 'react';
import { useGeolocation, useIsomorphicLayoutEffect, useSessionStorage } from 'react-use';

export const MapContext = createContext({
  savedScrollTop: 0,
  scrollEnabled: true,
  headerHeight: null,
});

const MapProvider = ({ storeLocatorIso, children }) => {
  const sessionKey =
    storeLocatorIso !== null
      ? `store-locator-retailers-${storeLocatorIso}`
      : `store-locator-retailers`;
  const geolocation = useGeolocation();
  const [userGeolocation, setUserGeolocation] = useState({
    lat: null,
    lng: null,
    source: 'geolocation',
  });
  const [location, setLocation] = useState({ lat: null, lng: null, source: false });
  const [allRetailers, setAllRetailers] = useSessionStorage(sessionKey, null);
  const [retailers, setRetailers] = useState(allRetailers);
  const [activeRetailer, setActiveRetailer] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);

  useIsomorphicLayoutEffect(() => {
    if (selectedPlace !== null) {
      setActiveRetailer(null);
      setLocation({ ...selectedPlace.location, source: 'search' });
    } else if (!geolocation.loading && allRetailers !== null) {
      const object = {
        lat: geolocation.latitude,
        lng: geolocation.longitude,
        source: 'geolocation',
      };
      setUserGeolocation(object);
      setLocation(object);
    }
  }, [geolocation.loading, selectedPlace]);

  return (
    <MapContext.Provider
      value={{
        allRetailers,
        setAllRetailers,
        selectedPlace,
        setSelectedPlace,
        retailers,
        setRetailers,
        userGeolocation,
        location,
        setLocation,
        activeRetailer,
        setActiveRetailer,
        activeCategory,
        setActiveCategory,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export default MapProvider;

export const useMapContext = () => {
  const context = useContext(MapContext);
  if (!context) {
    throw Error('useMapContext must be used inside a `MapProvider`');
  }
  return context;
};
