import React from 'react';
import { forwardRef, useCallback, useRef } from 'react';

export const isSmallHorizontalSwipe = ({ absDeltaX, absDeltaY }) =>
  absDeltaX > 25 && absDeltaY < 50;

const SwiperComponent = ({ onSwipe, as: Tag = 'div', enableMouse = false, ...others }, ref) => {
  const touch = useRef({ startX: 0, startY: 0 });

  const onTouchEvent = useCallback(
    (event) => {
      event.stopPropagation();
      const x =
        event.changedTouches && event.changedTouches.length > 0
          ? event.changedTouches[0].screenX
          : event.clientX;
      const y =
        event.changedTouches && event.changedTouches.length > 0
          ? event.changedTouches[0].screenY
          : event.clientY;

      switch (event.type) {
        case 'touchstart':
        case 'mousedown':
          touch.current.startX = x;
          touch.current.startY = y;
          if (enableMouse) {
            document.body.addEventListener('mouseleave', onTouchEvent);
          }
          break;
        case 'touchend':
        case 'mouseup':
          if (enableMouse) {
            document.body.removeEventListener('mouseleave', onTouchEvent);
          }
          onSwipe({
            deltaX: touch.current.startX - x,
            absDeltaX: Math.abs(touch.current.startX - x),
            deltaY: touch.current.startY - y,
            absDeltaY: Math.abs(touch.current.startY - y),
          });
          break;
      }
    },
    [onSwipe, enableMouse]
  );

  const events = {
    onTouchStart: onTouchEvent,
    onTouchEnd: onTouchEvent,
  };

  if (enableMouse) {
    events.onMouseDown = onTouchEvent;
    events.onMouseUp = onTouchEvent;
  }

  return <Tag ref={ref} {...events} {...others} />;
};

export const Swiper = forwardRef(SwiperComponent);
