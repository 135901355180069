import React, { useCallback } from 'react';
import styled from 'styled-components';
import PageProvider from 'store/PageProvider';
import { useGlobalContext } from 'store/GlobalProvider';
import GlobalStyle from './GlobalStyle';
import Helmet from 'react-helmet';
import theme from 'styles/theme';
import { Footer } from './Footer';
import { Header } from './Header';
import { Menu } from './Menu';
import {
  useIsomorphicLayoutEffect,
  useLocalStorage,
  useMount,
  usePrevious,
  useUpdateEffect,
} from 'react-use';
import { AgeGateway } from './AgeGateway';
import { AnimatePresence } from 'framer-motion';
import { ModalNewsletter } from './ModalNewsletter';
import { ModalBuy } from './ModalBuy';
import useScollEnabled from 'hooks/useScrollEnabled';
import { ModalArticle } from './ModalArticle';
import { bgPaper } from 'styles/others';
import { navigate } from 'gatsby';
import useAgegateCookie from 'hooks/useAgegateCookie';
import { delegateEvent } from 'core/helpers/delegateEvent';

const Layout = ({ children, location, pageContext }) => {
  const [{ country }] = useAgegateCookie();
  const resetScroll = useScollEnabled();
  const { pageType, hreflang, url, localeIso, sellingCountriesIso } = pageContext;
  const [choosenLocale] = useLocalStorage('user-selected-locale');
  const isPage404 = pageType === 'page_404';

  const {
    modal,
    showAgeGate,
    setShowAgeGate,
    setSavedScrollTop,
    scrollEnabled,
    setScrollEnabled,
    userCountry,
    setUserCountry,
    setStoreLocatorIso,
    headerHeight,
    isMenuOpen,
    setMenuOpen,
    wh,
  } = useGlobalContext();

  const previousModal = usePrevious(modal);

  useIsomorphicLayoutEffect(() => {
    const hideAgeGate = location.search.indexOf('no-agegate') > -1;
    const navLang = navigator?.language?.split('-')[0];
    const urlLang = hreflang.find(({ lang }) => lang.split('-')[0] === navLang)?.url;

    if (hideAgeGate) {
      setShowAgeGate(false);
    }

    if (!choosenLocale && url !== urlLang && !isPage404) {
      navigate(urlLang);
    }

    setUserCountry(country || localeIso.toUpperCase());
  }, []);

  useMount(() => {
    // Delegates event for cookies preferences toggle
    delegateEvent(document.body, 'click', '.optanon-toggle-display', () => {
      if (window?.Optanon?.ToggleInfoDisplay instanceof Function) {
        window.Optanon.ToggleInfoDisplay();
      }
    });
  });

  useIsomorphicLayoutEffect(() => {
    let storeLocatorIso = null;

    if (userCountry !== null && sellingCountriesIso.includes(userCountry.toLowerCase())) {
      storeLocatorIso = userCountry.toLowerCase();
    } else if (sellingCountriesIso.includes(localeIso)) {
      storeLocatorIso = localeIso;
    }

    setStoreLocatorIso(storeLocatorIso);
  }, [userCountry]);

  useUpdateEffect(() => {
    if (isMenuOpen) {
      resetScroll();
      setMenuOpen(false);
    }
  }, [children]);

  const getModal = useCallback(() => {
    const options = modal?.options || {};

    switch (modal?.id) {
      case 'newsletter':
        return <ModalNewsletter {...options} />;
      case 'buy':
        return <ModalBuy {...options} />;
      case 'article':
        return <ModalArticle key={options.url} {...options} />;
      default:
        return null;
    }
  }, [modal]);

  // Manage scroll enabled for modals
  useIsomorphicLayoutEffect(() => {
    if (modal === null) {
      setScrollEnabled(true);
    } else if (previousModal === null) {
      const { scrollTop } = document.scrollingElement || document.documentElement;
      setSavedScrollTop(scrollTop);
      setScrollEnabled(false);
    }
  }, [modal]);

  return (
    <PageProvider pageContext={pageContext}>
      <Helmet>
        {/* eslint-disable */}
        <link rel="preconnect" href="https://eminente.cdn.prismic.io" />
        <link rel="apple-touch-icon" sizes="180x180" href="/images/favicons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/images/favicons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/images/favicons/favicon-16x16.png" />
        <link rel="manifest" href="/images/favicons/site.webmanifest" />
        <link rel="mask-icon" href="/images/favicons/safari-pinned-tab.svg" color={theme.colors.metaThemeColor} />
        <link rel="shortcut icon" href="/images/favicons/favicon.ico" />
        <meta name="msapplication-config" content="/images/favicons/browserconfig.xml" />
        <meta name="theme-color" content={theme.colors.metaThemeColor} />
        {/* eslint-enable */}
      </Helmet>
      <GlobalStyle scrollEnabled={scrollEnabled} viewportHeight={wh} headerHeight={headerHeight} />
      <Header isPage404={isPage404} />
      <Menu />
      <Main>{children}</Main>
      {!isPage404 && <Footer />}
      {showAgeGate && !isPage404 && <AgeGateway />}
      <AnimatePresence>{getModal()}</AnimatePresence>
    </PageProvider>
  );
};

const Main = styled.main`
  position: relative;
  z-index: 2;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  ${bgPaper};
`;

export default Layout;
