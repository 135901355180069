import React, { useState } from 'react';
import styled from 'styled-components';
import StoreLocatorMap from './StoreLocatorMap';
import StoreLocatorNav from './StoreLocatorNav';
import { rem } from 'styles/utils';
import { useMapContext } from 'store/MapProvider';
import { useIsomorphicLayoutEffect, useMedia, useMount } from 'react-use';
import media, { mqTabletPortrait } from 'styles/media';
import { Button } from './Button';
import { usePageContext } from 'store/PageProvider';
import { useGlobalContext } from 'store/GlobalProvider';
import getLatLngDistance from 'core/helpers/getLatLngDistance';
import StoreLocatorNavItem from './StoreLocatorNavItem';

function getCategoryBinary(category) {
  switch (category) {
    case 'bars & restaurants':
      return 1;
    case 'hotels':
      return 2;
    case 'wine merchant':
    default:
      return 0;
  }
}

const StoreLocatorComponent = ({ ...others }) => {
  const isTabletPortrait = useMedia(mqTabletPortrait);
  const { modalBuy } = usePageContext();
  const { userCountry } = useGlobalContext();
  const [isMobileMapVisible, setMobileMapVisible] = useState(false);
  const {
    location,
    activeCategory,
    activeRetailer,
    retailers,
    allRetailers,
    setAllRetailers,
    setRetailers,
    setActiveRetailer,
  } = useMapContext();

  const fetchRetailers = async () => {
    const lang = userCountry.toLowerCase();
    const response = await fetch(`/data/store-locator/${lang}.json`);
    if (!response.ok) console.warn('error while fetching store locator');
    const data = await response.json();
    data.forEach((item, index) => {
      item.uid = `${lang}-${index}`;
      item.productsLabels =
        item.products === 0
          ? [modalBuy.sl_reserva_label]
          : item.products === 1
          ? [modalBuy.sl_gran_reserva_label]
          : [modalBuy.sl_reserva_label, modalBuy.sl_gran_reserva_label];
      item.catLabel =
        modalBuy[
          item.category === 0
            ? 'sl_wine_merchant_label'
            : item.category === 1
            ? 'sl_bars_restaurants_label'
            : 'sl_hotels_label'
        ];
    });
    setAllRetailers(data);
  };

  useMount(() => {
    if (allRetailers === null) {
      fetchRetailers();
    }
  });

  useIsomorphicLayoutEffect(() => {
    if (retailers === null) {
      setRetailers(allRetailers);
    }
  }, [allRetailers]);

  useIsomorphicLayoutEffect(() => {
    if (allRetailers !== null) {
      const hasLocation = location?.lat && location?.lng;

      const sortedAllRetailers = hasLocation
        ? allRetailers.sort((a, b) =>
            getLatLngDistance(a, location) < getLatLngDistance(b, location) ? -1 : 1
          )
        : allRetailers;

      const sortedRetailers = sortedAllRetailers
        // Select only retailers from the active category
        .filter((retailer) =>
          activeCategory !== null ? retailer.category === getCategoryBinary(activeCategory) : true
        );

      setAllRetailers(sortedAllRetailers);
      setRetailers(sortedRetailers);

      if (hasLocation && sortedRetailers.length > 0) {
        setActiveRetailer(sortedRetailers[0]);
      }
    }
  }, [location, activeCategory]);

  return (
    <div {...others}>
      <StoreLocatorNav />
      <MapContainer isMobileMapVisible={isMobileMapVisible}>
        {isTabletPortrait && activeRetailer && (
          <StoreLocatorNavItem retailer={activeRetailer} key={activeRetailer.uid} />
        )}
        <StoreLocatorMap />
      </MapContainer>
      <MobileSwitch
        id="map"
        background="small"
        color="red"
        onClick={() => setMobileMapVisible(!isMobileMapVisible)}
      >
        {isMobileMapVisible ? modalBuy.sl_go_to_list : modalBuy.sl_go_to_map}
      </MobileSwitch>
    </div>
  );
};

export const MapContainer = styled.div`
  position: relative;
  height: 100%;
  width: 65%;
  background: ${({ theme }) => theme.colors.dust};
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.red};
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.red};

  .cluster {
    img {
      width: 98px;
      height: 98px;
    }
  }

  ${StoreLocatorNavItem} {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    width: auto;
    z-index: 50;
    background: ${({ theme }) => theme.colors.dust} !important;
    color: ${({ theme }) => theme.colors.red} !important;
    border: 1px solid ${({ theme }) => theme.colors.red};
    padding-top: ${({ theme }) => theme.spacing.s2};
    padding-bottom: ${({ theme }) => theme.spacing.s1_5};
  }

  #eminente-map {
    mix-blend-mode: multiply;
  }

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODc1IiBoZWlnaHQ9IjU3MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48cGF0aCBkPSJNMiA1NzFDMCA1NTAgMSA1MiAxIDJtMCAwYzE3MiAxIDc1MS0xIDg2OSAxTDEgMlptMiAwYzE1OCAxIDcyNyAwIDg2OS0xTDMgMlptODcwIDBjMSAzNS0xIDU0NyAyIDU2OEw4NzMgMlptMCAxdjU2OFYzWm0tMSA1NjdMMiA1NjlsODcwIDFabTIgMWMtMTI4LTItNjYxLTItODczLTFsODczIDFaTTQgNTcyIDIgMmwyIDU3MFoiIHN0cm9rZT0iI0I1NEUzNyIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg==');
    background-size: 100% 100%;
    pointer-events: none;
  }

  ${media.tabletPortrait`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    visibility: ${({ isMobileMapVisible }) => (isMobileMapVisible ? 'visible' : 'hidden')};
  `}
`;

const MobileSwitch = styled(Button)`
  position: absolute;
  left: 50%;
  bottom: ${({ theme }) => theme.spacing.s3};
  z-index: 5;
  white-space: nowrap;
  transform: translateX(-50%);

  ${media.fromTabletLandscape`
    display: none;
  `}
`;

export default styled(StoreLocatorComponent)`
  position: relative;
  display: flex;
  color: ${({ theme }) => theme.colors.red};
  height: ${rem(570)};
  height: ${({ theme }) =>
    `calc(var(--viewport-height, 100vh) - var(--header-height, ${theme.spacing.s9}) - var(--modal-header-height, 0px)) `};
`;
